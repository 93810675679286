import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PreviewComponent from '../app-components/PreviewComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className='padding-m'>
          <div className="font-size-s">{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Tabs_Sample() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sampleTabs = "<Tabs>" +
  "\n\t<Tab label='Item One'/>" +
  "\n\t<Tab label='Item Two'/>" +
  "\n\t<Tab label='Item Three'/>" +
  "\n</Tabs>" +
  "\n<TabPanel>Item One Content</TabPanel>" +
  "\n<TabPanel>Item Two Content</TabPanel>" +
  "\n<TabPanel>Item Three Content</TabPanel>";
  
  const sampleTabsVertical = "<Tabs orientation='vertical' variant='scrollable'>" +
  "\n\t<Tab label='Item One'/>" +
  "\n\t<Tab label='Item Two'/>" +
  "\n\t<Tab label='Item Three'/>" +
  "\n\t<Tab label='Item Four'/>" +
  "\n\t<Tab label='Item Five'/>" +
  "\n\t<Tab label='Item Six'/>" +
  "\n\t<Tab label='Item Seven'/>" +
  "\n</Tabs>" +
  "\n<TabPanel>Item One Content</TabPanel>" +
  "\n<TabPanel>Item Two Content</TabPanel>" +
  "\n<TabPanel>Item Three Content</TabPanel>" +
  "\n<TabPanel>Item Four Content</TabPanel>" +
  "\n<TabPanel>Item Five Content</TabPanel>" +
  "\n<TabPanel>Item Six Content</TabPanel>" +
  "\n<TabPanel>Item Seven Content</TabPanel>";


  const sampleFullWidth=
"<Tabs variant='fullWidth'>" +
"\n\t<Tab label='Item One'/>" +
"\n\t<Tab label='Item Two'/>" +
"\n\t<Tab label='Item Three'/>" +
"\n</Tabs>" +
"\n<TabPanel value={value} index={0}> Item One </TabPanel>" +
"\n<TabPanel value={value} index={1}> Item Two </TabPanel>" +
"\n<TabPanel value={value} index={2}> Item Three </TabPanel>"

  return (
    <div>
      <PreviewComponent title="Horizontal" description="" code={sampleTabs}>
          <Box>
              <Tabs value={value} onChange={handleChange}>
                  <Tab label="Item One"  />
                  <Tab label="Item Two" {...a11yProps(1)} />
                  <Tab label="Item Three" {...a11yProps(2)} />
              </Tabs>
              <TabPanel value={value} index={0}>Item One Content</TabPanel>
              <TabPanel value={value} index={1}>Item Two Content</TabPanel>
              <TabPanel value={value} index={2}>Item Three Content</TabPanel>
          </Box>
      </PreviewComponent>

      <PreviewComponent title="Vertical and Scrollable" description="" code={sampleTabsVertical}>
          <Box sx={{ flexGrow: 1, display: 'flex', height: 224 }}>
              <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange}>
                  <Tab label="Item One"  />
                  <Tab label="Item Two" {...a11yProps(1)} />
                  <Tab label="Item Three" {...a11yProps(2)} />
                  <Tab label="Item Four" {...a11yProps(3)} />
                  <Tab label="Item Five" {...a11yProps(4)} />
                  <Tab label="Item Six" {...a11yProps(5)} />
                  <Tab label="Item Seven" {...a11yProps(6)} />
              </Tabs>
              <TabPanel value={value} index={0}>Item One Content</TabPanel>
              <TabPanel value={value} index={1}>Item Two Content</TabPanel>
              <TabPanel value={value} index={2}>Item Three Content</TabPanel>
              <TabPanel value={value} index={3}>Item Four Content</TabPanel>
              <TabPanel value={value} index={4}>Item Five Content</TabPanel>
              <TabPanel value={value} index={5}>Item Six Content</TabPanel>
              <TabPanel value={value} index={6}>Item Seven Content</TabPanel>
          </Box> 
      </PreviewComponent> 
      
      <PreviewComponent title="Full width" description="" code={sampleFullWidth}>
      <Box >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
        >
          <Tab label="Item One" />
          <Tab label="Item Two"  />
          <Tab label="Item Three"/>
        </Tabs>
        <TabPanel value={value} index={0}> Item One </TabPanel>
        <TabPanel value={value} index={1}> Item Two </TabPanel>
        <TabPanel value={value} index={2}> Item Three </TabPanel>
      </Box>  
      </PreviewComponent>
    </div>
  );
}