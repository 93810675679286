import * as React from 'react';
import { Avatar, Stack } from '@mui/material';
import PreviewComponent from '../app-components/PreviewComponent';

export default function Avatar_Sample() {

  const sampleImage = "<Avatar src='/path/to/image'/>";

  const sampleLetters = "<Avatar>HI</Avatar>" +
                        "\n<Avatar className='background-secondary'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-10'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-9'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-8'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-7'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-6'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-5'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-4'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-3'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-2'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-1'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-0'>HI</Avatar>";

  const sampleLettersLight = "<Avatar className='background-primary-light'>HI</Avatar>" +
                        "\n<Avatar className='background-secondary-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-10-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-9-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-8-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-7-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-6-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-5-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-4-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-3-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-2-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-1-light'>HI</Avatar>" +
                        "\n<Avatar className='background-neutral-0-light'>HI</Avatar>";


  const sampleSizes = "<Avatar className='large'>HI</Avatar>" +
                      "\n<Avatar>HI</Avatar>" +
                      "\n<Avatar className='small'>HI</Avatar>";
                      
  return (
    <div>
      <PreviewComponent title="With Image" description="" code={sampleImage}>
          <Avatar src="/images/profile.jpg"/>
      </PreviewComponent>

      <PreviewComponent title="With Letters / Colors" description="" code={sampleLetters}> 
          <Stack direction='row' spacing={2}>
            <Avatar>HI</Avatar>
            <Avatar className="background-secondary">HI</Avatar>
            <Avatar className="background-neutral-10">HI</Avatar>
            <Avatar className="background-neutral-9">HI</Avatar>
            <Avatar className="background-neutral-8">HI</Avatar>
            <Avatar className="background-neutral-7">HI</Avatar>
            <Avatar className="background-neutral-6">HI</Avatar>
            <Avatar className="background-neutral-5">HI</Avatar>
            <Avatar className="background-neutral-4">HI</Avatar>
            <Avatar className="background-neutral-3">HI</Avatar>
            <Avatar className="background-neutral-2">HI</Avatar>
            <Avatar className="background-neutral-1">HI</Avatar>
            <Avatar className="background-neutral-0">HI</Avatar>
          </Stack>
      </PreviewComponent>

      <PreviewComponent title="With Letters / Colors - Light" description="" code={sampleLettersLight}> 
          <Stack direction='row' spacing={2} className='background-neutral-4 padding-base'>
            <Avatar className='background-primary-light'>HI</Avatar>
            <Avatar className="background-secondary-light">HI</Avatar>
            <Avatar className="background-neutral-10-light">HI</Avatar>
            <Avatar className="background-neutral-9-light">HI</Avatar>
            <Avatar className="background-neutral-8-light">HI</Avatar>
            <Avatar className="background-neutral-7-light">HI</Avatar>
            <Avatar className="background-neutral-6-light">HI</Avatar>
            <Avatar className="background-neutral-5-light">HI</Avatar>
            <Avatar className="background-neutral-4-light">HI</Avatar>
            <Avatar className="background-neutral-3-light">HI</Avatar>
            <Avatar className="background-neutral-2-light">HI</Avatar>
            <Avatar className="background-neutral-1-light">HI</Avatar>
            <Avatar className="background-neutral-0-light">HI</Avatar>
          </Stack>
      </PreviewComponent>
     
      <PreviewComponent title="Sizes" description="" code={sampleSizes}> 
          <Stack direction='row' spacing={2}>
            <Avatar className="large">HI</Avatar>
            <Avatar>HI</Avatar>
            <Avatar className="small">HI</Avatar>
          </Stack>
      </PreviewComponent>
    </div>
  );
}