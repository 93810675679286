import * as React from 'react';
import { PieChart } from '@mui/x-charts';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
  const chartValue = props.value;
  const restValue = 100 - props.value;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        width: '300px',
        height: '280px',
      }}
    >
      <PieChart
        colors={['#ED002F', '#CED4DA']}
        series={[
          {
            data: [
              { id: 0, value: chartValue, label: "Pressure 70%" },
              { id: 1, value: restValue, label: "Crate 30%" },
            ],
            innerRadius: 88,
            outerRadius: 98,
            endAngle: 360,
          },
        ]}
        legend={{
          hidden:true,
          directon: 'row',
          position: {
            vertical: 'bottom',
            horizontal: 'middle',
          },
        }}
        margin={{ right: 65 }}
        height={280}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 60,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" component="div">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
