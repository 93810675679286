import * as React from 'react';
import LayoutBlankWrapper from '../app-components/LayoutBlankWrapper';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Alert, Button, Snackbar } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function Error() {

    //SNACKBAR MESSAGE
  
  function InfoSnackbar({ open, onClose }) {

    React.useEffect(() => {
      let timer;
      if (open) {
        timer = setTimeout(() => {
          onClose();
        }, 5000);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [open, onClose]);

    return (
      <Snackbar open={open} onClose={onClose} 
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
      }}>
        <Alert className="info" onClose={onClose}>
          <InfoOutlinedIcon />
          Implement the required logic
        </Alert>
      </Snackbar>
    );
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
    return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

    return (

        <LayoutBlankWrapper>
            {openSnackbar && <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />}
            <ErrorOutlineIcon className="margin-bottom-base" sx={{width:304, height:304, color:"var(--color-primary)"}}/>
            <div className='font-size-display margin-bottom-base'> Sorry,</div>
            <div className='font-size-s margin-bottom-l'> It seems that something is not right on this screen, you may not have access.</div>
            <div>
            <Button  onClick={handleClickSnackbar}> Contact Technical Support</Button>
            <Button className='primary margin-left-base' onClick={handleClickSnackbar}>Go to homepage</Button>
            </div>

        </LayoutBlankWrapper>

    )

}