import * as React from 'react';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function SectionIndex_Sample() {
  
  const sampleCode_layoutLogin =
"<SectionIndex sections={sections}/>" +
"\n" +
"\n<section id='section1'>" + 
"\n\t{content of the section}" +
"\n</section>" +
"\n<section id='section2'>" + 
"\n\t{content of the section}" +
"\n</section>" +
"\n etc."
  
  return (
    <div>
      <PreviewComponent title="" description="" code={sampleCode_layoutLogin}>
      <h5>Properties:</h5>
      <ul>
        <li><strong>&#123;sections&#125;:</strong> you need to define the name of the sections; For example: const sections= &#91; 'Improvements', 'Management', 'Maps', etc. &#93;</li>
        <li><strong>content of the section:</strong> where you put all the content you need inside each section. For example: &lt;Card&gt;, &lt;Box&gt;, etc. </li>
    </ul>
      </PreviewComponent>
    </div>
  );
}