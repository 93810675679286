export default function AppMenu({ pages }) {

  let activePage = "";

  pages.map((page) => {
    if (window.location.pathname === page.url) {
      activePage = page.url;
      return true;
    } else {
      return false;
    }
  });

  return (
    <div className="app-menu-container">
      {pages.map((page) =>
          <a
            key={page.id}
            href={page.url}
            className={`${activePage === page.url ? "is-active" : ""}`}
          >
            {page.label}
          </a>
        )
      }
    </div>
  );
}
