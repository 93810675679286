import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PreviewComponent from '../app-components/PreviewComponent';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

export default function TemporaryDrawer() {

  const sampleCode_Drawer="<Drawer>" +
                        "\n\t{drawerContent(anchor)}" +
                        "\n</Drawer>";

  const sampleCode_SwipeableDrawer="<SwipeableDrawer" +
                        "\n\tclassName='action-sheet'" +
                        "\n\topen={open}" +
                        "\n\tonClose={toggleSwipeableDrawer(false)}" +
                        "\n\tonOpen={toggleSwipeableDrawer(true)}" +
                        "\n>" +

                        "\n\t<Box className='action-sheet-puller'>" +
                        "\n\t\tanchor='bottom'" +
                        "\n\t\t<Button>Save</Button>" +
                        "\n\t\t<Button>Edit</Button>" +
                        "\n\t\t<Button onClick={toggleSwipeableDrawer(false)}>Cancel</Button>" +
                        "\n\t</Box>" +
                        "\n</SwipeableDrawer>";

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const drawerContent = (anchor) => (
    <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400, height: anchor === 'right' || anchor === 'left' ? 'auto' : 250 }}
          className="margin-base"    
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
        <div className='center'>
            <Avatar src="/images/profile.jpg"/>
            <p className='font-regular font-size-h3 margin-bottom-none margin-top-s'>
              Morwan Chatila
            </p>
            <p className='font-size-s horizontal-align margin-bottom-none'>
            <EmailIcon className='font-size-s'/>morwan@couplahouse.com
            </p>
            <p className='font-size-s horizontal-align margin-bottom-none'>
            <LocalPhoneIcon className='font-size-s'/>+1 604428 0611
            </p>
        </div>
    </Box>
  );

  //SWIPEABLE DRAWER

  const [open, setOpen] = React.useState(false);

  const toggleSwipeableDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <div>
      <div>
          <PreviewComponent title="" description="" code={sampleCode_Drawer}>
            {['top', 'right', 'bottom', 'left',].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button onClick={toggleDrawer(anchor, true)} className=' margin-left-s'>{anchor} </Button>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {drawerContent(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
          </PreviewComponent>
      </div>  
  
  
      <div className='full-height'>
        <PreviewComponent title="Swipeable Drawer" description="" code={sampleCode_SwipeableDrawer}>
          <Box>
            <Button onClick={toggleSwipeableDrawer(true)}>Open</Button>
          </Box>
          <SwipeableDrawer
            className="action-sheet"
            anchor="bottom"
            open={open}
            onClose={toggleSwipeableDrawer(false)}
            onOpen={toggleSwipeableDrawer(true)}
          >
      
          <Box className="action-sheet-puller" >
            <Button className='full-width font-size-s btn-secondary'>Save</Button>
            <Button className='full-width font-size-s text-secondary'>Edit</Button>
            <Button className='margin-top-base full-width font-size-s text-secondary'  onClick={toggleSwipeableDrawer(false)}>Cancel</Button>
          </Box>

          </SwipeableDrawer>
          </PreviewComponent>
        </div>
    </div>
  );
}