import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { Button, Stack, Box, useMediaQuery } from '@mui/material';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import LayoutBlankWrapper from '../app-components/LayoutBlankWrapper';

const images = [
    { title: 'Vacation Map',
      imgPath: './images/HiResources.Image_Sectioned.png',
      subTitle: 'Application to submit vacation',
      details: 'Send information regarding vacation requests in and consult it.'
    },
    { title: 'Submit a vacation request',
      imgPath:'./images/HiResources.Image_Sectioned.png',
      subTitle: 'Choose a date or dates',
      details: 'From the calendar, pick the days you want to take off.'
    },
    { title: 'Submit a vacation request ',
      imgPath:'./images/HiResources.Image_Sectioned.png',
      subTitle: ' Choose absence type',
      details: 'Choose the reason for the absence request.' 
    },
    { title: 'Submit a vacation request',
      imgPath:'./images/HiResources.Image_Sectioned.png',
      subTitle: 'Submit request',
      details: 'Send your chosen absence request in for approval.'
    },
    { title: 'Check order status',
      imgPath:'./images/HiResources.Image_Sectioned.png', 
      subTitle: '',
      details: "Anytime you need to check the status of your absence requests' approvals." 
    }
  ];

export default function OnBoarding() {

    const isPhone = useMediaQuery('(max-width:620px)');

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;
  
  
    const handleStepChange = (step) => {
      setActiveStep(step);
    };

    
  const handleNext = () => {
    if (!isLastStep) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  
    const isLastStep = activeStep === maxSteps - 1;
    const buttonText = isLastStep ? 'Login' : 'Next';
    const showSkipButton = !isLastStep;

    return (

        <LayoutBlankWrapper>

            <Box className='text-align-center full-width'>
        {isPhone ? (
            <>
                 <SwipeableViews
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {images.map((step, index) => (
                        <div key={step.title}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <div>
                                    <h2>{step.title}</h2>
                                    <Box
                                        className='margin-top-xl padding-x-base'
                                        component="img"
                                        src={step.imgPath}
                                        alt={step.title}
                                    />
                                    <h4 className='margin-top-m text-align-center padding-x-base'>{step.subTitle}</h4>
                                    <div className='text-neutral-7 text-align-center margin-top-s padding-x-base'>{step.details}</div>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </SwipeableViews>
                <Stack direction="row" justifyContent="center">
                <MobileStepper
                className='padding-top-base margin-bottom-xxl'
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                />
                </Stack>
                <div>
                    <Button className='primary' endIcon={isLastStep ? null : <EastRoundedIcon />} onClick={handleNext}>
                    {buttonText}
                    </Button>
                </div>
                {showSkipButton && <Button className='margin-top-base'>Skip onboarding</Button>}

            </>
        ):(
        <>
            <h1>{images[activeStep].title}</h1>
            <SwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                <div key={step.title}>
                    {index === activeStep && (
                    <div>
                        <Box
                        className='margin-top-xl'
                        component="img"
                        src={step.imgPath}
                        alt={step.title}
                        />
                        <h3 className='margin-top-m text-align-center'>{step.subTitle}</h3>
                        <div className='text-neutral-7 text-align-center margin-top-s'>{step.details}</div>
                    </div>
                    )}
                </div>
                ))}
            </SwipeableViews>
            <Stack direction="row" justifyContent="center">
                <MobileStepper
                className='padding-top-base margin-bottom-xxl'
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                />
            </Stack>
            <div>
                <Button className='primary' endIcon={isLastStep ? null : <EastRoundedIcon />} onClick={handleNext}>
                {buttonText}
                </Button>
            </div>
            <Stack direction="row" justifyContent="flex-end" className='margin-right-xl margin-top-base'>
                {showSkipButton && <Button>Skip onboarding</Button>}
            </Stack>
        </>
        )}            
            </Box>

        </LayoutBlankWrapper>

    )

}