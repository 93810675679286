import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home'
import PreviewComponent from '../app-components/PreviewComponent';
import { Stack } from '@mui/material';


const sample_small ="<HomeIcon className='font-size-s'/> ";

const sample_base ="<HomeIcon />";

const sample_large = "<HomeIcon className='font-size-h1'/>";

const sample_colors = "<HomeIcon className='text-primary'/>" +
                    "\n<HomeIcon className='text-secondary'/>" +
                    "\n<HomeIcon className='text-neutral-7'/>" +
                    "\n<HomeIcon className='text-error'/>" +
                    "\n<HomeIcon className='text-info'/>" +
                    "\n<HomeIcon className='text-success'/>" +
                    "\n<HomeIcon className='text-warning'/>";


export default function SvgIconsSize() {

  return (    
      <div>
          <PreviewComponent title="Small" description="" code={sample_small}>
              <HomeIcon className="font-size-s"/>
          </PreviewComponent> 

          <PreviewComponent title="Base" description="" code={sample_base}>
              <HomeIcon />
          </PreviewComponent>

          <PreviewComponent title="Large" description="" code={sample_large}>
              <HomeIcon className="font-size-h1" />
          </PreviewComponent>

          <PreviewComponent title="Colors" description="" code={sample_colors}>
              <Stack direction='row' spacing={2}>
                  <HomeIcon className="text-primary" />
                  <HomeIcon className="text-secondary" />
                  <HomeIcon className="text-neutral-7" />
                  <HomeIcon className="text-error" />
                  <HomeIcon className="text-info" />
                  <HomeIcon className="text-success" />
                  <HomeIcon className="text-warning" />
              </Stack>
          </PreviewComponent>
      </div>
  );
}