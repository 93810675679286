import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PreviewComponent from "../../app-components/PreviewComponent";
import { Box } from "@mui/material";

export default function BasicSelect() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const sampleCode_basic =
    "<FormControl>" +
    "\n\t<InputLabel>Age</InputLabel>" +
    "\n\t<Select value={age} label='Age'>" +
    "\n\t\t<MenuItem value={10}>Ten</MenuItem>" +
    "\n\t\t<MenuItem value={20}>Twenty</MenuItem>" +
    "\n\t\t<MenuItem value={30}>Thirty</MenuItem>" +
    "\n\t</Select>" +
    "\n</FormControl>";

  return (
    <div>
      <PreviewComponent title="Basic" description="" code={sampleCode_basic}>
          <Box className="width-300">
            <FormControl className="full-width">
                <InputLabel>Age</InputLabel>
                <Select
                value={age}
                label="Age"
                onChange={handleChange}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl>
          </Box>
      </PreviewComponent>
    </div>
  );
}
