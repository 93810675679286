import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import PreviewComponent from '../app-components/PreviewComponent';


export default function SpeedDial_Sample() {

  const sampleSpeedDialTooltip = "<SpeedDial icon={<SpeedDialIcon />}>" +
                      "\n\t<SpeedDialAction icon={<FileCopyIcon />} tooltipTitle={'Copy'} />" +
                      "\n\t<SpeedDialAction icon={<SaveIcon />} tooltipTitle={'Save'} />" +
                      "\n\t<SpeedDialAction icon={<PrintIcon />} tooltipTitle={'Print'} />" +
                      "\n\t<SpeedDialAction icon={<ShareIcon />} tooltipTitle={'Share'} />" +
                      "\n</SpeedDial>";

  const sampleSpeedDialLabel = "<SpeedDial icon={<SpeedDialIcon />}>" +
                      "\n\t<SpeedDialAction icon={<FileCopyIcon />} tooltipTitle={'Copy'} tooltipOpen />" +
                      "\n\t<SpeedDialAction icon={<SaveIcon />} tooltipTitle={'Save'} tooltipOpen />" +
                      "\n\t<SpeedDialAction icon={<PrintIcon />} tooltipTitle={'Print'} tooltipOpen />" +
                      "\n\t<SpeedDialAction icon={<ShareIcon />} tooltipTitle={'Share'} tooltipOpen />" +
                      "\n</SpeedDial>";

  return (
    <div>
        <PreviewComponent title="With Tooltip on Hover" description="" code={sampleSpeedDialTooltip}>
          <Box sx={{ height: 320}}>
            <SpeedDial ariaLabel="SpeedDial basic example" icon={<SpeedDialIcon />} > 
            {/* Se retirar o ariaLabel dá erro */}
                <SpeedDialAction icon={<FileCopyIcon />} tooltipTitle={'Copy'}/>
                <SpeedDialAction icon={<SaveIcon />} tooltipTitle={'Save'}/>
                <SpeedDialAction icon={<PrintIcon />} tooltipTitle={'Print'}/>
                <SpeedDialAction icon={<ShareIcon />} tooltipTitle={'Share'}/>
            </SpeedDial>
          </Box>
        </PreviewComponent>

        <PreviewComponent title="With Fixed Label" description="" code={sampleSpeedDialLabel}>
          <Box sx={{ height: 320}}>
            <SpeedDial ariaLabel="SpeedDial basic example" icon={<SpeedDialIcon />} > 
            {/* Se retirar o ariaLabel dá erro */}
                <SpeedDialAction icon={<FileCopyIcon />} tooltipTitle={'Copy'} tooltipOpen/>
                <SpeedDialAction icon={<SaveIcon />} tooltipTitle={'Save'} tooltipOpen/>
                <SpeedDialAction icon={<PrintIcon />} tooltipTitle={'Print'} tooltipOpen/>
                <SpeedDialAction icon={<ShareIcon />} tooltipTitle={'Share'} tooltipOpen/>
            </SpeedDial>
          </Box>
        </PreviewComponent>
    </div>
  );
}