import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function SelectAutoWidth() {
  // const [age, setAge] = React.useState('');

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  const sampleCode_autowidth= "<FormControl>" +
                                  "\n\t<InputLabel>Age</InputLabel>" +
                                  "\n\t<Select autoWidth label='Age'>" +
                                  "\n\t\t<MenuItem value={10}>Twenty</MenuItem>" +
                                  "\n\t\t<MenuItem value={21}>Twenty one</MenuItem>" +
                                  "\n\t\t<MenuItem value={22}>Twenty one and a half</MenuItem>" +
                                  "\n\t</Select>" +
                              "\n</FormControl>"

  return (
    <div>
      <PreviewComponent title="Auto-Width" description="" code={sampleCode_autowidth}>
          <FormControl className="min-width-150">
            <InputLabel>Age</InputLabel>
            <Select
              autoWidth
              label="Age"
            >
              <MenuItem value={10}>Twenty</MenuItem>
              <MenuItem value={21}>Twenty one</MenuItem>
              <MenuItem value={22}>Twenty one and a half</MenuItem>
            </Select>
          </FormControl>
      </PreviewComponent>
    </div>
  );
}