import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SortAndSelect from './TableSortSelect_Sample'
import PreviewComponent from '../app-components/PreviewComponent';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];


const sampleCode_basicTable="<TableContainer>" +
                            "\n\t<Table>" +
                            "\n\t\t<TableHead>" +
                            "\n\t\t\t<TableRow>" +
                            "\n\t\t\t\t<TableCell>Dessert (100g serving)</TableCell>" +
                            "\n\t\t\t\t<TableCell>Calories</TableCell>" +
                            "\n\t\t\t\t<TableCell>Fat (g)</TableCell>" +
                            "\n\t\t\t\t<TableCell>Carbs (g)</TableCell>" +
                            "\n\t\t\t\t<TableCell>Protein (g)</TableCell>" +
                            "\n\t\t\t</TableRow>" +
                            "\n\t\t</TableHead>" +
                            "\n\t\t<TableBody>" +
                            "\n\t\t\t{rows.map((row) => (" +
                            "\n\t\t\t<TableRow" +
                            "\n\t\t\t\tkey={row.name}" +
                            "\n\t\t\t>" +
                            "\n\t\t\t\t<TableCell className='text-primary'>" +
                            "\n\t\t\t\t\t{row.name}" +
                            "\n\t\t\t\t</TableCell>" +
                            "\n\t\t\t\t<TableCell>{row.calories}</TableCell>" +
                            "\n\t\t\t\t<TableCell>{row.fat}</TableCell>"+
                            "\n\t\t\t\t<TableCell>{row.carbs}</TableCell>" +
                            "\n\t\t\t\t<TableCell>{row.protein}</TableCell>" +
                            "\n\t\t\t</TableRow>" +
                            "\n\t\t\t))}" +
                            "\n\t\t</TableBody>" +
                            "\n\t</Table>" +
                            "\n</TableContainer>"


export default function Table_Sample() {
  return (
    <div>

      <PreviewComponent title="Basic" description="" code={sampleCode_basicTable}>
        <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Dessert (100g serving)</TableCell>
                    <TableCell>Calories</TableCell>
                    <TableCell>Fat (g)</TableCell>
                    <TableCell>Carbs (g)</TableCell>
                    <TableCell>Protein (g)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                    >
                      <TableCell className="text-primary">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.calories}</TableCell>
                      <TableCell>{row.fat}</TableCell>
                      <TableCell>{row.carbs}</TableCell>
                      <TableCell>{row.protein}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
        </TableContainer>
      </PreviewComponent>

      <SortAndSelect/>
    </div>
  );
}