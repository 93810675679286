import React from "react";
import LayoutSideWrapper from '../app-components/LayoutSideWrapper';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Tabs, Tab, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, useMediaQuery, TableSortLabel, Stack } from "@mui/material";
import { Button } from "@mui/base";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import InfoSnackbar from './Commons/InfoSnackbar';
import ActionSheet from "../theme/custom-components/action-sheet/ActionSheet";


export default function DetailComplex() {

const isNotDestktop = useMediaQuery('(max-width:820px)');

  const rows = React.useMemo(() =>[
    {name: 'Black and silver headphones', quantity: 1831},
    // {name: 'Black and silver headphones', quantity: 1831},
    // {name: 'Black and silver headphones', quantity: 1831},
    {name: 'White tubular speaker', quantity: 4243},
    // {name: 'White tubular speaker', quantity: 4243},
    // {name: 'White rectangular speaker', quantity: 4567},
    {name: 'Laptop case grey', quantity: 4307},
    // {name: 'White in-ear pods', quantity: 3561},
    // {name: 'White in-ear pods', quantity: 3561},
  ], []);

  //SORT TABLE

const [sortConfig, setSortConfig] = React.useState({ key: null, direction: 'ascending' });

const sortedData = React.useMemo(() => {
  let sortableData = [...rows];
  if (sortConfig.key) {
    sortableData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
  }
  return sortableData;
}, [rows, sortConfig]);

const requestSort = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  setSortConfig({ key, direction });
};

//TABS
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box className='padding-top-m'>
                <div>{children}</div>
              </Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

      const [value, setValue] = React.useState(0);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

 //SNACKBAR MESSAGE

 const [openSnackbar, setOpenSnackbar] = React.useState(false);

 const handleClickSnackbar = () => {
   setOpenSnackbar(true);
   return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
 };

 const handleCloseSnackbar = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }

   setOpenSnackbar(false);
 };

//ACTION SHEETS

const [openActionSheet, setOpenActionSheet] = React.useState(false);

  const toggleActionSheet = (newOpen) => () => {
    setOpenActionSheet(newOpen);
  }

//ACTION BUTTONS

    function actionButton() {
        return (
            <Stack direction="row" className="margin-bottom-m">
                {isNotDestktop ? (
                <>
                   <Button className="btn-icon" onClick={toggleActionSheet(true)}> <MoreVertRoundedIcon/> </Button>

                  <ActionSheet open={openActionSheet} onClose={toggleActionSheet(false)} onCancelClick={toggleActionSheet(false)} cancelText="Cancel">
                      <Button className='full-width' onClick={handleClickSnackbar}>Edit</Button>
                      <Button className='full-width'onClick={handleClickSnackbar}>Print</Button>
                  </ActionSheet>
                </>

                ) : (
                <>

                  <Typography className="text-align-left margin-right-xxl">
                    <Stack direction="column">
                      <span className="text-neutral-7">Latest submit</span>
                      <span className="font-bold">6 may. 2022 at 11:21</span>
                    </Stack>
                  </Typography>
                  <Typography className="text-align-left margin-right-xxl">
                    <Stack direction="column">
                      <span className="text-neutral-7">Created on</span>
                      <span className=" font-bold">6 may. 2022 at 11:21</span>
                    </Stack>
                  </Typography>
                  <Button className="margin-right-base" onClick={handleClickSnackbar}> 
                    <Stack direction="row" alignItems="center">
                      <PrintOutlinedIcon className="margin-right-s" /> 
                      <span>Print</span>  
                    </Stack>
                  </Button>
                  <Button className='primary' onClick={handleClickSnackbar}>
                    <Stack direction="row" alignItems="center">
                      <EditOutlinedIcon className="margin-right-s"/>
                      <span>Edit</span>
                    </Stack>
                  </Button>
                </>
                )}
                </Stack>
         );
    }
  
    return (
  
        <LayoutSideWrapper 
        title="Detail Complex" 
        actions={actionButton()}
        breadcrumbs={[
        <Link key="home">Home</Link>,
        <Link key="products">Products</Link>,
        <span key="detail-complex">Detail Complex</span>
        ]}>

    {openSnackbar && <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />} 

          {isNotDestktop ? (

            <Stack direction="row" className="margin-bottom-m">
              <Typography className="text-align-left margin-right-xxl">
                <Stack direction="column">
                  <span className="text-neutral-7 ">Latest submit</span>
                  <span className="font-bold">6 may. 2022 at 11:21</span>
                </Stack>
              </Typography>
              <Typography className="text-align-left margin-right-xxl">
                <Stack direction="column">
                  <span className="text-neutral-7 ">Created on</span>
                  <span className="font-bold">6 may. 2022 at 11:21</span>
                </Stack>
              </Typography>
            </Stack>
       

        ) : (
        <>

        </>
        )}
       

        <Tabs value={value} onChange={handleChange} variant='fullWidth'>
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Contacts" {...a11yProps(1)} />
            <Tab label="Historic" {...a11yProps(2)} />
            <Tab label="Delivery Map" {...a11yProps(3)} />
        </Tabs>
            <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Card className='padding-m min-height-350'>
                            <CardContent>
                            <h2 className="margin-bottom-m">Form</h2>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={6} md={6}>
                                    <div className="text-neutral-7 text-align-left">Number</div>
                                    <div className="margin-bottom-m" >0000000254550000004</div>
                                    <div className="text-neutral-7 text-align-left">Created by</div>
                                    <div className="margin-bottom-m" >Tiago Almeida</div>
                                    <div className="text-neutral-7 text-align-left">Mechanics</div>
                                    <div className="margin-bottom-m" >Discount, Bonus, Rappel</div>
                                </Grid>
                                <Grid className="text-align-left" item xs={12} sm={6} md={6}>
                                    <div className="text-neutral-7 ">Status</div>
                                    <div className="margin-bottom-m" >Regularized lending</div>
                                    <div className="text-neutral-7 text-align-left">Order Number</div>
                                    <div className="margin-bottom-m" >Absent work orders</div>
                                    <div className="text-neutral-7 text-align-left">Type of agreement</div>
                                    <div className="margin-bottom-m" >New</div>
                                </Grid>
                            </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={8}>
                        <Card className='padding-m min-height-350'>
                            <CardContent>
                                <h2 className="margin-bottom-m">Number of distributor orders</h2>
                                <TableContainer>
                                    <Table>
                                    <TableHead>
                                        <TableRow>
                                        <TableCell  onClick={() => requestSort('name')} style={{ cursor: 'pointer' }}>
                                          <TableSortLabel
                                              active={sortConfig.key === 'name'}
                                              direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                                            >
                                              Name
                                            </TableSortLabel>
                                          </TableCell>
                                        <TableCell  onClick={() => requestSort('quantity')} style={{ cursor: 'pointer' }}>
                                          <TableSortLabel
                                              active={sortConfig.key === 'quantity'}
                                              direction={sortConfig.key === 'quantity' ? sortConfig.direction : 'asc'}
                                            >
                                              Quantity
                                            </TableSortLabel>
                                          </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedData.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.quantity}</TableCell>
                                        </TableRow>
                                        ))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid className='margin-top-m' container >
                    <Grid item xs={12} sm={12} md={12}>
                        <Card className='padding-m'>
                            <CardContent>
                                <h2 className="margin-bottom-m">Request</h2>
                                <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="text-neutral-7 text-align-left">Client number</div>
                                    <div className="margin-bottom-m" >1128100620</div>
                                    <div className="text-neutral-7 text-align-left">Activity</div>
                                    <div className="margin-bottom-m" >Regularized lending</div>
                                    <div className="text-neutral-7 text-align-left">Start date</div>
                                    <div className="margin-bottom-m">May 6, 2022 at 11:21</div>
                                    <div className="text-neutral-7 text-align-left">Priority</div>
                                    <div>Normal</div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="text-neutral-7 text-align-left">Client</div>
                                    <div className="margin-bottom-m">Restaurante Brasinha</div>
                                    <div className="text-neutral-7 text-align-left">Potential segm</div>
                                    <div className="margin-bottom-m">B</div>
                                    <div className="text-neutral-7 text-align-left">End date</div>
                                    <div className="margin-bottom-m">May 6, 2022 at 11:21</div>
                                    <div className="text-neutral-7 text-align-left">Motive</div>
                                    <div>Regularized lending</div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="text-neutral-7 text-align-left">Seg level 3</div>
                                    <div>Restaurant</div>
                                </Grid>
                            </Grid>
                              <div className="text-neutral-7 margin-top-m">Observations</div>
                              <div>Use agile frameworks to provide high-level overviews a solid summary. Corporate strategy iterations encourage group collaboration to strengthen the overall value offering. Develop a more comprehensive understanding of disruptive innovation via workplace empowerment and diversity.</div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>
            
            <TabPanel value={value} index={1}>Sample content contact</TabPanel>
            <TabPanel value={value} index={2}>Sample content historic</TabPanel>
            <TabPanel value={value} index={3}>Sample content delivery</TabPanel>
            
        </LayoutSideWrapper>
  
    );
  }