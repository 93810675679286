import AppMenu from "../../../app-components/AppMenu";
import { Breadcrumbs } from "@mui/material";
import React, { useState } from 'react';

export default function LayoutTop(props){

    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    return (
        
        <div className={"layout-top" + (props.halfSize ? " layout-top--half-width" : "") + (menuVisible ? " app-menu-visible" : "")} key="layout-top">
            <header className='layout-top__header'>
                <div className="layout-top__header-container layout-grid-container">
                    <div className='layout-top__header-application-name'>
                    <img src={props.appLogoURL} alt="" />
                        {props.appName}
                    </div>
                    <nav className='layout-top__header-menu'>
                        <AppMenu pages={props.appMenuPages}/>
                    </nav>
                    <div className='layout-top__header-actions'>
                        {props.headerActions}
                    </div>
                    <div className="hamburguer-menu" onClick={toggleMenu}></div>
                </div>
            </header>
            <div className="layout-top__content">
                <div className="layout-grid-container">
                    <div className="breadcrumbs">
                        {(props.breadcrumbs !== undefined ? <Breadcrumbs separator="›">{props.breadcrumbs.map(breadcrumb => breadcrumb)}</Breadcrumbs>: "")}
                    </div>
                    <div className="content-top">
                        <h1 className="content-top__title">{props.title}</h1>
                        <div className="content-top__actions">{props.actions}</div>
                    </div>
                    <div className="main-content">   
                        {props.children}
                    </div>
                </div>
            </div>
            <div className="layout-top__footer">
                <div className="layout-grid-container">
                    {props.footer}
                </div>
            </div>

            {/* App Menu for Mobile */}
            <div className="app-menu-overlay" onClick={toggleMenu}></div>
            <div className="app-menu">
                <div className="app-menu__header">
                    <div className="app-menu__header-name">
                        <div className="app-menu__header-logo">
                        <img src={props.appLogoFullURL} alt="" />
                        </div>
                        <div className="app-menu__header-name">{props.appName}</div>
                    </div>
                    <div className="app-menu__header-close" onClick={toggleMenu}></div>
                </div>
                <div className="app-menu__content">
                    <AppMenu pages={props.appMenuPages}/>
                </div>
                <div className="app-menu__content-bottom">
                    {props.menuBottom}
                </div>
                <div className="app-menu__footer">
                    {props.footer}
                </div> 
            </div>
            
        </div>

    )
}