import React, { Fragment } from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router';


const AppMenuList = ({menuItems}) => {
  
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
      setOpen(!open);
    };

  return (
    <List>
      {menuItems.map((item) =>
        item.submenu.length <= 0 ? (
           <ListItemButton key={item.id} className={pathname === item.link ? 'active' : ''}>
            <ListItemIcon>
              <i class="fa-light fa-gauge-simple-high font-size-base text-neutral-7"></i>
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>

        ) : (
          <Fragment>
            <ListItemButton onClick={handleClick} className={pathname === item.link ? 'active' : ''}>
              <ListItemIcon> <i
                    data-icon=""
                    class="fa-light fa-user fa-1x font-size-base text-neutral-7"
                  ></i></ListItemIcon>
              <ListItemText primary={item.label} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.submenu.map((child) => (
                  <ListItemButton className={pathname === child.link ? 'active' : ''}>
                    <ListItemText primary={child.label} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </Fragment>
        )
      )}
    </List>
  );
};

export default AppMenuList;
