import { Link } from "react-router-dom";
import LayoutTopWrapperLSG from "../app-components/LayoutTopWrapperLSG";
import PreviewScreenTemplate from "../app-components/PreviewScreenTemplate";

export default function ScreenSamples(){

    const screenTemplatesList = [
        {
            title : "Dashboard",
            description : "Used for aggregating measurements and summarizing information, with rapid actions available.",
            imageurl : "/images/Dashboard.png",
            url : "/dashboard"
        },
        {
            title : "Detail Complex",
            description : "Used for detail pages with more complicated information structure. For example, sites with multiple relevant topics that are split and easily accessible to the user via Tabs.",
            imageurl : "/images/DetailComplex.png",
            url : "/detail-complex"
        },  
        {
            title : "Detail Consult Editable",
            description : "Used to provide the user the details of a page that may be edited.  Every detail page includes an editing option, and the user should never be provided with a detail page that has disabled fields.",
            imageurl : "/images/DetailConsultEditable.png",
            url : "/detail-consult-editable"
        },
        {
            title : "Detail Consult Information",
            description : "Used to provide to the user the detail of a page that can be edited; each detail page has an editing option, and the detail page with disabled fields should not be displayed to the user.",
            imageurl : "/images/DetailConsultInformation.png",
            url : "/detail-consult-information"
        },
        {
            title : "Error",
            description : "It is used to display an error message if something has not been done the right way.",
            imageurl : "/images/ErrorScreen.png",
            url : "/error"
        },
        {
            title : "FAQ's",
            description : "Used to help the user clarify uncertainties by organizing queries into categories.",
            imageurl : "/images/FAQs.png",
            url : "/FAQs"
        },
        {
            title : "Form Section",
            description : "Used for processes that do not require information exchange between categories/areas.",
            imageurl : "/images/FormSectionScreen.png",
            url : "/form-section"
        },
        {
            title : "Form Survey",
            description : "Used to answer questionnaires, exemplifying various sorts of questions, with more simple parts and subsections, allowing the user to know where he is at the moment and how many steps left to finish.",
            imageurl : "/images/FormSurvey.png",
            url : "/form-survey"
        },
        {
            title : "Form Wizard Horizontal",
            description : "Step-by-step processes allow users to submit information in a preset order, with succeeding stages based on information entered in prior ones. Procedures with up to five stages.",
            imageurl : "/images/FormWizardHorizontal.png",
            url : "/form-wizard-horizontal"
        },
        {
            title : "Form Wizard Vertical",
            description : "Step-by-step procedures allow users to submit information in a preset order, with succeeding stages based on the information entered in prior ones. Processes having more than five interdependent stages.",
            imageurl : "/images/FormWizardVertical.png",
            url : "/form-wizard-vertical"
        },
        {
            title : "Home",
            description : "Used to serve as a homepage for applications. Usually with some features highlights and useful summarized information.",
            imageurl : "/images/HomeScreen.png",
            url : "/home"
        },
        {
            title : "Loading",
            description : "It is used to notify the user that the application is in the process of loading, with its corresponding length progress.",
            imageurl : "/images/LoadingScreen.png",
            url : "/loading-screen"
        },
        {
            title : "Login",
            description : "Used in conjunction with a basic form to allow the user to access the program if he is enrolled in it, or to register.",
            imageurl : "/images/LoginScreen.png",
            url : "/login"
        },
        {
            title : "Onboarding",
            description : "Used to assist the application's first usage by displaying the stages of use or the major functions of the program.",
            imageurl : "/images/OnBoarding.png",
            url : "/onboarding"
        },
        {
            title : "Simple Form",
            description : "Used to answer questionnaires, exemplifying various sorts of questions, with more simple parts and subsections, allowing the user to know where he is at the moment and how many steps left to finish.",
            imageurl : "/images/SimpleFormScreen.png",
            url : "/simple-form"
        },
        {
            title : "Simple List",
            description : "It is used to display tables with basic lists, for status queries, or for a rapid generic search.",
            imageurl : "/images/SimpleListScreen.png",
            url : "/simple-list"
        }
    ];

    return (

        <LayoutTopWrapperLSG title="Screen Templates" halfSize={false} breadcrumbs={[
            <Link to="/">Home</Link>,
            <span>Screen Templates</span>
          ]}>

            <div className="margin-bottom-l font-size-h6">Start building apps with pre-assembled screens following UX guidelines and using your own data.</div>
            <div className="screen-template-list">
                {                    
                    screenTemplatesList.map((screen, screenIndex) => 
                        <PreviewScreenTemplate 
                            title={screen.title} 
                            description={screen.description} 
                            url={screen.url} 
                            imageurl={screen.imageurl} 
                            key={screenIndex}
                        />
                    )
                }
            </div>
        </LayoutTopWrapperLSG>  
    )
}