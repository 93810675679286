import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import PreviewComponent from '../app-components/PreviewComponent';

export default function PositionedPopper() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const samplePopper = "<Popper className='popper'>" +
                    "\n\t<Paper className='text-secondary'>" +
                    "\n\t\t<MessageOutlinedIcon className='margin-right-base'/>" +
                    "\n\t\t<span className='font-size-s'>You have a new message</span>" +
                    "\n\t</Paper>" +
                    "\n</Popper>";
                 

  return (
    <div>
        <PreviewComponent title="" description="" code={samplePopper}>
            <Box sx={{ width: 500 }}>
                <Popper className='popper' open={open} anchorEl={anchorEl} placement={placement}>
                    <Paper className='text-secondary'>
                        <MessageOutlinedIcon className="margin-right-base"/>
                        <span className="font-size-s">You have a new message</span>
                    </Paper>
                </Popper>
            <Grid container justifyContent="center">
                <Grid item>
                    <Button className="margin-bottom-base"onClick={handleClick('top-start')}>top-start</Button>
                    <Button className="margin-x-base margin-bottom-base" onClick={handleClick('top')}>top</Button>
                    <Button className="margin-bottom-base" onClick={handleClick('top-end')}>top-end</Button>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={6}>
                    <Button onClick={handleClick('left-start')}>left-start</Button>
                    <br />
                    <Button className="margin-y-base" onClick={handleClick('left')}>left</Button>
                    <br />
                    <Button className="margin-bottom-base" onClick={handleClick('left-end')}>left-end</Button>
                </Grid>
            <Grid item container xs={6} alignItems="flex-end" direction="column">
                <Grid item>
                    <Button  onClick={handleClick('right-start')}>right-start</Button>
                </Grid>
                <Grid item>
                    <Button className="margin-y-base" onClick={handleClick('right')}>right</Button>
                </Grid>
                <Grid item>
                    <Button className="margin-bottom-base" onClick={handleClick('right-end')}>right-end</Button>
                </Grid>
            </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item>
                    <Button onClick={handleClick('bottom-start')}>bottom-start</Button>
                    <Button className="margin-x-base" onClick={handleClick('bottom')}>bottom</Button>
                    <Button onClick={handleClick('bottom-end')}>bottom-end</Button>
                </Grid>
            </Grid>
            </Box>
        </PreviewComponent>
    </div>    
  );
}