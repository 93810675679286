import React from "react";
import LayoutSideWrapper from '../app-components/LayoutSideWrapper';
import { Link } from 'react-router-dom';
import { Typography, Button, useMediaQuery, Grid, Card, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputLabel, TextField, Divider, Badge, Checkbox, Box, Drawer, TableSortLabel, Stack } from "@mui/material";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import InfoSnackbar from './Commons/InfoSnackbar';
import ActionSheet from "../theme/custom-components/action-sheet/ActionSheet";


export default function DetailConsultInformation() {

    const isNotDestktop = useMediaQuery('(max-width:820px)');

    //CREATE TABLE
    const rows = React.useMemo(() =>[
        {name: 'Black and silver headphones', quantity: 1831},
        // {name: 'Black and silver headphones', quantity: 1831},
        // {name: 'Black and silver headphones', quantity: 1831},
        {name: 'White tubular speaker', quantity: 4243},
        // {name: 'White tubular speaker', quantity: 4243},
        // {name: 'White rectangular speaker', quantity: 4567},
        {name: 'Laptop case grey', quantity: 4307},
        // {name: 'White in-ear pods', quantity: 3561},
        // {name: 'White in-ear pods', quantity: 3561},
      ], []);

//SORT TABLE

const [sortConfig, setSortConfig] = React.useState({ key: null, direction: 'ascending' });

const sortedData = React.useMemo(() => {
  let sortableData = [...rows];
  if (sortConfig.key) {
    sortableData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
  }
  return sortableData;
}, [rows, sortConfig]);

const requestSort = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  setSortConfig({ key, direction });
};


 //SNACKBAR MESSAGE

 const [openSnackbar, setOpenSnackbar] = React.useState(false);

 const handleClickSnackbar = () => {
   setOpenSnackbar(true);
   return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
 };

 const handleCloseSnackbar = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }

   setOpenSnackbar(false);
 };

//ACTION BUTTONS & ACTION SHEETS

const [openActionSheet, setopenActionSheet] = React.useState(false);
const toggleActionSheet = (newOpen) => () => {
  setopenActionSheet(newOpen);
};

      function actionButton() {
        return (

            <Stack direction="row" className="margin-bottom-m">
                {isNotDestktop ? (
                <div>
                    <Badge badgeContent={3}>
                        <Button className="btn-icon" onClick={toggleActionSheet(true)}> <MoreVertRoundedIcon /> </Button>
                    </Badge>

                    <ActionSheet  open={openActionSheet} onClose={toggleActionSheet(false)} onCancelClick={toggleActionSheet(false)}	cancelText='Cancel'>
                        <Button className='full-width' onClick={handleClickSnackbar}>Edit</Button>
                        <Button className='full-width'onClick={handleClickSnackbar}>Print</Button>
                        <Button className='full-width'onClick={toggleDrawer("right", true)}>Comments (3)</Button>
                    </ActionSheet>

                    <Drawer
                        anchor={"right"}
                        open={state["right"]}
                        onClose={toggleDrawer("right", false)}
                    >
                        {drawerContent("right")}
                    </Drawer>
                </div>

                ) : (
                <>

                  <Typography className="text-align-left margin-right-xxl">
                    <Stack>
                      <span className="text-neutral-7 ">Latest submit</span>
                      <span className=" font-bold">Sep. 7, 2023 16:50</span>
                    </Stack>
                  </Typography>
                  <Typography className="text-align-left margin-right-xxl">
                    <Stack>
                      <span className="text-neutral-7 ">Created on</span>
                      <span className=" font-bold">Sep. 7, 2023 16:50</span>
                    </Stack>
                  </Typography>
                  <Button className="margin-right-base" onClick={handleClickSnackbar}> 
                      <CloseRoundedIcon className="margin-right-s" /> 
                      <span>Cancel</span>  
                  </Button>
                  <Button className='primary' onClick={handleClickSnackbar}>
                      <SaveOutlinedIcon className="margin-right-s"/>
                      <span>Save</span>
                  </Button>
                </>
                )}
            </Stack>
         );
    }

//SIDEBAR
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };
    
      const drawerContent = (anchor) => (
        <Box
              className="margin-base not-desktop-sidebar" 
              onClick={toggleDrawer(anchor, true)}   
            >
            <div>
                <Card className='padding-m'>
                    <CardContent>
                        <h2 className="margin-bottom-m">Comments</h2>
                        <div className="margin-bottom-m">
                            <InputLabel htmlFor="id-comments">Internal comments</InputLabel>
                            <TextField
                                id="id-comments"
                                className="full-width"
                                multiline
                                rows={4}
                                placeholder="Write your comment here..."
                            />
                            <div className="text-neutral-7 text-align-right">Available characters: 500 of 500...</div>
                        </div>
                        <Button className=" margin-bottom-s" onClick={handleClickSnackbar}> Add Comment</Button>

                        <Divider className="margin-y-m"/>

                        <div className="font-bold ">Last tuesday, the data was updated. Please read over.</div>
                        <Stack direction= "row" alignItems="center" className="margin-top-s">
                            <PersonOutlineRoundedIcon/>
                            <span className="margin-left-s">John Smith</span>
                        </Stack>
                        <Stack direction= "row" alignItems="center" className="text-neutral-7 margin-top-s">
                            <ScheduleRoundedIcon/>
                            <span className="margin-left-s "> Sep. 7, 2023 16:50</span>
                        </Stack>

                        <Divider className="margin-y-m"/>

                        <div className="font-bold ">Last tuesday, the data was updated. Please read over.</div>
                        <Stack direction= "row" alignItems="center" className="margin-top-s">
                            <PersonOutlineRoundedIcon/>
                            <span className="margin-left-s">John Smith</span>
                        </Stack>
                        <Stack direction= "row" alignItems="center" className="text-neutral-7 margin-top-s">
                            <ScheduleRoundedIcon/>
                            <span className="margin-left-s "> Sep. 7, 2023 16:50</span>
                        </Stack>

                        <Divider className="margin-y-m"/>

                        <div className="font-bold ">Last tuesday, the data was updated. Please read over.</div>
                        <Stack direction= "row" alignItems="center" className="margin-top-s">
                            <PersonOutlineRoundedIcon/>
                            <span className="margin-left-s">John Smith</span>
                        </Stack>
                        <Stack direction= "row" alignItems="center" className="text-neutral-7 margin-top-s">
                            <ScheduleRoundedIcon/>
                            <span className="margin-left-s "> Sep. 7, 2023 16:50</span>
                        </Stack>

                    </CardContent>
                </Card>
            </div>
        </Box>
      );


    return(

        <LayoutSideWrapper 
        title="Detail Consult Information" 
        actions = {actionButton()}
        breadcrumbs={[
        <Link key="home">Home</Link>,
        <Link key="products">Products</Link>,
        <span key="detail-consult-information">Detail Consult Information</span>
        ]}>

    {openSnackbar && <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />} 
    

        {isNotDestktop ? (
            
            <Stack direction="row" className="margin-bottom-m">
              <Typography className="text-align-left margin-right-xxl">
                <Stack>
                  <span className="text-neutral-7">Latest submit</span>
                  <span className=" font-bold">Sep. 7, 2023 16:50</span>
                </Stack>
              </Typography>
              <Typography className="text-align-left margin-right-xxl">
                <Stack>
                  <span className="text-neutral-7">Created on</span>
                  <span className="font-bold">Sep. 7, 2023 16:50</span>
                </Stack>
              </Typography>
            </Stack>

        ) : (
        <>

        </>
        )}
       
       {isNotDestktop ? (
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
            <Card>
                <CardContent>
                <h2 className="margin-bottom-m">Form</h2>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={6} md={6}>
                    <div className="text-neutral-7 text-align-left">Number</div>
                        <div className="margin-bottom-m" >0000000254550000004</div>
                        <div className="text-neutral-7 text-align-left">Created by</div>
                        <div className="margin-bottom-m" >Tiago Almeida</div>
                        <div className="text-neutral-7 text-align-left">Mechanics</div>
                        <div >Discount, Bonus, Rappel</div>
                    </Grid>
                    <Grid className="text-align-left" item xs={12} sm={6} md={6}>
                        <div className="text-neutral-7 ">Status</div>
                        <div className="margin-bottom-m" >Regularized lending</div>
                        <div className="text-neutral-7 text-align-left">Order Number</div>
                        <div className="margin-bottom-m" >Absent work orders</div>
                        <div className="text-neutral-7 text-align-left">Type of agreement</div>
                        <div className="margin-bottom-m" >New</div>
                    </Grid>
                </Grid>
                </CardContent>
            </Card>

            <Card className='margin-top-m padding-m'>
                <CardContent>
                    <h2 className="margin-bottom-m">Request</h2>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4}>
                        <div className="text-neutral-7 text-align-left">Client number</div>
                        <div className="margin-bottom-m" >1128100620</div>
                        <div className="text-neutral-7 text-align-left">Activity</div>
                        <div className="margin-bottom-m" >Regularized lending</div>
                        <div className="text-neutral-7 text-align-left">Start date</div>
                        <div className="margin-bottom-m">Sep. 7, 2023 16:50</div>
                        <div className="text-neutral-7 text-align-left">Priority</div>
                        <div className="margin-bottom-s">Normal</div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <div className="text-neutral-7 text-align-left">Client</div>
                        <div className="margin-bottom-m">Restaurante Brasinha</div>
                        <div className="text-neutral-7 text-align-left">Potential segm</div>
                        <div className="margin-bottom-m">B</div>
                        <div className="text-neutral-7 text-align-left">End date</div>
                        <div className="margin-bottom-m">Sep. 7, 2023 16:50</div>
                        <div className="text-neutral-7 text-align-left">Motive</div>
                        <div className="margin-bottom-s">Regularized lending</div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <div className="text-neutral-7 text-align-left">Seg level 3</div>
                        <div className="margin-bottom-m">Restaurant</div>
                    </Grid>
                </Grid>
                  <div className="text-neutral-7 text-align-left">Observations</div>
                  <div className="margin-bottom-m">Use agile frameworks to provide high-level overviews a solid summary. Corporate strategy iterations encourage group collaboration to strengthen the overall value offering. Develop a more comprehensive understanding of disruptive innovation via workplace empowerment and diversity.</div>
                </CardContent>
            </Card>

            <Card className="margin-top-m">
                <CardContent>
                    <h2 className="margin-bottom-m">Number of distributor orders</h2>
                    <TableContainer>
                        <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Checkbox /></TableCell>
                                <TableCell  onClick={() => requestSort('name')} style={{ cursor: 'pointer' }}>
                                    <TableSortLabel
                                        active={sortConfig.key === 'name'}
                                        direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell  onClick={() => requestSort('quantity')} style={{ cursor: 'pointer' }}>
                                    <TableSortLabel
                                        active={sortConfig.key === 'quantity'}
                                        direction={sortConfig.key === 'quantity' ? sortConfig.direction : 'asc'}
                                    >
                                        Quantity
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell><Checkbox /></TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell className='text-align-left'>{row.quantity}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Grid>
    </Grid>

              ) : (
                <>
            <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Card>
                            <CardContent>
                            <h2 className="margin-bottom-m">Form</h2>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={6} md={6}>
                                    <div className="text-neutral-7 text-align-left">Number</div>
                                    <div className="margin-bottom-m" >0000000254550000004</div>
                                    <div className="text-neutral-7 text-align-left">Created by</div>
                                    <div className="margin-bottom-m" >Tiago Almeida</div>
                                    <div className="text-neutral-7 text-align-left">Mechanics</div>
                                    <div className="margin-bottom-m" >Discount, Bonus, Rappel</div>
                                </Grid>
                                <Grid className="text-align-left" item xs={12} sm={6} md={6}>
                                    <div className="text-neutral-7 ">Status</div>
                                    <div className="margin-bottom-m" >Regularized lending</div>
                                    <div className="text-neutral-7 text-align-left">Order Number</div>
                                    <div className="margin-bottom-m" >Absent work orders</div>
                                    <div className="text-neutral-7 text-align-left">Type of agreement</div>
                                    <div className="margin-bottom-m" >New</div>
                                </Grid>
                            </Grid>
                            </CardContent>
                        </Card>

                        <Card className='margin-top-m padding-m'>
                            <CardContent>
                                <h2 className="margin-bottom-m">Request</h2>
                                <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="text-neutral-7 text-align-left">Client number</div>
                                    <div className="margin-bottom-m" >1128100620</div>
                                    <div className="text-neutral-7 text-align-left">Activity</div>
                                    <div className="margin-bottom-m" >Regularized lending</div>
                                    <div className="text-neutral-7 text-align-left">Start date</div>
                                    <div className="margin-bottom-m">Sep. 7, 2023 16:50</div>
                                    <div className="text-neutral-7 text-align-left">Priority</div>
                                    <div className="margin-bottom-m">Normal</div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="text-neutral-7 text-align-left">Client</div>
                                    <div className="margin-bottom-m">Restaurante Brasinha</div>
                                    <div className="text-neutral-7 text-align-left">Potential segm</div>
                                    <div className="margin-bottom-m">B</div>
                                    <div className="text-neutral-7 text-align-left">End date</div>
                                    <div className="margin-bottom-m">Sep. 7, 2023 16:50</div>
                                    <div className="text-neutral-7 text-align-left">Motive</div>
                                    <div className="margin-bottom-m">Regularized lending</div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="text-neutral-7 text-align-left">Seg level 3</div>
                                    <div className="margin-bottom-m">Restaurant</div>
                                </Grid>
                            </Grid>
                              <div className="text-neutral-7 text-align-left">Observations</div>
                              <div className="margin-bottom-m">Use agile frameworks to provide high-level overviews a solid summary. Corporate strategy iterations encourage group collaboration to strengthen the overall value offering. Develop a more comprehensive understanding of disruptive innovation via workplace empowerment and diversity.</div>
                            </CardContent>
                        </Card>

                        <Card className="margin-top-m">
                            <CardContent>
                                <h2 className="margin-bottom-m">Number of distributor orders</h2>
                                <TableContainer>
                                    <Table>
                                    <TableHead>
                                        <TableRow>
                                        <TableCell><Checkbox /></TableCell>
                                        <TableCell className="">Name</TableCell>
                                        <TableCell className='text-align-right '>Quantity</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell><Checkbox /></TableCell>
                                            <TableCell className="">{row.name}</TableCell>
                                            <TableCell className='text-align-right '>{row.quantity}</TableCell>
                                        </TableRow>
                                        ))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <Card className='padding-m'>
                            <CardContent>
                                <h2 className="margin-bottom-m">Comments</h2>

                                <div className="margin-bottom-m">
                                    <InputLabel htmlFor="id-comments">Internal comments</InputLabel>
                                    <TextField
                                        id="id-comments"
                                        className="full-width"
                                        multiline
                                        rows={4}
                                        placeholder="Write your comment here..."
                                    />
                                    <div className="text-neutral-7 text-align-right">Available characters: 500 of 500...</div>
                                </div>
                                <Button className=" margin-bottom-s" onClick={handleClickSnackbar}> Add Comment</Button>

                                <Divider className="margin-y-m"/>

                                <div className="font-bold ">Last tuesday, the data was updated. Please read over.</div>
                                <Stack direction= "row" alignItems="center" className="margin-top-s">
                                    <PersonOutlineRoundedIcon/>
                                    <span className="margin-left-s">John Smith</span>
                                </Stack>
                                <Stack direction= "row" alignItems="center" className="text-neutral-7 margin-top-s">
                                    <ScheduleRoundedIcon/>
                                    <span className="margin-left-s "> Sep. 7, 2023 16:50</span>
                                </Stack>

                                <Divider className="margin-y-m"/>

                                <div className="font-bold ">Last tuesday, the data was updated. Please read over.</div>
                                <Stack direction= "row" alignItems="center" className="margin-top-s">
                                    <PersonOutlineRoundedIcon/>
                                    <span className="margin-left-s">John Smith</span>
                                </Stack>
                                <Stack direction= "row" alignItems="center" className="text-neutral-7 margin-top-s">
                                    <ScheduleRoundedIcon/>
                                    <span className="margin-left-s "> Sep. 7, 2023 16:50</span>
                                </Stack>

                                <Divider className="margin-y-m"/>

                                <div className="font-bold ">Last tuesday, the data was updated. Please read over.</div>
                                <Stack direction= "row" alignItems="center" className="margin-top-s">
                                    <PersonOutlineRoundedIcon/>
                                    <span className="margin-left-s">John Smith</span>
                                </Stack>
                                <Stack direction= "row" alignItems="center" className="text-neutral-7 margin-top-s">
                                    <ScheduleRoundedIcon/>
                                    <span className="margin-left-s "> Sep. 7, 2023 16:50</span>
                                </Stack>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                </>
                )}

        </LayoutSideWrapper>

    )

}