import * as React from 'react';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import PreviewComponent from '../app-components/PreviewComponent';

export default function SimpleBadge() {

  const sample_badges ='<Badge badgeContent={4}>' + 
                    '\n\t<MailIcon />' +
                    '\n</Badge>'

const sample_badges99 ='<Badge badgeContent={100} className="background-success">' + 
                    '\n\t<MailIcon />' +
                    '\n</Badge>'

  return (
    <div>
        <PreviewComponent title="" description="" code={sample_badges}>
            <Badge badgeContent={4}>
              <MailIcon />
            </Badge>
        </PreviewComponent>

        <PreviewComponent title="With Color" description="" code={sample_badges99}>
            <Badge className="background-success" badgeContent={100}>
              <MailIcon />
            </Badge>
        </PreviewComponent>
    </div>
  );
}