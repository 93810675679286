import * as React from 'react';
import { Alert, Snackbar } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function InfoSnackbar({ open, onClose, message, autoHideDuration }) {
    React.useEffect(() => {
      let timer;
      if (open) {
        timer = setTimeout(() => {
          onClose();
        }, autoHideDuration || 5000); // Default autoHideDuration to 5000ms (5 seconds)
      }
      return () => {
        clearTimeout(timer);
      };
    }, [open, onClose, autoHideDuration]);
  
    return (
      <Snackbar
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert className="info" onClose={onClose}>
          <InfoOutlinedIcon />
          {message || 'Implement the required logic'}
        </Alert>
      </Snackbar>
    );
  }
  
  export default InfoSnackbar;