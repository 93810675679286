import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PreviewComponent from '../app-components/PreviewComponent';

export default function RadioButtonsGroup() {

    const sampleCode_column = '<RadioGroup defaultValue="High">' + 
                                '\n\t<FormControlLabel value="High" control={<Radio />} label="High" />' + 
                                '\n\t<FormControlLabel value="Low" control={<Radio />} label="Low" />' + 
                              '\n</RadioGroup>';
    const sampleCode_row = '<RadioGroup row defaultValue="High">' + 
                              '\n\t<FormControlLabel value="High" control={<Radio />} label="High" />' + 
                              '\n\t<FormControlLabel value="Low" control={<Radio />} label="Low" />' + 
                            '\n</RadioGroup>';
    const sampleCode_disabledSelected = '<RadioGroup defaultValue="High">' + 
                                          '\n\t<FormControlLabel value="High" disabled control={<Radio />} label="High" />' + 
                                          '\n\t<FormControlLabel value="Low" disabled control={<Radio />} label="Low" />' + 
                                        '\n</RadioGroup>';

  return (
    <div>
      <PreviewComponent title="Column" description="" code={sampleCode_column}>
        <RadioGroup defaultValue="High">
            <FormControlLabel value="High" control={<Radio />} label="High" />
            <FormControlLabel value="Low" control={<Radio />} label="Low" />
          </RadioGroup>
      </PreviewComponent>
      
      <PreviewComponent title="Row" description="" code={sampleCode_row}>
        <RadioGroup row defaultValue="High">
          <FormControlLabel value="High" control={<Radio />} label="High" />
          <FormControlLabel value="Low" control={<Radio />} label="Low" />
        </RadioGroup>
      </PreviewComponent>

      <PreviewComponent title="Disabled Selected" description="" code={sampleCode_disabledSelected}>
        <RadioGroup defaultValue="High">
          <FormControlLabel value="High" disabled control={<Radio />} label="High"/>
          <FormControlLabel value="Low" disabled  control={<Radio className='test' />} label="Low"/>
        </RadioGroup>
      </PreviewComponent> 
    </div>
  );
}