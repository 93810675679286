import * as React from 'react';
import  Default from './Default_Sample';
import Disabled from './Disabled_Sample';
import SampleGrouped from './Grouped_Sample'
import DisabledOptions from './DisabledOptions_Sample';
// import CustomizedHook from './CustomizedHook_Sample';
import CheckboxesTags from './Checkboxes_Sample';



export default function AutoComplete_Sample() {

  return (
      <div>      

        <Default/>

        <Disabled/>

        <SampleGrouped/>

        <DisabledOptions/>


              {/* Don´t deploy for now */}
        {/* <h3>Customized hook</h3>

            <CustomizedHook/>
        <br/> */}

        <CheckboxesTags/>
          
      </div>
  );
}