import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Stack, Input, TextField, InputAdornment } from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PreviewComponent from '../app-components/PreviewComponent';

export default function TextField_Sample() {

  const sampleDefault = '<TextField placeholder="Placeholder" />';

  const sampleDefaultLabel = '<TextField label="Label" />';

  const sampleFixedLabel = '<InputLabel htmlFor="id-name">Label</InputLabel>\n' +
                           '<TextField id="id-name" placeholder="Placeholder" />';

  const sampleHelperText = '<TextField placeholder="Placeholder" helperText="Some important text" />';

  const sampleForm = '<TextField label="Required" required />\n' +
  '<TextField label="Disabled" disabled />\n' +
  '<TextField label="Read Only" InputProps={{readOnly: true,}} />';

  const sampleValidation = '<TextField placeholder="Error" helperText="Error Message" error />';

  const sampleMultiline = '<TextField\n\t' +
  'multiline\n\t' +
  'rows={4}\n\t' +
  'placeholder="Placeholder"\n' +
  '/>';
  
  const sampleIcon = '<TextField label="Icon on the left"\n' +
  ' InputProps={{startAdornment: (<InputAdornment position="start">\n' +
  ' <PermIdentityIcon/></InputAdornment>),}}/>';

  const samplePassword = 
  '<InputLabel htmlFor="id-name">Password</InputLabel>\n' +
  '<Input id="id-name" className="password" type={showPassword ? "text" : "password"} endAdornment={\n\t' +
  '  <InputAdornment position="end">\n\t\t' +
  '    <IconButton>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>\n\t' +
  '  </InputAdornment>}/>';
     

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <PreviewComponent title="Without Animated Label" description="" code={sampleDefault}>
            <TextField  placeholder="Placeholder"/>
      </PreviewComponent>

      <PreviewComponent title="With Animated Label" description="" code={sampleDefaultLabel}>
            <TextField label="Label" />
      </PreviewComponent>

      <PreviewComponent title="With Fixed Label" description="" code={sampleFixedLabel}>
            <InputLabel htmlFor='id-name'>Label</InputLabel>
            <TextField  id='id-name' placeholder="Placeholder"/>
      </PreviewComponent>

      <PreviewComponent title="With Helper Text" description="" code={sampleHelperText}>
            <TextField placeholder="Placeholder" helperText="Some important text"/>    
      </PreviewComponent>
    
      <PreviewComponent title="Form Props" description="" code={sampleForm}>
        <Stack spacing={2} direction="row">
            <TextField label="Required" required />
            <TextField label="Disabled" disabled />
            <TextField label="Read Only" InputProps={{
                    readOnly: true,
                  }} />
          </Stack>
      </PreviewComponent>

      <PreviewComponent title="Validation" description="" code={sampleValidation}>
        <Stack spacing={2} direction="row">
            <TextField placeholder='Error' helperText='Error Message' error />
        </Stack>
      </PreviewComponent>

      <PreviewComponent title="Multiline" description="" code={sampleMultiline}>
        <TextField
            multiline
            rows={4}
            placeholder="Placeholder"
        />
      </PreviewComponent>
   
      <PreviewComponent title="With Icon" description="" code={sampleIcon}>
        <TextField label="Icon on the left"  
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon/>
              </InputAdornment>
            ),
          }}/>
      </PreviewComponent>
      
      <PreviewComponent title="Password" description="" code={samplePassword}>
          <InputLabel htmlFor='id-name'>Password</InputLabel>
          <Input
            id="id-name"
            className='password'
            type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>}
           />
      </PreviewComponent>       
    </div>
  );
}