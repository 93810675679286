import * as React from 'react';
import LayoutSideWrapper from '../app-components/LayoutSideWrapper';
import { Link } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Divider, Grid, TextField, Button, InputAdornment, useMediaQuery, Select, MenuItem, FormControl, Stack} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import InfoSnackbar from './Commons/InfoSnackbar';
import SectionIndex from '../theme/custom-components/Section-index/SectionIndex';


export default function FAQs() {

    const isPhone = useMediaQuery('(max-width:620px)');

     //SNACKBAR MESSAGE

 const [openSnackbar, setOpenSnackbar] = React.useState(false);

 const handleClickSnackbar = () => {
   setOpenSnackbar(true);
   return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
 };

 const handleCloseSnackbar = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }

   setOpenSnackbar(false);
 };

 //SECTION LINK

 const sections = [
    'Attendance management',
    'Vacation management',
    'Expense map',
    'Event management',
    'Exclusive purchase agreements',
    'SAT forms',
  ];

// TO SHOW THE SELECTED DROPDOWN WHEN isPhone is active

const [selectedValue, setSelectedValue] = React.useState(1);

const handleSelectChange = (event) => {
  const newValue = event.target.value;
  setSelectedValue(newValue);
}

    return(

        <LayoutSideWrapper 
        title="FAQs" 
 
        breadcrumbs={[
        <Link to="/home">Home</Link>,
        <Link to="/products">Products</Link>,
        <span to="/FAQ's">FAQs</span>
        ]}>

            <div className='margin-bottom-m font-size-base text-neutral-7'>
                Look for the answers to your questions
            </div>

        {openSnackbar && <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />}
   
        {isPhone ? (

            <div>
                <Stack direction="row">
                    <TextField placeholder='Search by title or description' />
                    <Button className='primary margin-left-s'onClick={handleClickSnackbar}><SearchRoundedIcon/></Button>
                </Stack>
                <div>
                    <FormControl className=' margin-bottom-m full-width margin-top-xl'>
                        <Select
                        className='full-width'
                        value={selectedValue}
                        onChange={handleSelectChange}
                        >
                        <MenuItem value={1}>Attendance management</MenuItem>
                        <MenuItem value={2}>Vacation management</MenuItem>
                        <MenuItem value={3}>Expense map</MenuItem>
                        <MenuItem value={4}>Event management</MenuItem>
                        <MenuItem value={5}>Exclusive purchase agreements</MenuItem>
                        <MenuItem value={6}>SAT forms</MenuItem>
                        </Select>
                    </FormControl>

                    <section style={{ display: selectedValue === 1 ? 'block' : 'none' }}>
                        <Card className='margin-bottom-m'>
                            <CardContent>
                                    <Accordion className='accordion-item'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                Have I justified an absence and the day is still in red? Have I already justified an absence and the day is still in red?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion-item'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                Can I access the Attendance Portal and register absences / attendances / approvals when outside the SBG network?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book.
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion-item'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            I have a missing record of a mincing (input or output), how can I correct it?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                            </CardContent>
                        </Card>
                    </section>
                    <section style={{ display: selectedValue === 2 ? 'block' : 'none' }}>
                        <Card className='margin-bottom-m'>
                            <CardContent>
                                <div className='margin-bottom-base'>
                                    <Accordion className='accordion-item margin-top-l'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            Vacation?   
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion-item'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            Vacation?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </CardContent>
                        </Card>
                    </section>
                    <section style={{ display: selectedValue === 3 ? 'block' : 'none' }}>
                        <Card className='margin-bottom-m'>
                            <CardContent>
                                <div className='margin-bottom-base'>
                                    <Accordion className='accordion-item margin-top-l'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            Expense?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion-item'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            Expense?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </CardContent>
                        </Card>  
                    </section>
                    <section style={{ display: selectedValue === 4 ? 'block' : 'none' }}>
                        <Card className='margin-bottom-m'>
                            <CardContent>
                                <div className='margin-bottom-base'>
                                    <Accordion className='accordion-item margin-top-l'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            Event?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion-item'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            Event?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            orem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </CardContent>
                        </Card>
                    </section>
                    <section style={{ display: selectedValue === 5 ? 'block' : 'none' }}>
                        <Card className='margin-bottom-m'>
                            <CardContent>
                                <div className='margin-bottom-base'>
                                    <Accordion className='accordion-item margin-top-l'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            Agreements?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion-item'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            Agreements?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </CardContent>
                        </Card>
                    </section>
                    <section style={{ display: selectedValue === 6 ? 'block' : 'none' }}>
                        <Card >
                            <CardContent>
                                <div className='margin-bottom-base'>
                                    <Accordion className='accordion-item margin-top-l'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            Forms?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion-item'>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            Forms?
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </CardContent>
                        </Card>
                    </section>
                </div>
            </div>
            ):(
                <>

            <Stack direction="row">
                <TextField  className='width-300' placeholder='Search by title or description' 
                    InputProps={{startAdornment: (<InputAdornment><SearchRoundedIcon className='margin-x-s'/></InputAdornment>),}}
                />
                <Button className='primary margin-left-s' onClick={handleClickSnackbar}>Search</Button>
                <Button className='cancel margin-left-base' onClick={handleClickSnackbar}>Clear Search</Button>
            </Stack>

            <Grid container className='margin-top-xs'>
                <Grid item sm={3} md={3}> 
                    <SectionIndex sections={sections}/>
                </Grid>
                <Grid item sm={9} md={9} >
                    <section id="section1">
                    <Card className='margin-bottom-m' >
                        <CardContent>
                            <h2 className='padding-bottom-s'>Attendance management</h2>
                            <Divider/>
                            <div className='margin-bottom-base'>
                                <Accordion className='accordion-item margin-top-l'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                         Have I justified an absence and the day is still in red? Have I already justified an absence and the day is still in red?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className='accordion-item'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                         Can I access the Attendance Portal and register absences / attendances / approvals when outside the SBG network?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className='accordion-item'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        I have a missing record of a mincing (input or output), how can I correct it?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </CardContent>
                    </Card>
                    </section>
                    <section id="section2">
                    <Card className='margin-bottom-m'>
                        <CardContent>
                            <h2 className='padding-bottom-s'>Vacation management</h2>
                            <Divider/>
                            <div className='margin-bottom-base'>
                                <Accordion className='accordion-item margin-top-l'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                         Vacation?   
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className='accordion-item'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        Vacation?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </CardContent>
                    </Card>
                    </section>
                    <section id="section3">
                    <Card className='margin-bottom-m'>
                        <CardContent>
                            <h2 className='padding-bottom-s'>Expense map</h2>
                            <Divider/>
                            <div className='margin-bottom-base'>
                                <Accordion className='accordion-item margin-top-l'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        Expense?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className='accordion-item'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        Expense?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </CardContent>
                    </Card>
                    </section>
                    <section id="section4">
                    <Card className='margin-bottom-m'>
                        <CardContent>
                            <h2 className='padding-bottom-s'>Event management</h2>
                            <Divider/>
                            <div className='margin-bottom-base'>
                                <Accordion className='accordion-item margin-top-l'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        Event?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className='accordion-item'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        Event?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        orem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </CardContent>
                    </Card>
                    </section>
                    <section id="section5">
                    <Card className='margin-bottom-m'>
                        <CardContent>
                            <h2 className='padding-bottom-s'>Exclusive purchase agreements</h2>
                            <Divider/>
                            <div className='margin-bottom-base'>
                                <Accordion className='accordion-item margin-top-l'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        Agreements?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className='accordion-item'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        Agreements?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </CardContent>
                    </Card>
                    </section>
                    <section id="section6">
                    <Card >
                        <CardContent>
                            <h2 className='padding-bottom-s'>SAT forms</h2>
                            <Divider/>
                            <div className='margin-bottom-base'>
                                <Accordion className='accordion-item margin-top-l'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        Forms?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className='accordion-item'>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        Forms?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Lorem Ipsum is simply a dummy text from the typographic and printing industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a type kitchen and shuffled it to make a type specimen book
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </CardContent>
                    </Card>
                    </section>
                </Grid>
            </Grid>
               
               </>
            )}

        </LayoutSideWrapper>
    )
}