import React from 'react';
import { SwipeableDrawer, Box, Button } from '@mui/material';


const ActionSheet = ({ open, onClose, children, cancelText, onCancelClick}) => {

  return (
    <SwipeableDrawer
      className="action-sheet"
      anchor="bottom"
      open={open}
      onClose={onClose}
    >
      <Box className="action-sheet-puller">
        {children}
      </Box>
      <div className='action-sheet-cancel'> 
          <Button className='full-width cancel' onClick={onCancelClick}>{cancelText}</Button>
      </div>
    </SwipeableDrawer>
  );
};

export default ActionSheet;
