import * as React from 'react';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function LayoutTop_Sample() {

  const sampleCode_layoutTop =
  "<LayoutTop"+
    "\n\thalfSize={props.halfSize}"+
    "\n\ttitle={props.title}"+
    "\n\tbreadcrumbs={props.breadcrumbs}"+
    "\n\tactions={props.actions}"+
    "\n\tfooter={footer}"+
    "\n\tappLogoURL={appLogoURL}"+
    "\n\tappLogoFullURL={appLogoFullURL}"+
    "\n\tappName={appName}"+
    "\n\tappMenuPages={appMenuPages}"+
    "\n\tmenuBottom={userInfo()}"+
    "\n\theaderActions={headerActions}>"+
  "\n\t\tPage Content"+
    "\n</LayoutTop>";
  
  return (
    <div>
      <PreviewComponent title="" description="" code={sampleCode_layoutTop}>
        <h5>Properties:</h5>
        <ul>
          <li><strong>halfSize:</strong> defines the content area to have half its regular width;</li>
          <li><strong>title:</strong> defines the page title;</li>
          <li><strong>breadcrumbs:</strong> element used as a navigational aid in user interfaces;</li>
          <li><strong>actions:</strong> defines the possible actions of the user;</li>
          <li><strong>footer:</strong> defines the page footer;</li>
          <li><strong>appLogoURL:</strong> defines the logo for the whole app;</li>
          <li><strong>appLogoFullURL:</strong> defines a bigger logo for the app;</li>
          <li><strong>appName:</strong> defines the name of the app;</li>
          <li><strong>appMenuPages:</strong> defines the list of the different navigable pages;</li>
          <li><strong>menuBottom:</strong> displays the info (mail, name, etc.) of the logged in user;</li>
          <li><strong>headerActions:</strong> defines the possible actions of the user.</li>
        </ul>
      </PreviewComponent>
    </div>
  );
}