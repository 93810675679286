// import React from 'react';
// import { Link as ScrollIndex } from 'react-scroll';


// const SectionIndex = ({ sections }) => {
//   return (
//     <div className='section-index'>
//       {sections.map((section, index) => (
//         <ScrollIndex
//           key={index}
//           className='margin-bottom-s padding-left-m'
//           smooth
//           to={`section${index + 1}`}
//           offset={-200}
//         >
//           {section}
//         </ScrollIndex>
//       ))}
//     </div>
//   );
// };

// export default SectionIndex;

import React, { useState } from 'react';
import { Link as ScrollIndex } from 'react-scroll';

const SectionIndex = ({ sections }) => {
  const [activeSection, setActiveSection] = useState(0);

  const handleSetActiveSection = (index) => {
    console.log("teste")
    setActiveSection(index);
  };

  return (
    <div className='section-index'>
      {sections.map((section, index) => (
        <ScrollIndex
          key={index}
          className={`margin-bottom-s padding-left-m ${
            activeSection === index ? 'active' : ''
          }`}
          smooth
          to={`section${index + 1}`}
          offset={-200}
          onSetActive={(index) => handleSetActiveSection(index)}
        >
          {section}
        </ScrollIndex>
      ))}
    </div>
  );
};

export default SectionIndex;