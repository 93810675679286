import CodeBlock from "./CodeBlock";

export default function PreviewComponent ({title, subtitle, description, code, children}){

    return (

        <div className="component-preview">
            <div className="component-preview__title">
                <h2>{title}</h2>
            </div>
            <div className="component-preview__subtitle">
                <h3>{subtitle}</h3>
            </div>
            <div className="component-preview__description">
                {description}
            </div>
            <div className="component-preview__component">
                {children}
                {code ?
                <div className="component-preview__code">
                    <CodeBlock code={code}/>
                </div> : null}
            </div>
        </div>
    );
}