import React from 'react';

export default function LayoutLogin(props){

    return(
        
        <div className={"layout-login"} key="layout-login">
            <div className="layout-login__left">
                <div className="layout-login__left-background">
                    <img src={props.leftBackgroundURL} alt=""/>
                </div>
                <div className="layout-login__left-content">    
                    <div className="layout-login__left-title">{props.leftTitle}</div>
                    <div className="layout-login__left-description">{props.leftDescription}</div>
                </div>
            </div>
            <div className="layout-login__right">
                <div className="layout-login__right-content">
                    <div className="layout-login__right-app-logo">
                        <img src={props.appLogoURL} alt="Application Name Logo"/>
                    </div>
                    <div className="layout-login__right-app-name">{props.appName}</div>
                    <div className="layout-login__right-form-title">{props.formTitle}</div>

                    <div className="layout-login__right-form">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>

    )
}