import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function SelectOtherProps() {
  
  const sampleCode_otherprops =
                            "<FormControl disabled>" +
                            "\n\t<InputLabel >Age</InputLabel>" +
                            "\n\t<Select/>" +
                            "\n\t<FormHelperText>Disabled</FormHelperText>" +
                            "\n </FormControl>" +
                            "\n"+
                            "\n<FormControl error>" +
                            "\n\t<InputLabel>Age</InputLabel>" +
                            "\n\t<Select" +
                            "\n\t\tvalue={age}" +
                            "\n\t\tlabel='Age'" +
                            "\n\t\t<MenuItem value={10}>Ten</MenuItem>" +
                            "\n\t\t<MenuItem value={20}>Twenty</MenuItem>" +
                            "\n\t\t<MenuItem value={30}>Thirty</MenuItem>" +
                            "\n\t</Select>" +
                            "\n\t<FormHelperText>Error</FormHelperText>" +
                            "\n</FormControl>"+
                            "\n"+
                            "\n<FormControl>" +
                            "\n\t<InputLabel>Age</InputLabel>" +
                            "\n\t<Select" +
                            "\n\t\tvalue={age}" +
                            "\n\t\tlabel='Age'" +
                            "\n\t\tinputProps={{ readOnly: true }}>" +
                            "\n\t\t<MenuItem value={10}>Ten</MenuItem>" +
                            "\n\t\t<MenuItem value={20}>Twenty</MenuItem>" +
                            "\n\t\t<MenuItem value={30}>Thirty</MenuItem>" +
                            "\n\t</Select>" +
                            "\n\t<FormHelperText>Read only</FormHelperText>" +
                            "\n</FormControl>" +
                            "\n" +
                            "\n<FormControl required>" +
                            "\n\t<InputLabel>Age</InputLabel>" +
                            "\n\t<Select" +
                            "\n\t\tvalue={age}" +
                            "\n\t\tlabel='Age *'>" +
                            "\n\t\t<MenuItem value={10}>Ten</MenuItem>" +
                            "\n\t\t<MenuItem value={20}>Twenty</MenuItem>" +
                            "\n\t\t<MenuItem value={30}>Thirty</MenuItem>" +
                            "\n\t</Select>" +
                            "\n\t<FormHelperText>Required</FormHelperText>" +
                            "\n</FormControl>"

  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div>
        <PreviewComponent title="Other Props" description="" code={sampleCode_otherprops}>
            <FormControl className='min-width-150 margin-right-s' disabled>
              <InputLabel >Age</InputLabel>
              <Select/>
              <FormHelperText>Disabled</FormHelperText>
            </FormControl>
            
            <FormControl className='min-width-150 margin-right-s' error>
              <InputLabel>Age</InputLabel>
              <Select
                value={age}
                label="Age"
                onChange={handleChange}
                // renderValue={(value) => `⚠️  - ${value}`}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>Error</FormHelperText>
            </FormControl>

            <FormControl className='min-width-150 margin-right-s'>
              <InputLabel>Age</InputLabel>
              <Select
                value={age}
                label="Age"
                // onChange={handleChange}
                inputProps={{ readOnly: true }}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>Read only</FormHelperText>
            </FormControl>

            <FormControl required className='min-width-150'>
              <InputLabel>Age</InputLabel>
              <Select
                value={age}
                label="Age *"
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
        </PreviewComponent>
    </div>
  );
}