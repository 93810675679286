export default function PreviewScreenTemplate ({title, imageurl, url, description}){

    return (

        <a href={url} target="_blank" rel="noreferrer">
            <div className="screen-template-preview">
                <div className="screen-template-preview__image">
                    <img src={imageurl} alt={title + " Screen Template"}/>
                </div>
                <div className="screen-template-preview__title">
                    {title}
                </div>
                <div className="screen-template-preview__description">
                    {description}
                </div>
            </div>
        </a>
    );
}