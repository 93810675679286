import * as React from 'react';

import { Alert, Button, IconButton, Input, InputAdornment, InputLabel, Snackbar, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LayoutLogin from '../theme/custom-components/layout-login/LayoutLogin';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';

export default function Login() {

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    
    //props for login layout
    const leftTitle = "Welcome";
    const leftDescription = "Custom theme to be used with React applications using MUI framework.";
    const leftBackgroundURL = "/images/HiResources.bg_login.jpg";
    const appLogoURL = "/images/HiResources.Logo_Full.svg";
    const appName = "Application Name";
    const formTitle = "Log in to your account";
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

         //SNACKBAR MESSAGE
  
  function InfoSnackbar({ open, onClose }) {

    React.useEffect(() => {
      let timer;
      if (open) {
        timer = setTimeout(() => {
          onClose();
        }, 5000);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [open, onClose]);

    return (
      <Snackbar open={open} onClose={onClose} 
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
      }}>
        <Alert className="info" onClose={onClose}>
          <InfoOutlinedIcon />
          Implement the required logic
        </Alert>
      </Snackbar>
    );
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
    return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };


    return (
        <LayoutLogin 
            leftTitle = {leftTitle}
            leftDescription = {leftDescription} 
            leftBackgroundURL = {leftBackgroundURL}
            appLogoURL = {appLogoURL}
            appName = {appName}
            formTitle= {formTitle}
            >
            <form>
            {openSnackbar && <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />}
                <div>
                    <InputLabel htmlFor='username' required>Username</InputLabel>
                    <TextField 
                    InputProps={{startAdornment: (<InputAdornment position="start">
                      <PermIdentityIcon /></InputAdornment>),}}
                    required id='username' className="full-width"/>
                    <InputLabel htmlFor='password' required>Password</InputLabel>
                    <Input
                        className='password full-width'
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        startAdornment= {
                          <InputAdornment position="start">
                            <HttpsOutlinedIcon />
                          </InputAdornment>}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>}
                    />
                </div>
                <div className="margin-top-l">
                    <Button className='primary' onClick={handleClickSnackbar}>Log in</Button>
                </div>
            </form>
        </LayoutLogin>

    )
}