import LayoutSide from "../theme/custom-components/layout-side/LayoutSide";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "@mui/material";
import { useState } from "react";

export default function LayoutSideWrapper(props) {
  const appLogoURL = "../images/logo-250.png";
  const appLogoFullURL = "../images/logo-full.svg";
  const appName = "Application name";
  const headerActions = "";
  const userName = "John Smith";
  const userEmail = "johnsmith@email.com";
  const userPhoto = "JS";
  const footer = "";
  const menuItems = [
    { id: 1, label: "Dashboard", link: "/dashboard", submenu: [] },
    { id: 2, label: "Users", link: "/users", submenu: [] },
    {
      id: 3,
      label: "Account",
      link: "/Account",
      submenu: [{ label: "Profile" }, { label: "Settings" }],
    },
  ];

  const [openUser, setOpenUser] = useState(false);


  function userInfo() {
    const collapseUser = () => {
      setOpenUser(!openUser);
    };
    return (
      <List
        onMouseLeave={() => {
          setOpenUser(false);
        }}
      >
        <ListItemButton
          onClick={collapseUser}
          className="app-login-info__header"
        >
          <ListItemAvatar>
            <Avatar>{userPhoto}</Avatar>
          </ListItemAvatar>
          <div className="app-login-info__user-info">
            <ListItemText primary={userName} secondary={userEmail} />
            {openUser ? <ExpandLess /> : <ExpandMore />}
          </div>
        </ListItemButton>
        <Collapse in={openUser} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            className="app-login-info__submenu"
          >
            <ListItemButton>
              <ListItemIcon>
                <i
                  data-icon=""
                  class="fa-light fa-user fa-1x font-size-base text-neutral-7"
                ></i>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <i
                  data-icon=""
                  class="fa-light fa-power-off fa-1x font-size-base text-neutral-7"
                ></i>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    );
  }

  function sideFooter() {
    const link = (
      <Link href="https://www.hi-interactive.com/"> Hi Interactive</Link>
    );
    return <>powered by &nbsp; {link}</>;
  }

  return (
    <LayoutSide
      halfSize={props.halfSize}
      title={props.title}
      breadcrumbs={props.breadcrumbs}
      actions={props.actions}
      footer={footer}
      appLogoURL={appLogoURL}
      appLogoFullURL={appLogoFullURL}
      appName={appName}
      menuItems={menuItems}
      headerActions={headerActions}
      userInfo={userInfo()}
      sideFooter={sideFooter()}
    >
      {props.children}
    </LayoutSide>
  );
}
