import * as React from 'react';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function LayoutLogin_Sample() {

  
  const sampleCode_layoutLogin =
  "<LayoutLogin "+
  "\n\tleftTitle = {leftTitle}"+
  "\n\tleftDescription = {leftDescription} "+
  "\n\tleftBackgroundURL = {leftBackgroundURL}"+
  "\n\tappLogoURL = {appLogoURL}"+
  "\n\tappName = {appName}"+
  "\n\tformTitle= {formTitle}>"+
  "\n\t\tLogin Form Content"+
  "\n</LayoutLogin>";
  
  return (
    <div>
      <PreviewComponent title="" description="" code={sampleCode_layoutLogin}>
      <h5>Properties:</h5>
        <ul>
          <li><strong>leftBackgroundURL:</strong> defines the background of the left side of the page;</li>
          <li><strong>leftTitle:</strong> defines the title of the left side of the page;</li>
          <li><strong>leftDescription:</strong> provides a description on the left side of the page;</li>
          <li><strong>appLogoURL:</strong> defines the logo for the whole app;</li>
          <li><strong>appName:</strong> defines the name of the app.</li>
          <li><strong>formTitle:</strong> defines the title for the form.</li>
        </ul>
      </PreviewComponent>
    </div>
  );
}