import * as React from 'react';
import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import PreviewComponent from '../app-components/PreviewComponent';

export default function Paper_Sample() {

    const samplePaperShadow = "<Paper className='shadow-none'/>" +
    "\n<Paper className='shadow-s'/>" +
    "\n<Paper/> or <Paper className='shadow-m'/>" +
    "\n<Paper className='shadow-l'/>";
    

    const samplePaperOutline = "<Paper className='outline border-radius-soft'/>";
   
    const samplePaperSquared = "<Paper className='outline border-radius-none'/>";  


  return (
    <div>
        <PreviewComponent title="Shadow (none, S, M (default) and L)" description="" code={samplePaperShadow}>
            <Box sx={{display: 'flex',flexWrap: 'wrap','& > :not(style)': {
                m: 1,
                width: 128,
                height: 128,
                },}}>
                <Paper className='shadow-none'/>
                <Paper className='shadow-s'/>
                <Paper />
                <Paper className='shadow-l'/>
            </Box>
          </PreviewComponent>

          <PreviewComponent title="Border with Border Radius (and default shadow)" description="" code={samplePaperOutline}>
              <Box sx={{'& > :not(style)': {width: 128, height: 128,},}}> 
                <Paper  className="outline border-radius-soft" />
              </Box>
          </PreviewComponent>

          <PreviewComponent title="Border without Border Radius (and default shadow)" description="" code={samplePaperSquared}>
              <Box sx={{'& > :not(style)': {width: 128, height: 128,},}}> 
                <Paper sx={{width: 128, height: 128}} className='outline border-radius-none' />
              </Box>
          </PreviewComponent>
    </div>
  );
}