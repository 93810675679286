import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import LayoutBlankWrapper from '../app-components/LayoutBlankWrapper';

export default function LoadingScreen() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (

    <LayoutBlankWrapper>
        <img src="/images/logo-250.png" width="150px" height="150px" alt="Logo"/>
        <h5 className='margin-y-l'>LOADING...</h5>
        <LinearProgress sx={{width:236}} variant="determinate" value={progress} />
    </LayoutBlankWrapper>

  );
}