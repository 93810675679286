import LayoutTop from "../theme/custom-components/layout-top/LayoutTop";

export default function LayoutTopWrapperLSG(props){

    const footer = "2023 \u00A9 Powered by Hi Interactive";
    const appLogoURL = "../images/logo-250.png";
    const appLogoFullURL = "../images/logo-full.svg";
    const appName = "React Live Style Guide";
    const appMenuPages = [
        { id: 1, url: '/', label: 'Home' },
        { id: 2, url: '/get-started', label: 'Get Started' },
        { id: 3, url: '/styles', label: 'Styles' },
        { id: 4, url: '/components', label: 'Components' },
        { id: 5, url: '/screen-templates', label: 'Screen Templates' }
      ];  

    return(

        <LayoutTop 
                halfSize={props.halfSize}    
                title={props.title}
                breadcrumbs={props.breadcrumbs}
                actions={props.actions}
                footer={footer}
                appLogoURL={appLogoURL}
                appLogoFullURL={appLogoFullURL}
                appName={appName}
                appMenuPages={appMenuPages}
                >    
            {props.children}
        </LayoutTop>
        
    )
}