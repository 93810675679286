import * as React from 'react';
import {
  Card,
  Button,
  Popover,
  Grid,
  CardContent,
  ToggleButtonGroup,
  ToggleButton,
  CardMedia,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  TableSortLabel,
  Stack,
} from '@mui/material';
import LayoutSideWrapper from '../../app-components/LayoutSideWrapper';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { BarChart, PieChart } from '@mui/x-charts';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import Simplified_SimpleList from './Simplified_SimpleList';
import CircularProgressWithLabel from '../../theme/custom-components/circular-progress-label/CircularProgressWithLabel';
import CircularProgressWithoutLabel from '../../theme/custom-components/circular-progress-label/CircularProgressWithoutLabel';
import InfoSnackbar from '../Commons/InfoSnackbar';
import ActionSheet from '../../theme/custom-components/action-sheet/ActionSheet';

//HORIZONTAL BAR CHART

// const StyledPath = styled('path')(({ theme, color }) => ({
//   fill: 'none',
//   stroke: theme.palette.text[color],
//   shapeRendering: 'crispEdges',
//   strokeWidth: 1,
//   pointerEvents: 'none',
// }));

// function CartesianAxis() {
//   // Get the drawing area bounding box
//   const { left, top, width, height } = useDrawingArea();

//   const xTicks = [-2, -1, 1, 2, 3];

//   // Get the two scale
//   const xAxisScale = useXScale();
//   const xOrigin = xAxisScale(0);

//   return (
//     <React.Fragment>

// {xTicks.map((value) => (
//         <StyledPath
//           key={value}
//           d={`M ${xAxisScale(value)} ${top} l 0 ${height}`}
//           color="secondary"
//         />
//       ))}

//       <StyledPath d={`M ${xOrigin} ${top} l 0 ${height}`} color="primary" />

//     </React.Fragment>
//   );
// }

const dataset = [
  {
    score: 3,
    name: 'Maria',
  },
  {
    score: 5,
    name: 'José',
  },
  {
    score: 5,
    name: 'Carlos',
  },
  {
    score: 2,
    name: 'Joana',
  },
  {
    score: 6,
    name: 'Saraiva',
  },
];

const valueFormatter = (value) => `${value}`;

//VERTICAL BAR CHART
const volume2018 = [5, 5, 2, 10, 1, 15];
const volume2019 = [5, 6, 3, 5, 0, 15];
const LabelsX = ['January', 'February', 'March', 'April', 'May'];

// //PIE CHART
// const data1 = [
//   { label: 'Pressure 70%', value: 70, color: 'var(--color-primary)' },
//   { label: 'Crate 30%', value: 30, color: 'var(--color-neutral-5)' },
// ];

// const TOTAL = data1.map((item) => item.value).reduce((a, b) => a + b, 0);
// const getArcLabel = (params) => {
//   const percent = params.value / TOTAL;
//   return `${(percent * 100).toFixed(0)}%`;
// };

export default function Dashboard() {
  const isPhone = useMediaQuery('(max-width:620px)');
  const isNotDesktop = useMediaQuery('(max-width:820px)');

  // CARD POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //SNACKBAR MESSAGE

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
    return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  //ACTION BUTTONS

  function actionButton() {
    return (
      <div>
        {isPhone ? (
          <Button className="primary btn-icon" onClick={handleClickSnackbar}>
            {' '}
            <AddRoundedIcon />
          </Button>
        ) : (
          <>
            <Button className="primary" onClick={handleClickSnackbar}>
              {' '}
              <AddRoundedIcon className="margin-right-s" />
              Add Campaign
            </Button>
          </>
        )}
      </div>
    );
  }

  //CHANGE VERTICAL VIEW

  const [verticalView, setVerticalView] = React.useState('chart');

  const handleVerticalViewChange = () => {
    setVerticalView(verticalView === 'table' ? 'chart' : 'table');
  };

  //CHANGE HORIZONTAL VIEW

  const [horizontalView, sethorizontalView] = React.useState('chart');

  const handleHorizontalViewChange = () => {
    sethorizontalView(horizontalView === 'table' ? 'chart' : 'table');
  };

  //CHANGE PIE VIEW

  const [pieView, setPieView] = React.useState('chart');

  const handlePieViewChange = () => {
    setPieView(pieView === 'table' ? 'chart' : 'table');
  };

  //ACTION SHEETS
  //FAVORITES / SEE DETAILS
  const [openActionSheet, setopenActionSheet] = React.useState(false);
  const toggleActionSheet = (newOpen) => () => {
    setopenActionSheet(newOpen);
  };

  //FOR YEAR /MONTH / WEEK
  const [openActionCampaign, setopenActionCampaign] = React.useState(false);
  const toggleActionCampaign = (newOpen) => () => {
    setopenActionCampaign(newOpen);
  };

  //SMALL TABLE

  const rows = React.useMemo(() =>[
    {name:'Macbook Pro Retina', price: '150.99', sku: 2422, date: '01 jan. 2023'},
    {name:'iPhone 14 Pro Max 256GB', price: '1499.99', sku: 3756, date: '04 jan. 2023'},
    {name:'iPad Pro 1TB', price: '750', sku: 2354, date: '05 feb. 2023'},
    {name:'Macbook Pro', price: '1199.99', sku: 6701, date: '20 mar. 2023'},
    {name:'iPhone 14', price: '999.99', sku: 4907, date: '01 jan. 2023'},
  ], []);

  //SORT TABLE

  const [sortConfig, setSortConfig] = React.useState({
    key: null,
    direction: 'ascending',
  });

  const sortedData = React.useMemo(() => {
    let sortableData = [...rows];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === 'ascending' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    return sortableData;
  }, [rows, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <LayoutSideWrapper title="Dashboard" actions={actionButton()}>
      {openSnackbar && (
        <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />
      )}

      <ActionSheet open={openActionSheet} onClose={toggleActionSheet(false)} onCancelClick={toggleActionSheet(false)} cancelText="Cancel">
        <Button
          className="full-width"
          onClick={handleClickSnackbar}
        >
          Favorites
        </Button>
        <Button
          className="full-width"
          onClick={handleClickSnackbar}
        >
          See Details
        </Button>
      </ActionSheet>

      <ActionSheet
        open={openActionCampaign}
        onClose={toggleActionCampaign(false)}
        onCancelClick={toggleActionCampaign(false)} 
        cancelText="Cancel"
      >
        <Button
          className="full-width"
          onClick={handleClickSnackbar}
        >
          Year
        </Button>
        <Button
          className="full-width"
          onClick={handleClickSnackbar}
        >
          Month
        </Button>
        <Button
          className="full-width"
          onClick={handleClickSnackbar}
        >
          Week
        </Button>
      </ActionSheet>

      <div className="margin-bottom-m font-size-base text-neutral-7">
        Latest update: 1 Jun. 2022
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                <PersonOutlineRoundedIcon className="font-size-h1 text-neutral-7" />
                <Stack direction="column" className="margin-left-m">
                  <div className="font-size-display">178</div>
                  <div className="text-neutral-7">Soft booking</div>
                </Stack>
              </Stack>
              <EastRoundedIcon className="font-size-h3 text-primary" />
            </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <AssignmentOutlinedIcon className="font-size-h1 text-neutral-7" />
                  <Stack direction="column" className="margin-left-m">
                    <div className="font-size-display">365</div>
                    <div className="text-neutral-7">Hard booking</div>
                  </Stack>
                </Stack>
                <EastRoundedIcon className="font-size-h3 text-primary" />
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent >
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Stack direction="row" alignItems="center">
                    <RepeatRoundedIcon className="font-size-h1 text-neutral-7" />
                    <Stack direction="column" className="margin-left-m">
                      <div className="font-size-display">29</div>
                      <div className="text-neutral-7">Ongoing</div>
                    </Stack>
                  </Stack>
                  <EastRoundedIcon className="font-size-h3 text-primary" />
                </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent >
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Stack direction="row" alignItems="center">
                    <FormatListBulletedRoundedIcon className="font-size-h1 text-neutral-7" />
                    <Stack direction="column" className="margin-left-m">
                      <div className="font-size-display">1953</div>
                      <div className="text-neutral-7">All</div>
                    </Stack>
                  </Stack>
                  <EastRoundedIcon className="font-size-h3 text-primary" />
                </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Card className="margin-top-l">
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <h4> Campaign calendar </h4>
            {isNotDesktop ? (
              <Button className="btn-icon" onClick={toggleActionCampaign(true)}>
                <MoreVertRoundedIcon className="text-primary" />
              </Button>
            ) : (
              <>
                <ToggleButtonGroup onClick={handleClickSnackbar} exclusive>
                  <ToggleButton selected> Year </ToggleButton>
                  <ToggleButton> Month </ToggleButton>
                  <ToggleButton> Week </ToggleButton>
                </ToggleButtonGroup>
              </>
            )}
          </Stack>

          {/* <CartesianAxis/> */}
          <div className="horizontalBarChart">
            <BarChart
              height={300}
              margin={{ top: 24 }}
              dataset={dataset}
              series={[
                {
                  dataKey: 'score',
                  valueFormatter,
                  color: 'var(--color-primary)',
                },
              ]}
              layout="horizontal"
              yAxis={[
                { scaleType: 'band', dataKey: 'name', categoryGapRatio: 0.5 },
              ]}
              xAxis={[
                { min: 0, max: 12, tickMinStep: 2, label: 'Total Score' },
              ]}
            />
          </div>
        </CardContent>
      </Card>

      <Card className="margin-top-m">
        <CardContent>
         <Stack direction="row" justifyContent="space-between">
            <h4>Top 5 SKU’s review</h4>

            {isPhone ? (
              <Stack direction="row" className="margin-bottom-m">
                <Button
                  className="margin-right-base btn-icon"
                  onClick={toggleActionSheet(true)}
                >
                  <MoreVertRoundedIcon className="text-primary" />
                </Button>
                <ToggleButtonGroup
                  value={verticalView}
                  exclusive
                  onChange={handleVerticalViewChange}
                >
                  <ToggleButton value="table" className='btn-icon'>
                    {verticalView === 'table' ? (
                      <FormatListBulletedRoundedIcon />
                    ) : (
                      <WindowOutlinedIcon />
                    )}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            ) : (
              <>
                <Stack direction="row" className="margin-bottom-m">
                  <Button
                    className="margin-right-base small btn-icon"
                    onClick={isNotDesktop ? toggleActionSheet(true) : handleClick}
                  >
                    <MoreVertRoundedIcon className="text-primary" />
                  </Button>

                  <Popover
                    className="popover padding-none"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={handleClickSnackbar}>
                      <ListItemIcon>
                        <StarBorderRoundedIcon />
                      </ListItemIcon>
                      <ListItemText>Favorites</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClickSnackbar}>
                      <ListItemIcon>
                        <VisibilityOutlinedIcon onClick={handleClickSnackbar} />
                      </ListItemIcon>
                      <ListItemText>See Details</ListItemText>
                    </MenuItem>
                  </Popover>

                  <ToggleButtonGroup
                    value={verticalView}
                    exclusive
                    onChange={handleVerticalViewChange}
                  >
                    <ToggleButton value="table" className="small btn-icon">
                      <FormatListBulletedRoundedIcon />
                    </ToggleButton>
                    <ToggleButton value="chart" className="small btn-icon">
                      <WindowOutlinedIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </>
            )}
          </Stack>

          {verticalView === 'table' ? (
            // TABLE VIEW
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      onClick={() => requestSort('name')}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableSortLabel
                        active={sortConfig.key === 'name'}
                        direction={
                          sortConfig.key === 'name'
                            ? sortConfig.direction
                            : 'asc'
                        }
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      onClick={() => requestSort('date')}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableSortLabel
                        active={sortConfig.key === 'date'}
                        direction={
                          sortConfig.key === 'date'
                            ? sortConfig.direction
                            : 'asc'
                        }
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="text-align-right"
                      onClick={() => requestSort('sku')}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableSortLabel
                        active={sortConfig.key === 'sku'}
                        direction={
                          sortConfig.key === 'sku'
                            ? sortConfig.direction
                            : 'asc'
                        }
                      >
                        SKUs
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>
                        <Link>{row.name}</Link>
                      </TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell className="text-align-right">
                        {row.sku}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            // CHART VIEW
            <div className="barChart">
              <BarChart
                height={300}
                margin={{ top: 24 }}
                series={[
                  {
                    data: volume2018,
                    label: 'AC 2018 Volume',
                    id: 'volume2018Id',
                    color: 'var(--color-primary)',
                  },
                  {
                    data: volume2019,
                    label: 'AC 2019 Volume',
                    id: 'volume2019Id',
                    color: 'var(--color-secondary)',
                  },
                ]}
                xAxis={[
                  {
                    data: LabelsX,
                    scaleType: 'band',
                    categoryGapRatio: 0.7,
                    barGapRatio: 0.5,
                  },
                ]}
                legend={{
                  hidden: false,
                  position: {
                    vertical: 'bottom',
                    horizontal: 'middle',
                  },
                }}
              />
            </div>
          )}
        </CardContent>
      </Card>

      <Grid className="margin-top-m" container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Card sx={{height:400}}>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <h4>Price group (Top 5)</h4>
                {isPhone ? (
                  <Stack direction="row" className="margin-bottom-m">
                    <Button
                      className="margin-right-base btn-icon"
                      onClick={toggleActionSheet(true)}
                    >
                      <MoreVertRoundedIcon className="text-primary" />
                    </Button>
                    <ToggleButtonGroup
                      value={horizontalView}
                      exclusive
                      onChange={handleHorizontalViewChange}
                    >
                      <ToggleButton value="table" className="btn-icon">
                        {horizontalView === 'table' ? (
                          <FormatListBulletedRoundedIcon />
                        ) : (
                          <WindowOutlinedIcon />
                        )}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                ) : (
                  <>
                    <Stack direction="row" className="margin-bottom-m">
                      <Button
                        className="margin-right-base small btn-icon"
                        onClick={isNotDesktop ? toggleActionSheet(true) : handleClick}
                      >
                        <MoreVertRoundedIcon className="text-primary" />
                      </Button>

                      <ToggleButtonGroup
                        value={horizontalView}
                        exclusive
                        onChange={handleHorizontalViewChange}
                      >
                        <ToggleButton value="table" className="small btn-icon">
                          <FormatListBulletedRoundedIcon />
                        </ToggleButton>
                        <ToggleButton value="chart" className="small btn-icon">
                          <WindowOutlinedIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                  </>
                )}
              </Stack>

              {horizontalView === 'table' ? (
                // TABLE VIEW

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          onClick={() => requestSort('name')}
                          style={{ cursor: 'pointer' }}
                        >
                          <TableSortLabel
                            active={sortConfig.key === 'name'}
                            direction={
                              sortConfig.key === 'name'
                                ? sortConfig.direction
                                : 'asc'
                            }
                          >
                            Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          className="text-align-right"
                          onClick={() => requestSort('price')}
                          style={{ cursor: 'pointer' }}
                        >
                          <TableSortLabel
                            active={sortConfig.key === 'price'}
                            direction={
                              sortConfig.key === 'price'
                                ? sortConfig.direction
                                : 'asc'
                            }
                          >
                            Price
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell>
                            <Link>{row.name}</Link>
                          </TableCell>
                          <TableCell className="text-align-right">
                            ${row.price}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                //CHART VIEW
                <div>
                  <BarChart
                    height={300}
                    margin={{ top: 24 }}
                    dataset={dataset}
                    series={[
                      {
                        dataKey: 'score',
                        valueFormatter,
                        color: 'var(--color-primary)',
                      },
                    ]}
                    layout="horizontal"
                    yAxis={[
                      {
                        scaleType: 'band',
                        dataKey: 'name',
                        categoryGapRatio: 0.5,
                      },
                    ]}
                    xAxis={[
                      { min: 0, max: 12, tickMinStep: 2, label: 'Total Score' },
                    ]}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card sx={{height:400}}>
            <CardContent>
            <Stack direction="row" justifyContent="space-between">
                <h4>Campaign indicators</h4>
                {isPhone ? (
                  <Stack direction="row" className="margin-bottom-m">
                    <Button
                      className="margin-right-base btn-icon"
                      onClick={toggleActionSheet(true)}
                    >
                      <MoreVertRoundedIcon className="text-primary" />
                    </Button>
                    <ToggleButtonGroup
                      value={pieView}
                      exclusive
                      onChange={handlePieViewChange}
                    >
                      <ToggleButton value="table" className="btn-icon">
                        {pieView === 'table' ? (
                          <FormatListBulletedRoundedIcon />
                        ) : (
                          <WindowOutlinedIcon />
                        )}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                ) : (
                  <>
                    <Stack direction="row" className="margin-bottom-m">
                      <Button
                        className="margin-right-base small btn-icon"
                        onClick={isNotDesktop ? toggleActionSheet(true) : handleClick}
                      >
                        <MoreVertRoundedIcon className="text-primary" />
                      </Button>

                      <ToggleButtonGroup
                        value={pieView}
                        exclusive
                        onChange={handlePieViewChange}
                      >
                        <ToggleButton value="table" className="small btn-icon">
                          <FormatListBulletedRoundedIcon />
                        </ToggleButton>
                        <ToggleButton value="chart" className="small btn-icon">
                          <WindowOutlinedIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                  </>
                )}
              </Stack>

              {pieView === 'table' ? (
                // TABLE VIEW

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          onClick={() => requestSort('name')}
                          style={{ cursor: 'pointer' }}
                        >
                          <TableSortLabel
                            active={sortConfig.key === 'name'}
                            direction={
                              sortConfig.key === 'name'
                                ? sortConfig.direction
                                : 'asc'
                            }
                          >
                            Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          className="text-align-right"
                          onClick={() => requestSort('sku')}
                          style={{ cursor: 'pointer' }}
                        >
                          <TableSortLabel
                            active={sortConfig.key === 'sku'}
                            direction={
                              sortConfig.key === 'sku'
                                ? sortConfig.direction
                                : 'asc'
                            }
                          >
                            SKUs
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell>
                            <Link>{row.name}</Link>
                          </TableCell>
                          <TableCell className="text-align-right">
                            {row.sku}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                //CHART VIEW
                <div className="pieChart">
                  <PieChart
                    series={[
                      {
                        data: [
                          {
                            id: 0,
                            value: 75,
                            label: '[0% - 40%]',
                            color: 'var(--color-info-light)',
                          },
                          {
                            id: 1,
                            value: 25,
                            label: '[40% - 55%]',
                            color: 'var(--color-error-light)',
                          },
                          {
                            id: 2,
                            value: 25,
                            label: '[55% - 65%]',
                            color: 'var(--color-success)',
                          },
                          {
                            id: 3,
                            value: 25,
                            label: '[65% - 75%]',
                            color: 'var(--color-info)',
                          },
                          {
                            id: 4,
                            value: 25,
                            label: '[75% - 85%]',
                            color: 'var(--color-secondary)',
                          },
                          {
                            id: 5,
                            value: 25,
                            label: '[85% - 100%]',
                            color: 'var(--color-primary)',
                          },
                        ],
                      },
                    ]}
                    legend={{
                      direction: 'row',
                      position: {
                        vertical: 'bottom',
                        horizontal: 'middle',
                      },
                    }}
                    width={400}
                    height={250}
                    margin={{ left: 100, bottom: 100 }}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <h2 className="margin-top-l margin-bottom-m">Individual results</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{height:400}}>
            <CardContent>
              <h4>Press products &gt; 60%</h4>
              <CircularProgressWithLabel value={70} />
              {/* <PieChart
                          series={[

                            {
                              innerRadius: 110,
                              data: data1,
                              arcLabel: getArcLabel,
                            },
                          ]}
                          width={300}
                          height={300}
                        /> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{height:400}}>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <h4>Last Score</h4>
                <Stack direction="row" className="margin-bottom-m">
                    {isPhone ? (
                      <Button className='btn-icon' onClick={toggleActionSheet(true)}>
                        <MoreVertRoundedIcon className="text-primary" />
                      </Button>
                    ) : (
                      <>
                        <Button className="small btn-icon" onClick={isNotDesktop ? toggleActionSheet(true) : handleClick}>
                          <MoreVertRoundedIcon className="text-primary" />
                        </Button>
                      </>
                    )}
                </Stack>
              </Stack>

              <CircularProgressWithoutLabel value={70} />
              {/* <PieChart
                          series={[
                            {
                              innerRadius: 115,
                              outerRadius: 100,
                              data: data1,
                              arcLabel: getArcLabel,
                            },
                          ]}
                          width={350}
                          height={300}
                          legend={{ hidden: true }}
                        /> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Card sx={{height:400}}>
            <CardContent>
              <Stack direction="column" >
                <h4>Goal-setting for the coming week</h4>
                <p className="text-neutral-7">
                  An illustration of the weekly goal.
                </p>
                <CardMedia
                  component="img"
                  image="/images/Goal-setting.png"
                  title="Goal-setting Picture"
                  className="full-width  padding-y-m"
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <h2 className="margin-top-l">Materials</h2>
      <Simplified_SimpleList />
    </LayoutSideWrapper>
  );
}
