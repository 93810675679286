import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import PreviewComponent from '../app-components/PreviewComponent';



export default function Breadcrumbs_Sample() {
  const sampleBreadcrumbs = "<Breadcrumbs separator='>'>" +
                        "\n\t<Link>Home</link>" +
                        "\n\t<Link>Level 2</Link>" +
                        "\n\t<span>Active Page</span>" +
                        "\n</Breadcrumbs>";


  return (
    <div>
        <PreviewComponent title="" description="" code={sampleBreadcrumbs}>
            <Breadcrumbs separator="›">
                <Link>Home</Link>,
                <Link>Level 2</Link>,
                <span>Active Page</span>,
            </Breadcrumbs>
        </PreviewComponent>
    </div>
  );
}