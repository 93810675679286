import * as React from 'react';
import PreviewComponent from '../../app-components/PreviewComponent';
import { Box, Button} from '@mui/material';
import ActionSheet from '../../theme/custom-components/action-sheet/ActionSheet';

export default function ActionSheet_Sample() {

  //ACTION SHEETS

const [openActionSheet, setOpenActionSheet] = React.useState(false);

const toggleActionSheet = (newOpen) => () => {
  setOpenActionSheet(newOpen);
}

  const sampleCode_layoutLogin =

  "<ActionSheet" +
  "\n\topen={openActionSheet}" +
  "\n\tonClose={toggleActionSheet(false)}" + 
  "\n\tonCancelClick={toggleActionSheet(false)}" +
  "\n\tcancelText='Cancel'" +
  "\n>" +
  "\n\t<Button className='full-width'>Edit</Button>" +
  "\n\t<Button className='full-width error'>Delete</Button>" +
  "\n</ActionSheet>"
  
  
  return (
    <div>
      <PreviewComponent title="" description="" code={sampleCode_layoutLogin}>
      <Button className='margin-y-base' onClick={toggleActionSheet(true)}>Try here</Button>
      <h5>Properties:</h5>
      <ul>
        <li><strong>open:</strong> receives a boolean to control the ActionSheet's state;</li>
        <li><strong>onClose:</strong> defines the callback action to close the action sheet when clicking on the overlay;</li>
    </ul>
      </PreviewComponent>

      <ActionSheet open={openActionSheet} onClose={toggleActionSheet(false)} onCancelClick={toggleActionSheet(false)} cancelText='Cancel'>
            <Button className='full-width'>Edit</Button>
            <Button className='full-width error'>Delete</Button>
      </ActionSheet>
    </div>
  );
}