import LayoutBlank from "../theme/custom-components/layout-blank/LayoutBlank";

export default function LayoutBlankWrapper(props){

    return(

        <LayoutBlank>
            {props.children}
        </LayoutBlank>
        
    )
}