import * as React from 'react';
import Link from '@mui/material/Link';
import PreviewComponent from '../app-components/PreviewComponent';


export default function Link_Sample() {

  const sampleLinkDefault = "<Link>Link - Default</Link>";

  const sampleLinkUnderlined = "<Link className='underlined-hover'>Link - Underlined on Hover</link>";

  const sampleLinkDisabled = "<Link disabled>Link - Disabled</link>";
  

  return (
    <div>
      <PreviewComponent title="Default" description="" code={sampleLinkDefault}>
        <Link>Link - Default</Link>
      </PreviewComponent>

      <PreviewComponent title="Underlined on Hover" description="" code={sampleLinkUnderlined}>
        <Link className='underlined-hover'>Link - Underlined on Hover</Link>
      </PreviewComponent>
      
      
      <PreviewComponent title="Disabled" description="" code={sampleLinkDisabled}>
        <Link disabled>Link - Disabled</Link>
      </PreviewComponent>
   </div>
  );
}