import * as React from 'react';
import LayoutSideWrapper from '../app-components/LayoutSideWrapper';
import { Box, Button, Card, CardContent, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, Stack, Step, StepLabel, Stepper, TextField, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';


export default function FormSurvey() {

  const isPhone = useMediaQuery('(max-width:620px)');
  const isNotDesktop = useMediaQuery('(max-width:820px)');

  const steps = ['Start', 'Generic Info', 'Questions', 'Questions', 'Questions', 'Questions', 'Confirmation'];
  const stepContent = [

    <>
      <Card className='margin-bottom-m'>
        <CardContent>
          <h2>Outside PDV</h2>
          {isPhone ?(
            <>
            <div className='font-bold margin-top-m'> Day preparation</div>
            <Divider className='padding-top-base'/>
            <InputLabel className='padding-top-base'>Set goals for the day</InputLabel>
            <FormControl className='full-width margin-bottom-m'>
              <Select value={1}>
                <MenuItem value={1}>Fully complies</MenuItem>
                <MenuItem value={2}>Does not comply</MenuItem>
                <MenuItem value={3}>Requires improvement</MenuItem>
              </Select>
            </FormControl>
            <InputLabel>Performs time management</InputLabel>
            <FormControl className='full-width'>
              <Select value={1}>
                <MenuItem value={1}>Fully complies</MenuItem>
                <MenuItem value={2}>Does not comply</MenuItem>
                <MenuItem value={3}>Requires improvement</MenuItem>
              </Select>
            </FormControl>
            <div className='font-bold margin-top-l'> Day preparation</div>
            <Divider className='padding-top-base'/>
            <InputLabel className='padding-top-base'>Know the actions of the defined promotional plan</InputLabel>
            <FormControl className='full-width margin-bottom-m'>
              <Select value={1}>
                <MenuItem value={1}>Fully complies</MenuItem>
                <MenuItem value={2}>Does not comply</MenuItem>
                <MenuItem value={3}>Requires improvement</MenuItem>
              </Select>
            </FormControl>
            <InputLabel>Defines visit objectives, sales strategy and prepares the materials needed to implement actions</InputLabel>
            <FormControl className='full-width margin-bottom-m'>
              <Select value={1}>
                <MenuItem value={1}>Fully complies</MenuItem>
                <MenuItem value={2}>Does not comply</MenuItem>
                <MenuItem value={3}>Requires improvement</MenuItem>
              </Select>
            </FormControl>
            </>
          ):(
            <>
              <Stack direction="row" className='margin-top-xl'>
                <Grid item sm={4} md={4}>
                  <span className='font-bold'> Day preparation</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <Stack direction="row" justifyContent="space-around">
                    <div className='font-bold'> Fully complies</div>
                    <div className='font-bold'> Does not comply</div>
                    <div className='font-bold'> Requires improvement</div>
                  </Stack>
                </Grid>
              </Stack>
              <Divider className='padding-top-base'/>
              <Stack direction="row" className='padding-top-base' alignItems="center">
                <Grid item sm={4} md={4} >
                  <span> Set goals for today</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <RadioGroup row style={{ justifyContent: 'space-around' }}>
                    <FormControlLabel value="Fully complies" control={<Radio />}  />
                    <FormControlLabel value="Does not comply" control={<Radio />} />
                    <FormControlLabel value="Requires improvement" control={<Radio />} />
                  </RadioGroup>
                </Grid>
              </Stack>
              <Stack direction="row" className='margin-top-m' alignItems="center">
                <Grid item sm={4} md={4}>
                  <span> Performs time management</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <RadioGroup row style={{ justifyContent: 'space-around' }}>
                    <FormControlLabel value="Fully complies" control={<Radio />}  />
                    <FormControlLabel value="Does not comply" control={<Radio />} />
                    <FormControlLabel value="Requires improvement" control={<Radio />} />
                  </RadioGroup>
                </Grid>
              </Stack>
              <Stack direction="row" className='margin-top-xl'>
                <Grid item sm={4} md={4}>
                  <span className='font-bold'> Day preparation</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <Stack direction="row" justifyContent="space-around">
                    <div className='font-bold'> Fully complies</div>
                    <div className='font-bold'> Does not comply</div>
                    <div className='font-bold'> Requires improvement</div>
                  </Stack>
                </Grid>
              </Stack>
              <Divider className='padding-top-base'/>
              <Stack direction="row" className='padding-top-base' alignItems="center">
                <Grid item sm={4} md={4}>
                  <span> Know the actions of the defined promotional plan</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <RadioGroup row style={{ justifyContent: 'space-around' }}>
                    <FormControlLabel value="Fully complies" control={<Radio />}  />
                    <FormControlLabel value="Does not comply" control={<Radio />} />
                    <FormControlLabel value="Requires improvement" control={<Radio />} />
                  </RadioGroup>
                </Grid>
              </Stack>
              <Stack direction="row" className='margin-top-m' alignItems="center">
                <Grid item sm={4} md={4}>
                  <span>Defines visit objectives, sales strategy and prepares the materials needed to implement actions</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <RadioGroup row style={{ justifyContent: 'space-around' }}>
                    <FormControlLabel value="Fully complies" control={<Radio />}  />
                    <FormControlLabel value="Does not comply" control={<Radio />} />
                    <FormControlLabel value="Requires improvement" control={<Radio />} />
                  </RadioGroup>
                </Grid>
              </Stack>
            </>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <h2>Inside PDV</h2>
          {isPhone ?(
            <>
            <div className='font-bold margin-top-m'> Greet the customer</div>
            <Divider className='padding-top-base'/>
            <InputLabel className='padding-top-base'>Greets staff and assesses openness to conversation</InputLabel>
            <FormControl className='full-width margin-bottom-m'>
              <Select value={1}>
                <MenuItem value={1}>Fully complies</MenuItem>
                <MenuItem value={2}>Does not comply</MenuItem>
                <MenuItem value={3}>Requires improvement</MenuItem>
              </Select>
            </FormControl>
            <InputLabel>Recognizes the thematic moment of the store (brochures and spaces / fairs)</InputLabel>
            <FormControl className='full-width'>
              <Select value={1}>
                <MenuItem value={1}>Fully complies</MenuItem>
                <MenuItem value={2}>Does not comply</MenuItem>
                <MenuItem value={3}>Requires improvement</MenuItem>
              </Select>
            </FormControl>
            <div className='font-bold margin-top-l'> POS verification / execution</div>
            <Divider className='padding-top-base'/>
            <InputLabel className='padding-top-base'>Draw conclusions from priorities by talking to the retailer</InputLabel>
            <FormControl className='full-width margin-bottom-m'>
              <Select value={1}>
                <MenuItem value={1}>Fully complies</MenuItem>
                <MenuItem value={2}>Does not comply</MenuItem>
                <MenuItem value={3}>Requires improvement</MenuItem>
              </Select>
            </FormControl>
            </>
          ):(
            <>
              <Stack direction="row" className='margin-top-xl'>
                <Grid item sm={4} md={4}>
                  <span className='font-bold'> Greet the customer</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <Stack direction="row" justifyContent="space-around">
                    <div className='font-bold'> Fully complies</div>
                    <div className='font-bold'> Does not comply</div>
                    <div className='font-bold'> Requires improvement</div>
                  </Stack>
                </Grid>
              </Stack>
              <Divider className='padding-top-base'/>
              <Stack direction="row" className='padding-top-base' alignItems="center">
                <Grid item sm={4} md={4}>
                  <span> Greets staff and assesses openness to conversation</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <RadioGroup row style={{ justifyContent: 'space-around' }}>
                    <FormControlLabel value="Fully complies" control={<Radio />}  />
                    <FormControlLabel value="Does not comply" control={<Radio />} />
                    <FormControlLabel value="Requires improvement" control={<Radio />} />
                  </RadioGroup>
                </Grid>
              </Stack>
              <Stack direction="row" className='margin-top-m' alignItems="center">
                <Grid item sm={4} md={4}>
                  <span>Recognizes the thematic moment of the store (brochures and spaces / fairs)</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <RadioGroup row style={{ justifyContent: 'space-around' }}>
                    <FormControlLabel value="Fully complies" control={<Radio />}  />
                    <FormControlLabel value="Does not comply" control={<Radio />} />
                    <FormControlLabel value="Requires improvement" control={<Radio />} />
                  </RadioGroup>
                </Grid>
              </Stack>
              <Stack direction="row" className='margin-top-xl'>
                <Grid item sm={4} md={4}>
                  <span className='font-bold'> POS verification / execution</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <Stack direction="row" justifyContent="space-around">
                    <div className='font-bold'> Fully complies</div>
                    <div className='font-bold'> Does not comply</div>
                    <div className='font-bold'> Requires improvement</div>
                  </Stack>
                </Grid>
              </Stack>
              <Divider className='padding-top-base'/>
              <Stack direction="row" className='padding-top-base' alignItems="center">
                <Grid item sm={4} md={4}>
                  <span> Draw conclusions from priorities by talking to the retailer</span>
                </Grid>
                <Grid item sm={8} md={8}>
                  <RadioGroup row style={{ justifyContent: 'space-around' }}>
                    <FormControlLabel value="Fully complies" control={<Radio />}  />
                    <FormControlLabel value="Does not comply" control={<Radio />} />
                    <FormControlLabel value="Requires improvement" control={<Radio />} />
                  </RadioGroup>
                </Grid>
              </Stack>
            </>
          )}
  
        </CardContent>
      </Card>
    </>,

    <>
    <Card className='margin-bottom-m'>
      <CardContent>
        <h2>Outside PDV</h2>
        <InputLabel htmlFor="id-day-preparation" className='font-bold text-neutral-8 margin-top-m'>Day preparation</InputLabel>
        <TextField
            id="id-day-preparation"
            className="full-width"
            multiline
            rows={4}
            placeholder="Description"
        />
        <InputLabel htmlFor="id-prepare-visits" className='font-bold text-neutral-8 margin-top-xxl'>Prepare visits</InputLabel>
        <TextField
            id="id-prepare-visits"
            className="full-width"
            multiline
            rows={4}
            placeholder="Description"
        />
      </CardContent>
    </Card>
    <Card className='margin-bottom-m'>
      <CardContent>
        <h2>Inside PDV</h2>
        <InputLabel htmlFor="id-day-preparation" className='font-bold text-neutral-8 margin-top-m'>Presents opportunities</InputLabel>
        <TextField
            id="id-day-preparation"
            className="full-width"
            multiline
            rows={4}
            placeholder="Description"
        />
        <InputLabel htmlFor="id-prepare-visits" className='font-bold text-neutral-8 margin-top-xxl'>Implement actions</InputLabel>
        <TextField
            id="id-prepare-visits"
            className="full-width"
            multiline
            rows={4}
            placeholder="Description"
        />
      </CardContent>
    </Card>
    </>,

    <>
    <Grid item sm={12} md={9}>
    <Card className='margin-bottom-m'>
      <CardContent>
      <h2>PDV</h2>
      <Stack className='margin-top-xl'>
                <span className='font-bold'>What is the buyer's main mission in this client?</span>
                <Divider className='padding-top-base'/>
                <RadioGroup 
                  row={isPhone ? false : true} column={isPhone ? true : false} 
                  style={{ justifyContent: 'space-between' }}
                  className='padding-top-s'
                >
                  <FormControlLabel value="Eat" control={<Radio />} label="Eat"  />
                  <FormControlLabel value="Drink" control={<Radio />} label="Drink" />
                  <FormControlLabel value="Entertainment" control={<Radio />} label="Entertainment" />
                  <FormControlLabel value="Resell" control={<Radio />} label="Resell" />
                </RadioGroup>
              </Stack>
              <Stack className='margin-top-xl'>
                <span className='font-bold'>What is the buyer's main mission in this client?</span>
                <Divider className='padding-top-base'/>
                <FormGroup 
                  row={isPhone ? false : true} column={isPhone ? true : false} 
                  style={{ justifyContent: 'space-between' }}
                  className='padding-top-s' 
                >
                  <FormControlLabel control={<Checkbox/>} label='Eat' />
                  <FormControlLabel control={<Checkbox/>} label='Drink' />
                  <FormControlLabel control={<Checkbox/>} label='Entertainment' />
                  <FormControlLabel control={<Checkbox/>} label='Resell' />
                </FormGroup>
              </Stack>
              <Stack className='margin-top-xl'>
                <span className='font-bold'>Serve dinners?</span>
                <Divider className='padding-top-base'/>
                <RadioGroup 
                  row={isPhone ? false : true} column={isPhone ? true : false}
                  className='padding-top-s' 
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No"/>
                </RadioGroup>
              </Stack>
      </CardContent>
    </Card>
    <Card className='margin-bottom-m'>
      <CardContent>
        <h2>Quiz</h2>
        <Stack className='margin-top-xl'>
          <span className='font-bold'>The space is &gt;= 66%?</span>
          <Divider className='padding-top-base'/>
          <RadioGroup 
            row={isPhone ? false : true} column={isPhone ? true : false}
            className='padding-top-s' 
          >  
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No"/>
            <FormControlLabel value="N/A" control={<Radio />} label="N/A"/>
          </RadioGroup>
        </Stack>
      </CardContent>
    </Card>
    </Grid>
    </>
];

  //MODAL

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const openModal = () => {setIsModalOpen(true)};
  const closeModal = () => {setIsModalOpen(false)};

//STEPPER STEPS
const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

//ACTION BUTTONS

    function actionButton() {
      return (
        <div>
          {isPhone ? (
            <Stack>
                <span className='font-size-base text-neutral-7 text-align-left'>Result</span>
                <h2 className='text-align-left'>56%</h2>
            </Stack>
            
          ) : (
            <>
             <Card className='padding-y-base'>
              <Stack>
                  <span className='font-size-base text-neutral-7 text-align-left'>Result</span>
                  <h2 className='text-align-left'>56%</h2>
              </Stack>
             </Card>
            </>
          )}
        </div>
      );
    }

  return (

     <LayoutSideWrapper 
        title="Form survey " 
        actions={actionButton()}
        breadcrumbs={[
        <Link to="/home">Home</Link>,
        <Link to="/products">Products</Link>,
        <span to="/form-survey">Form Survey</span>
      ]}>

        <Modal className='dialog-style MuiBackdrop-visible' open={isModalOpen} onClose={closeModal}>
          <Box className="text-align-center">
          <Card>
            <CardContent className='padding-m'>
            <Stack direction="row" justifyContent="right">
            <Link className='text-primary'onClick={closeModal}><CloseRoundedIcon/></Link>
          </Stack>
              <div className='padding-bottom-base' >
                <h2 className='margin-bottom-s'>Thank you</h2>
                <span className='font-size-base text-neutral-7'>Your survey has been successfully completed!</span>
              </div>
              <Stack direction="row" justifyContent="center">
                <h2 className='text-neutral-7'>Result&nbsp;</h2>
                <h2>&nbsp;56%</h2>
              </Stack>
              <Button className='primary margin-top-l' onClick={closeModal}>Ok</Button>
            </CardContent>
          </Card>
          </Box>
        </Modal>
        
    <Grid container className='margin-top-xs'>

        {isNotDesktop ? (
            <Grid item xs={12} sm={12} className='margin-bottom-base'>
              <Stepper activeStep={activeStep} orientation='horizontal' alternativeLabel>
                 {steps.map((label, index) => {
                   const stepProps = {};
                   const labelProps = {};
                   return (
                     <Step key={label} {...stepProps}>
                     {isPhone ? (
                        <>
                         <StepLabel {...labelProps}>{isPhone ? (activeStep === index ? label : "") : label}</StepLabel>

                        </>
                      ) : (
                        <>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </>
                      )}
                     </Step>
                   );
                 })}
               </Stepper>
            </Grid>
        ) : (
          <>
            <Grid item md={3}>
              <Stepper activeStep={activeStep} orientation='vertical'>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
          </>
        )}
 
          <Grid item xs={12} sm={12} md={9}>
              {activeStep === 2 || activeStep === 3 || activeStep === 4 || activeStep === 5 || activeStep === 6
                ? stepContent[2] : stepContent[activeStep]}
          </Grid>
        </Grid>
        <Card className='full-width margin-top-m'>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Button className='cancel'> Cancel</Button>
                <Stack direction="row">
                  <Button disabled={activeStep === 0} onClick={handlePrevious} startIcon={<ChevronLeftRoundedIcon/>}>Previous</Button>
                  <Button className='primary margin-left-base' endIcon={<ChevronRightRoundedIcon/>} onClick={() => {
                      if (activeStep === steps.length - 1) {
                        openModal();
                      } else {
                        handleNext();
                      }
                      }}
                  >
                      {activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
      </LayoutSideWrapper >
  );
}