import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PreviewComponent from '../../app-components/PreviewComponent';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export default function MultipleSelectPlaceholder() {

    const sampleCode_placeholder =
                                "<FormControl>" +
                                "\n\t<Select" +
                                "\n\t\tmultiple" +
                                "\n\t\tdisplayEmpty" +
                                "\n\t\tinput={<OutlinedInput/>}" +
                                "\n\t>" +
                                "\n\t\t<MenuItem disabled value=''>" +
                                "\n\t\t\t<em>Choose one or several names</em>" +
                                "\n\t\t</MenuItem>" +
                                "\n\t\t\t{names.map((name) => (" +
                                "\n\t\t<MenuItem" +
                                "\n\t\t\tkey={name}" +
                                "\n\t\t\tvalue={name}" +
                                "\n\t\t>" +
                                "\n\t\t\t{name}" +
                                "\n\t\t</MenuItem>" +
                                "\n\t\t\t))}" +
                                "\n\t</Select>" +
                                "\n</FormControl>"
        
  // const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
        <PreviewComponent title="Default with Placeholder" description="" code={sampleCode_placeholder}>
            <FormControl sx={{ width: 300}} >
              <Select
                multiple
                displayEmpty
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span>Placeholder</span>;
                  }
                  return selected.join(', ');
                }}
              >
                <MenuItem disabled value="">
                  <em>Choose one or several names</em>
                </MenuItem>
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </PreviewComponent>
    </div>
  );
}