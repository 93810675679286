import { Rating, Stack } from '@mui/material';
import * as React from 'react';
import PreviewComponent from '../app-components/PreviewComponent';

export default function Rating_Sample() {
  const sampleTypes = '<Rating />\n' +
  '<Rating readOnly />\n'+
  '<Rating disabled />';

  const sampleSizes = "<Rating className='small'/>" + 
  "\n<Rating />" +
  "\n<Rating className='large'/>";

  const samplePrecision = "<Rating precision={0.5} />";

  return (
    <div>
      <PreviewComponent title="Types" description="" code={sampleTypes}>
        <Stack spacing={2} direction="column">
          <Rating />
          <Rating readOnly />
          <Rating disabled />
        </Stack>
      </PreviewComponent>

      <PreviewComponent title="Sizes" description="" code={sampleSizes}>   
        <Stack spacing={2} direction="column">
          <Rating className='small'/>
          <Rating />
          <Rating className='large' />
        </Stack>
      </PreviewComponent>

      <PreviewComponent title="With Precision" description="" code={samplePrecision}> 
        <Stack spacing={2} direction="column">
          <Rating precision={0.5}/>
        </Stack>
      </PreviewComponent>    
  </div>
  );
}