import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { FormControl, Stack, TextField, MenuItem, CardContent, Card, InputLabel, Snackbar, Alert } from '@mui/material';
import * as React from 'react';
import LayoutSideWrapper from '../app-components/LayoutSideWrapper';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function SimpleForm() {

  const area = [
    { 
      value: 'select area',
    },
    { 
      value: 'Accesories',
    },
    {
      value: 'Headphones',
    },
    {
      value: 'Laptops',
    },
    {
      value: 'Smartphones',
    },
    {
      value: 'Printers'
    },
    {
      value: 'Storage',
    },
    {
      value: 'Smartwatches',
    }
  ];

  //SNACKBAR MESSAGE
  
  function InfoSnackbar({ open, onClose }) {

    React.useEffect(() => {
      let timer;
      if (open) {
        timer = setTimeout(() => {
          onClose();
        }, 5000);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [open, onClose]);

    return (
      <Snackbar open={open} onClose={onClose} 
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
      }}>
        <Alert className="info" onClose={onClose}>
          <InfoOutlinedIcon />
          Implement the required logic
        </Alert>
      </Snackbar>
    );
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
    return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

    return (
        <LayoutSideWrapper 
            title="Product Request" 
            halfSize={true} 
            breadcrumbs={[
            <Link key="home">Home</Link>,
            <Link key="products">Products</Link>,
            <span key="products-request">Product Request</span>
            ]}
        >
          <Card>
          <CardContent>
              <form>
              {openSnackbar && <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />}
                  <InputLabel htmlFor='requested-by' required>Product requested by</InputLabel>
                  <TextField id='requested-by' placeholder="Name" required />
                
               
                <InputLabel required htmlFor='Select area'>Area</InputLabel>
                  <TextField
                    id='selectArea'
                    select
                    placeholder="Select area"
                    defaultValue="select area"
                    required
                    sx={{
                      width: [
                        '100%', // Default width for desktop
                        '83%',  // Width for screens with a max-width of 768px (tablet)
                      ],  }}
                  >
                    {area.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField> 
                <div className='date-picker'>
                  <InputLabel htmlFor='Requested date'>Requested date</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{width: 150}}/>
                  </LocalizationProvider>
                </div>
                  <InputLabel htmlFor='email'>Email</InputLabel>
                  <TextField placeholder="Email"/>
               
               <div className='margin-bottom-m'>
                    <InputLabel htmlFor='Internal comments'>Internal comments</InputLabel>
                      <TextField className="input-multiline" id="outlined-multiline-static"
                      sx={{width:"100%"}}
                      multiline
                      rows={4}
                      placeholder="Insert comments"/>
                    <div className="text-neutral-7 text-align-right">Available characters: 500 of 500...</div>
                  </div>
               
                <FormControl >
                  <Stack direction="row" spacing={2}>
                    <Button type='reset' className='cancel' onClick={handleClickSnackbar}>Cancel</Button>
                    <Button type='submit' className='primary' onClick={handleClickSnackbar}>Request</Button>
                  </Stack>
                </FormControl>
            </form>
            </CardContent>
        </Card>
      </LayoutSideWrapper>
    );

}