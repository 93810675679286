import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { Button, Card, CardContent, Chip, Grid, ListItemIcon, ListItemText, MenuItem, Pagination, Popover, Snackbar, Stack, SwipeableDrawer, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import Alert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ActionSheet from '../../theme/custom-components/action-sheet/ActionSheet';

function createData(productName, price, stock, maxStock, equipment, created, status) {
  return {
    productName,
    price,
    stock,
    maxStock,
    equipment,
    created,
    status,
  };
}

const rows = [
  createData('A Black and white headphones', 150.99, 1831, 500, 'Headphones', "01 jan. 2023", "Draft"),
  createData('Black headphones', 99.90, 2986, 250, 'Headphones', "01 jan. 2023", "Rejected"),
  createData('Black headphones', 45.99, 3559, 300, 'Headphones', "01 feb. 2023", "Draft"),
  createData('Black and white headphones', 35.99, 3561, 450, 'Headphones', "01 jan. 2023", "Pending"),
  createData('Black and silver headphones', 140.99, 3646, 200, 'Headphones', "01 jan. 2023", "Pending"),
  createData('Black and silver headphones', 89.99, 3656, 200, 'Headphones', "01 mar. 2023", "Submitted"),
  createData('Silver smartphone', 150.99, 2567, 300, 'Smartphones', "01 jan. 2023", "Submitted"),
  createData('Silver smartphone', 99.90, 2212, 500, 'Smartphones', "01 jan. 2023", "Active"),
  createData('White smartphone', 45.99, 1559, 250, 'Smartphones', "01 jan. 2023", "Rejected"),
  createData('Black smartphone', 35.99, 961, 200, 'Smartphones', "01 feb. 2023", "Draft"),
  createData('Red smartphone', 140.99, 1234, 250, 'Smartphones', "01 mar. 2023", "Pending"),
  createData('Silver smartphone', 89.99, 4321, 300, 'Smartphones', "01 mar. 2023", "Submitted"),
  createData('SSD 2TB', 499.99, 4307, 300, 'Storage', "01 feb. 2023", "Active"),
  createData('SSD 20TB', 199.90, 4001, 400, 'Storage', "01 feb. 2023", "Draft"),
  createData('SSD 10TB', 145.99, 4004, 250, 'Storage', "01 feb. 2023", "Active"),
  createData('SSD 2TB', 135.99, 4321, 250, 'Storage', "01 feb. 2023", "Pending"),
  createData('SSD 5TB', 340.99, 1007, 250, 'Storage', "01 mar. 2023", "Pending"),
  createData('SSD 2TB', 189.99, 4307, 500, 'Storage', "01 mar. 2023", "Submitted"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
  
const headCells = [
  {
    id: 'productName',
    numeric: true,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Price',
  },
  {
    id: 'stock',
    numeric: true,
    disablePadding: false,
    label: 'Stock',
  },
  {
    id: 'maxStock',
    numeric: true,
    disablePadding: false,
    label: 'Max stock',
  },
  {
    id: 'equipment',
    disablePadding: false,
    label: 'Category',
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'Created on',
  },
  {
    id: 'status',
    disablePadding: false,
    label: 'Status',
    },
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
  
/* Create Table Header*/
function EnhancedTableHead(props) {
  const {order, orderBy, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="text-align-left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
  
export default function Simplified_SimpleList() {

  const isPhone = useMediaQuery('(max-width:620px)');
  const isNotDesktop = useMediaQuery('(max-width:820px)');
  
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');

  // Sorting
  const handleRequestSort = (event, property) => {
    const isAsc = (order === 'asc');
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);    
    setFilteredRows(stableSort(filteredRows, getComparator(isAsc ? 'desc' : 'asc', orderBy)));
  };

  // PAGINATION
  const [page, setPage] = React.useState(1);
  const rowsperpage = 5;
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);


  //atualizar tabela
    setFilteredRows(stableSort(filteredRowsCount, getComparator(order, orderBy)).slice(
      (newPage-1) * rowsperpage,
      (newPage-1) * rowsperpage + rowsperpage,
    )
    )
  };

  function resortList(thisAraay) {
    return stableSort(thisAraay, getComparator(order, orderBy)).slice(
      (page-1) * rowsperpage,
      (page-1) * rowsperpage + rowsperpage,
      
    )
  }

  //LOAD MORE CARDS BUTTON
  const cardsPerPage = 8;
  const [visibleCards, setVisibleCards] = React.useState(cardsPerPage);

  const handleLoadMore = () => {
    setVisibleCards((preVisibleCards) => preVisibleCards + cardsPerPage);
  };

  // SEARCH

  const [filteredRowsCount] = React.useState(
    stableSort(rows, getComparator(order, orderBy))
  );
  const [filteredRows, setFilteredRows] = React.useState(resortList(filteredRowsCount));

  const [filteredCardsCount] = React.useState(
    stableSort(rows, getComparator(order, orderBy))
  );
    
  //CHIP COLOR STATES
  const statusClassNames = [
    { status: 'Active', className: 'background-info-light'},
    { status: 'Pending', className: 'background-warning-light'},
    { status: 'Draft', className: 'background-secondary-light'},
    { status: 'Submitted', className: 'background-success-light'},
    { status: 'Rejected', className: 'background-error-light'},
  ];
  
  //CHANGE VIEW TO DISPLAY CARDS INSTEAD OF A TABLE
  const [view, setView] = React.useState('table');

  const handleViewChange = () => {
    setView(view === 'table' ? 'card' : 'table');
  };;

    //VIEW WHEN THE RESOLUTION IS BELOW 600PX (PHONE)
  
  React.useEffect(() => {
    // Check the screen width and set the view accordingly
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setView('card');
      } else {
        setView('table');
      }
    };

    // Initial check when the component mounts
    handleResize();

    // Add a listener for window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // CARD POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //ACTION SHEETS
    //FAVORITES / SEE DETAILS
  const [openActionSheet, setopenActionSheet] = React.useState(false);
  const toggleActionSheet = (newOpen) => () => {
    setopenActionSheet(newOpen);
  };

  //SNACKBAR MESSAGE
  
  function InfoSnackbar({ open, onClose }) {

    React.useEffect(() => {
      let timer;
      if (open) {
        timer = setTimeout(() => {
          onClose();
        }, 5000);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [open, onClose]);

    return (
      <Snackbar open={open} onClose={onClose} 
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
      }}>
        <Alert className="info" onClose={onClose}>
          <InfoOutlinedIcon />
          Implement the required logic
        </Alert>
      </Snackbar>
    );
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
    return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
      
  <Grid className='margin-top-m'>
      {openSnackbar && <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />}

            <ActionSheet
              open={openActionSheet}
              onClose={toggleActionSheet(false)}
              onCancelClick={toggleActionSheet(false)}
              cancelText='Cancel'
            >
              <Button className='full-width' onClick={handleClickSnackbar}>Favorites</Button>
              <Button className='full-width'onClick={handleClickSnackbar}>See Details</Button>
            </ActionSheet>
            
            <Stack direction="row" justifyContent="space-between"  className='margin-bottom-m'>
              <Stack direction="row" alignItems="center" > {filteredRowsCount.length} results</Stack>

                {isPhone ? (
                  <Stack direction="row" >
                    <Button className='margin-right-base btn-icon'  onClick={toggleActionSheet(true)}> 
                      <MoreVertRoundedIcon className='text-primary'/> 
                    </Button>
                    <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={handleViewChange}
                    >
                      <ToggleButton className='btn-icon'>
                        {view === 'table' ? (
                          <FormatListBulletedRoundedIcon />
                        ) : (
                          <WindowOutlinedIcon />
                        )}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                ) : (
                <>
                <Stack direction="row">
                  <Button className='margin-right-base small btn-icon'  onClick={isNotDesktop ? toggleActionSheet(true) : handleClick}> 
                    <MoreVertRoundedIcon 
                      className='text-primary'/> 
                  </Button>
                  <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={handleViewChange}
                  >
                    <ToggleButton value="table" className="small btn-icon">
                      <FormatListBulletedRoundedIcon />
                    </ToggleButton>
                    <ToggleButton value="card" className="small btn-icon">
                      <WindowOutlinedIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
                </>
                )}
            </Stack>
              
              <Popover className="popover padding-none" open={open} anchorEl={anchorEl} onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}>
                <MenuItem
                onClick={handleClickSnackbar}
                >
                <ListItemIcon>
                  <StarBorderRoundedIcon 
                  />
                </ListItemIcon>
                <ListItemText>Favorites</ListItemText>
              </MenuItem>
              <MenuItem
              onClick={handleClickSnackbar}
              >
                <ListItemIcon>
                  <VisibilityOutlinedIcon 
                  onClick={handleClickSnackbar}
                  />
                </ListItemIcon>
                <ListItemText>See Details</ListItemText>
              </MenuItem>
              </Popover>

            {view === 'table' ? (        
          <Card>
            <CardContent>
              <TableContainer>
                <Table>

                  {/*table header*/}

                  <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredRows.length}
                  />

                  {/*table rows*/}
                  <TableBody>           
                    {filteredRows.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            className='text-primary text-align-left'            
                          >
                            <Link onClick={handleClickSnackbar}> {row.productName}</Link>
                          </TableCell>
                          <TableCell className="text-align-left">${row.price}</TableCell>
                          <TableCell className="text-align-left">{row.stock}</TableCell>
                          <TableCell className="text-align-left">{row.maxStock}</TableCell>
                          <TableCell className="text-align-left">{row.equipment}</TableCell>   
                          <TableCell className="text-align-left">{row.created}</TableCell>
                          <TableCell className="text-align-left"><Chip label={row.status} className={statusClassNames.find(item => item.status === row.status)?.className || ''}
                          />
                          </TableCell>          
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                  {/* PAGE COUNTING */}

              {isPhone ? (
              <Stack direction="column" className="margin-top-m">
                <div className="text-align-center text-neutral-7">
                  {rowsperpage * (page - 1) + 1 > filteredRowsCount.length
                    ? filteredRowsCount.length : rowsperpage * (page - 1) + 1}{' '}
                  to{' '}{Math.min(rowsperpage * page, filteredRowsCount.length)}{' '}
                  of {filteredRowsCount.length}
                </div>
                <Pagination
                  className="margin-bottom-m margin-top-base"
                  rowsperpage={rowsperpage}
                  page={page}
                  count={Math.ceil(filteredRowsCount.length / rowsperpage)}
                  onChange={handleChangePage}
                />
              </Stack>
              ) : (
                  <>
              <Stack direction="row" alignItems="center" justifyContent="space-between" className="text-neutral-7">
                  {rowsperpage * (page - 1) + 1 > filteredRowsCount.length
                    ? filteredRowsCount.length : rowsperpage * (page - 1) + 1}{' '}
                  to{' '}{Math.min(rowsperpage * page, filteredRowsCount.length)}{' '}
                  of {filteredRowsCount.length}
                <Pagination
                  rowsperpage={rowsperpage}
                  page={page}
                  count={Math.ceil(filteredRowsCount.length / rowsperpage)}
                  onChange={handleChangePage}
                />
              </Stack>
                    </>
              )}
              </TableContainer>
            </CardContent>
          </Card>
        
            ) : (

          /*CARD VIEW*/
            <>
           <Grid container spacing={3} >
            {filteredCardsCount.slice(0, visibleCards).map((row, index) => (
              <Grid key={index} item xs={12} sm={4} md={4}  >
              <Card key={row.id} >
                <CardContent>
                 <div className='padding-bottom-base'> 
                 <div className='text-neutral-8'>Name</div>
                 <Link onClick={handleClickSnackbar}> {row.productName}</Link>
              </div>

                  {headCells.map((cell) => {
                    if (cell.id !== 'status' && cell.id !== 'id' && cell.id!== 'productName') {
                      return (
                        <div key={cell.id}>
                          <div className='text-neutral-8'>{cell.label}</div>
                          <div className='padding-bottom-base font-bold font-size-base'>
                           {cell.id === 'price' ? '$' + row[cell.id] : row[cell.id]}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                  <div className="text-align-left">
                    <Chip
                      label={row.status}
                      className={`small 
                      ${statusClassNames.find((item) => item.status === row.status)?.className || ''}`}
                    />
                  </div>
                </CardContent>
              </Card>
              </Grid>
            ))}
           </Grid>
            
          <Stack direction="row" justifyContent="center" className="margin-top-m">
            {visibleCards < filteredCardsCount.length && (
              <Button onClick={handleLoadMore}>
                Load more ({Math.min(visibleCards, filteredCardsCount.length)}{' '}
                of {filteredCardsCount.length})
              </Button>
            )}
          </Stack>
            </>
            )}

</Grid>
  )
}