import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PreviewComponent from '../app-components/PreviewComponent';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function BasicList() {

const sampleList         = "<List>" +
                        "\n\t<ListItem>" +
                        "\n\t\t<ListItemButton>" +
                        "\n\t\t\t<ListItemIcon>" +
                        "\n\t\t\t\t<InboxIcon />" +
                        "\n\t\t\t</ListItemIcon>" +
                        "\n\t\t\t<ListItemText primary='Inbox' />" +
                        "\n\t\t</ListItemButton>" +
                        "\n\t</ListItem>" +
                        "\n\t<ListItem>" +
                        "\n\t\t<ListItemButton selected>" +
                        "\n\t\t\t<ListItemIcon>" +
                        "\n\t\t\t\t<DraftsIcon />" +
                        "\n\t\t\t</ListItemIcon>" +
                        "\n\t\t\t<ListItemText primary='Drafts' />" +
                        "\n\t\t</ListItemButton>" +
                        "\n\t</ListItem>"+
                        "\n\t<ListItem>" +
                        "\n\t\t<ListItemButton>" +
                        "\n\t\t\t<ListItemText primary='Trash' />" +
                        "\n\t\t</ListItemButton>" +
                        "\n\t</ListItem>" +
                        "\n\t<ListItem>" +
                        "\n\t\t<ListItemButton component='a' href='#simple-list'>" +
                        "\n\t\t\t<ListItemText primary='Spam' />" +
                        "\n\t\t</ListItemButton>" +
                        "\n\t</ListItem>" +
                        "\n</List>";


  return (
    <div>
      <PreviewComponent title="" description="" code={sampleList}>
        <Box sx={{width:300, p:2}}>
            <List >
              <ListItem>
                <ListItemButton>
                  <ListItemText primary="Inbox"/> <ArrowForwardIosIcon/>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton selected>
                  <ListItemText primary="Drafts" /><ArrowForwardIosIcon/>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemText primary="Trash" /><ArrowForwardIosIcon/>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemText primary="Spam" /><ArrowForwardIosIcon/>
                </ListItemButton>
              </ListItem>
            </List>
        </Box>
      </PreviewComponent>
    </div>
  );
}