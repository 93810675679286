import * as React from 'react';
import LayoutSideWrapper from '../app-components/LayoutSideWrapper';
import { Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, Link, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Stack, Drawer, Box, Divider, useMediaQuery, Popover, ListItemIcon, ListItemText, TableSortLabel, Pagination } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SectionIndex from '../theme/custom-components/Section-index/SectionIndex';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import InfoSnackbar from './Commons/InfoSnackbar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import ConfirmationDelete from '../theme/custom-components/confirmation-delete/ConfirmationDelete';
import ActionSheet from '../theme/custom-components/action-sheet/ActionSheet';


export default function FormSection() {

    const isNotDesktop = useMediaQuery('(max-width:820px)');
    const isPhone = useMediaQuery('(max-width:620px)');
    const className = isNotDesktop ? 'not-desktop-sidebar' : 'width-500';

    const sections = [
        'Generic',
        'Equipment',
        'Documentation',
      ];

    //SMALL TABLE

function createData(name, price, sku, date, format, size) {
    return { name, price, sku, date, format, size};
  }
  
  const rows = React.useMemo(() =>[
    createData('Black and silver headphones', '$249.99', 2224, '01 Jan. 2023', "blackandsilverheadphones.jpg", 1231),
    createData('Macbook Pro Retina', '$150.99', 2422, '01 Jan. 2023', "macbookproretina.jpg", 1831),
    createData('iPhone 14 Pro Max 256GB', '$1499.99', 3756, '04 Jan. 2023', "iPhone14promax256GB.jpg", 2839),
    createData('iPad Pro 1TB', '$750', 2354, '01 Feb. 2023', "iPadpro1TB.jpg", 4070),
    createData('Macbook Pro', '$1199.99', 6701, '04 Mar. 2023', "macbookpro.jpg", 4401),
    createData('iPhone 14', '$999.99', 4907, '22 Apr. 2023', "iPhone14.jpg", 3703),
], []);

//SORT SMALL TABLE

const itemsPerPage = 3;

const [sortConfig, setSortConfig] = React.useState({ key: null, direction: 'ascending' });
const [currentPage] = React.useState(0);

const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
    }
    setSortConfig({ key, direction });
};

// Sorting logic (same as in your code)
const sortedData = React.useMemo(() => {
    let sortableData = [...rows];
    if (sortConfig.key) {
    sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
    });
    }
    return sortableData;
}, [rows, sortConfig]);

// Calculate start and end indices for the current page
const startIndex = currentPage * itemsPerPage;
const endIndex = startIndex + itemsPerPage;

// Slice the sorted data to display only the current page's items
const displayedData = sortedData.slice(startIndex, endIndex);

//Checkbox select
const [selectAll, setSelectAll] = React.useState(false);
const handleSelectAll = () => {
setSelectAll(!selectAll);
};

const handleRowSelect = (rowIndex) => {
}

    //BIG TABLE

    const advancedRows = React.useMemo(() =>[
        {names:"Patricia", birth: "Dec. 25, 1986", email: "patricia.wesley@example.com"},
        {names:"Edward", birth: "Oct. 9, 1980", email: "edward.williams@example.com"},
        {names:"Andrea", birth: "Dec. 14, 1986", email: "andrea.mccarthy@example.com"},
        {names:"Ann", birth: "Aug. 18, 1978", email: "ann.olivarria@example.com"},
        {names:"Bridget", birth: "Nov. 10, 1982", email: "bridget.hernandez@example.com"},
        {names:"Carla", birth: "Dec. 30, 1986", email: "carla.hansen@example.com"},
        {names:"Charlotte", birth: "Sep. 15, 1982", email: "charlotte.anderson@example.com"},
        {names:"Cheryl", birth: "Apr. 10, 1980", email: "cheryl.fleet@example.com"},
        {names:"Christina", birth: "Jul. 20, 1980", email: "christina.sharp@example.com"},
        {names:"Christopher", birth: "Oct. 5, 1991", email: "christopher.shaw@example.com"}
    ], []);

//SORT BIG TABLE

const advancedItemsPerPage = 8;

const [sortAdvancedConfig, setSortAdvancedConfig] = React.useState({ key: null, direction: 'ascending' });
const [currentAdvancedPage, setCurrentAdvancedPage] = React.useState(1);

// Handle page change
const handleChangePage = (event, newPage) => {
    setCurrentAdvancedPage(newPage);
  };

  const requestAdvancedSort = (key) => {
    let direction = 'ascending';
    if (sortAdvancedConfig.key === key && sortAdvancedConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortAdvancedConfig({ key, direction });
  };

// Define a function to parse the birthdate string into a Date object
function parseBirthdate(birthdate) {
    // Convert the month abbreviation to a numeric month
    const monthAbbreviations = {
      "Jan.": 0, "Feb.": 1, "Mar.": 2, "Apr.": 3, "May": 4, "Jun.": 5,
      "Jul.": 6, "Aug.": 7, "Sep.": 8, "Oct.": 9, "Nov.": 10, "Dec.": 11
    };
  
    const [monthAbbreviation, day, year] = birthdate.split(' ');
    const month = monthAbbreviations[monthAbbreviation];
    return new Date(year, month, day.replace(',', ''));
  }

// Sorting logic
const sortedAdvancedData = React.useMemo(() => {
    let sortableData = [...advancedRows];
    if (sortAdvancedConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortAdvancedConfig.key] < b[sortAdvancedConfig.key]) return sortAdvancedConfig.direction === 'ascending' ? -1 : 1;
        if (a[sortAdvancedConfig.key] > b[sortAdvancedConfig.key]) return sortAdvancedConfig.direction === 'ascending' ? 1 : -1;
  
        // If the values in the specified key are the same, sort by birthdate
        const dateA = parseBirthdate(a.birth);
        const dateB = parseBirthdate(b.birth);
  
        if (dateA < dateB) return sortAdvancedConfig.direction === 'ascending' ? -1 : 1;
        if (dateA > dateB) return sortAdvancedConfig.direction === 'ascending' ? 1 : -1;
  
        return 0;
      });
    }
    return sortableData;
  }, [advancedRows, sortAdvancedConfig]);

// Calculate start and end indices for the current page
const startAdvancedIndex = (currentAdvancedPage -1) * advancedItemsPerPage;
const endAdvancedIndex = startAdvancedIndex + advancedItemsPerPage;

// Slice the sorted data to display only the current page's items
const displayedAdvancedData = sortedAdvancedData.slice(startAdvancedIndex, endAdvancedIndex);


//SNACKBAR MESSAGE

 const [openSnackbar, setOpenSnackbar] = React.useState(false);

 const handleClickSnackbar = () => {
   setOpenSnackbar(true);
   return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
 };

 const handleCloseSnackbar = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }

   setOpenSnackbar(false);
 };

  // ADD EQUIPMENT SIDEBAR
  const [equipmentState, setEquipmentState] = React.useState({
    right: false,
  });

  const toggleAddEquipmentDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setEquipmentState({ ...equipmentState, [anchor]: open });
  };

  const drawerAddEquipmentContent = (anchor) => (
    <Card className={className}>
        <CardContent>
        <h2 className='margin-bottom-m'>Add equipment</h2>
        <Grid container>
            <Grid item xs={12} sm={8} md={8}>
            <InputLabel htmlFor="id-name" >Equipment</InputLabel>
                <TextField className='full-width padding-right-s' id="id-name" placeholder="Name" />
            </Grid>
            <Grid  item xs={12} sm={4} md={4}>
            <InputLabel required>Area</InputLabel>
                <FormControl className='full-width padding-left-s'>
                    <Select defaultValue={1}>
                        <MenuItem value={1}>Accesories</MenuItem>
                        <MenuItem value={2}>Headphones</MenuItem>
                        <MenuItem value={3}>Laptops</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container>
            <Grid  item xs={12} sm={8} md={4}>
            <InputLabel htmlFor="id-price" >Price</InputLabel>
                <TextField id="id-price" />
            </Grid>
        </Grid>
            <div className="margin-bottom-m">
                <InputLabel htmlFor="id-comments">Internal comments</InputLabel>
                <TextField
                    id="id-comments"
                    className="full-width"
                    multiline
                    rows={4}
                    placeholder="Write your comment here..."
                />
                <div className="text-neutral-6 text-align-right">Available characters: 500 of 500...</div>
            </div>
            <div>
                <Button className='margin-right-base cancel'onClick={toggleAddEquipmentDrawer("right", false)}>Cancel</Button>
                <Button className='primary'>Add</Button>
            </div>
        </CardContent>
    </Card>
  )

// ADD DOCUMENTATION SIDEBAR
const [documentationState, setDocumentationState] = React.useState({
    right: false,
    });

    const toggleAddDocumentationDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
    }

    setDocumentationState({ ...documentationState, [anchor]: open });
    };

    const drawerAddDocumentationContent = (anchor) => (
    <Card className={className}>
        <CardContent>
        <h2 className='margin-bottom-m'>Add documentation</h2>
        <InputLabel htmlFor="id-upload" >Upload</InputLabel>
        <TextField id="id-upload" type="file"><CloudUploadIcon/></TextField>
        <Grid container>
            <Grid  item md={4}>
            <InputLabel htmlFor="id-price" >Price</InputLabel>
                <TextField id="id-price" />
            </Grid>
        </Grid>
            <div className="margin-bottom-m">
                <InputLabel htmlFor="id-comments">Internal comments</InputLabel>
                <TextField
                    id="id-comments"
                    className="full-width"
                    multiline
                    rows={4}
                    placeholder="Write your comment here..."
                />
                <div className="text-neutral-6 text-align-right">Available characters: 500 of 500...</div>
            </div>
            <div>
                <Button className='margin-right-base cancel'onClick={toggleAddDocumentationDrawer("right", false)}>Cancel</Button>
                <Button className='primary'>Add</Button>
            </div>
        </CardContent>
    </Card>
    )

  //COMMENTS SIDEBAR
        //Para Desktop
  const [showCommentCard, setShowCommentCard] = React.useState(true);

  const toggleCommentCard = () => {
    setShowCommentCard(!showCommentCard);
  };
  const mdValue = showCommentCard ? 6 : 9;

         //Para phone e tablet
  const [commentState, setCommentState] = React.useState({
    right: false,
  });

  const toggleCommentDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setCommentState({ ...commentState, [anchor]: open });
  };

  const drawerCommentContent = (anchor) => (
        <Card className='not-desktop-sidebar'>
            <CardContent>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                <h2 className="margin-bottom-s">Comments</h2>

            {isNotDesktop ? (
                <>
                <Link onClick={toggleCommentDrawer("right", false)} >
                    <CloseRoundedIcon className='text-primary'/>
                </Link>
                </>
            ):(
                <>
                <Button className='small btn-icon' onClick={toggleCommentCard}><CloseRoundedIcon/></Button>
                </>

            )}  
                </Stack>
                <div className="margin-bottom-m">
                    <InputLabel htmlFor="id-comments">Internal comments</InputLabel>
                    <TextField
                        id="id-comments"
                        className="full-width"
                        multiline
                        rows={4}
                        placeholder="Write your comment here..."
                    />
                    <div className="text-neutral-7 text-align-right">Available characters: 500 of 500...</div>
                </div>
                <Button className="margin-bottom-s" onClick={handleClickSnackbar}> Add Comment</Button>

                <Divider className="margin-y-m"/>

                <div className="font-bold ">Last tuesday, the data was updated. Please read over.</div>
                <Stack direction="row" alignItems="center" className="margin-top-s">
                    <PersonOutlineRoundedIcon/>
                    <span className="margin-left-s">John Smith</span>
                </Stack>
                <Stack direction="row" alignItems="center" className="text-neutral-7 margin-top-s">
                    <ScheduleRoundedIcon/>
                    <span className="margin-left-s ">May 10, 2022 at 10:15</span>
                </Stack>

                <Divider className="margin-y-m"/>

                <div className="font-bold ">Last tuesday, the data was updated. Please read over.</div>
                <Stack direction="row" alignItems="center" className="margin-top-s">
                    <PersonOutlineRoundedIcon/>
                    <span className="margin-left-s">John Smith</span>
                </Stack>
                <Stack direction="row" alignItems="center" className="text-neutral-7 margin-top-s">
                    <ScheduleRoundedIcon/>
                    <span className="margin-left-s ">May 10, 2022 at 10:15</span>
                </Stack>

                <Divider className="margin-y-m"/>

                <div className="font-bold ">Last tuesday, the data was updated. Please read over.</div>
                <Stack direction="row" alignItems="center" className="margin-top-s">
                    <PersonOutlineRoundedIcon/>
                    <span className="margin-left-s">John Smith</span>
                </Stack>
                <Stack direction="row" alignItems="center" className="text-neutral-7 margin-top-s">
                    <ScheduleRoundedIcon/>
                    <span className="margin-left-s ">May 10, 2022 at 10:15</span>
                </Stack>
            </CardContent>
        </Card>
  );

//ADVANCED CUSTOMER SEARCH SIDEBAR

const [advancedState, setAdvancedState] = React.useState({
    right: false,
  });

  const toggleAdvancedDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setAdvancedState({ ...advancedState, [anchor]: open });
  };

  const drawerAdvancedContent = (anchor) => (
    <Box sx={{ width: isNotDesktop ? '85vw' : 800}} >

        <Stack className='padding-x-m padding-top-l padding-top-s' direction="row" alignItems="center" justifyContent="space-between">
        <h2 className="margin-bottom-s">Advanced customer search</h2>
        <Link onClick={toggleAdvancedDrawer("right", false)} >
            <CloseRoundedIcon className='text-primary'/>
        </Link>
        </Stack>

        <Grid container className='padding-x-m padding-top-s' columnSpacing={3}>
            <Grid item xs={12} sm={3} md={3}>
            <InputLabel htmlFor="id-name" required >Client name</InputLabel>
                <TextField id="id-name" placeholder="Name" className='full-width' />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
            <InputLabel>Type</InputLabel>
                <FormControl className= {isPhone ? 'full-width margin-bottom-m' : 'full-width'}>
                    <Select defaultValue={0}>
                        <MenuItem value={0}>Select</MenuItem>
                        <MenuItem value={1}>Accounting</MenuItem>
                        <MenuItem value={2}>Brand Management</MenuItem>
                        <MenuItem value={3}>Controlling</MenuItem>
                        <MenuItem value={4}>Credit Control</MenuItem>
                        <MenuItem value={5}>CRM</MenuItem>
                        <MenuItem value={6}>Finance</MenuItem>
                        <MenuItem value={7}>Human Resources</MenuItem>
                        <MenuItem value={8}>Logistics</MenuItem>
                        <MenuItem value={9}>Management</MenuItem>
                        <MenuItem value={10}>Marketing</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
                <InputLabel>Equipment</InputLabel>
                <FormControl className= {isPhone ? 'full-width margin-bottom-m' : 'full-width'}>
                    <Select defaultValue={0}>
                        <MenuItem value={0}>Select</MenuItem>
                        <MenuItem value={1}>Accesories</MenuItem>
                        <MenuItem value={2}>Headphones</MenuItem>
                        <MenuItem value={3}>Laptops</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
            <InputLabel>Status</InputLabel>
                <FormControl className= {isPhone ? 'full-width margin-bottom-m' : 'full-width'}>
                    <Select defaultValue={0}>
                        <MenuItem value={0}>Select</MenuItem>
                        <MenuItem value={1}>Accounting</MenuItem>
                        <MenuItem value={2}>Brand Management</MenuItem>
                        <MenuItem value={3}>Controlling</MenuItem>
                        <MenuItem value={4}>Credit Control</MenuItem>
                        <MenuItem value={5}>CRM</MenuItem>
                        <MenuItem value={6}>Finance</MenuItem>
                        <MenuItem value={7}>Human Resources</MenuItem>
                        <MenuItem value={8}>Logistics</MenuItem>
                        <MenuItem value={9}>Management</MenuItem>
                        <MenuItem value={10}>Marketing</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>

        <Grid container className='padding-x-m margin-bottom-m' columnSpacing={3} alignItems="flex-end">
            <Grid item xs={12} sm={3} md={3}>
            <InputLabel>District</InputLabel>
                <FormControl className= {isPhone ? 'full-width margin-bottom-m' : 'full-width'}>
                    <Select defaultValue={0}>
                        <MenuItem value={0}>Select</MenuItem>
                        <MenuItem value={1}>Accounting</MenuItem>
                        <MenuItem value={2}>Brand Management</MenuItem>
                        <MenuItem value={3}>Controlling</MenuItem>
                        <MenuItem value={4}>Credit Control</MenuItem>
                        <MenuItem value={5}>CRM</MenuItem>
                        <MenuItem value={6}>Finance</MenuItem>
                        <MenuItem value={7}>Human Resources</MenuItem>
                        <MenuItem value={8}>Logistics</MenuItem>
                        <MenuItem value={9}>Management</MenuItem>
                        <MenuItem value={10}>Marketing</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
            <InputLabel>Town Hall</InputLabel>
                <FormControl className= {isPhone ? 'full-width margin-bottom-m' : 'full-width'}>
                    <Select defaultValue={0}>
                        <MenuItem value={0}>Select</MenuItem>
                        <MenuItem value={1}>Accounting</MenuItem>
                        <MenuItem value={2}>Brand Management</MenuItem>
                        <MenuItem value={3}>Controlling</MenuItem>
                        <MenuItem value={4}>Credit Control</MenuItem>
                        <MenuItem value={5}>CRM</MenuItem>
                        <MenuItem value={6}>Finance</MenuItem>
                        <MenuItem value={7}>Human Resources</MenuItem>
                        <MenuItem value={8}>Logistics</MenuItem>
                        <MenuItem value={9}>Management</MenuItem>
                        <MenuItem value={10}>Marketing</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Stack direction="row">
                    <Button className='primary'onClick={handleClickSnackbar}>Filter</Button>
                    <Button className='margin-left-base cancel'onClick={handleClickSnackbar}>Clear all filters</Button>
                </Stack>
            </Grid>
        </Grid>

        <TableContainer className='padding-x-m'>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell
                    onClick={() => requestAdvancedSort('names')}
                    style={{ cursor: 'pointer' }}
                    >
                    <TableSortLabel
                        active={sortAdvancedConfig.key === 'names'}
                        direction={sortAdvancedConfig.key === 'names' ? sortAdvancedConfig.direction : 'asc'}
                    >
                        Name
                    </TableSortLabel>
                    </TableCell>
                    <TableCell
                        onClick={() => requestAdvancedSort('birth')}
                        style={{ cursor: 'pointer' }}
                    >
                    <TableSortLabel
                        active={sortAdvancedConfig.key === 'birth'}
                        direction={sortAdvancedConfig.key === 'birth' ? sortAdvancedConfig.direction : 'asc'}
                    >
                        Birth date
                    </TableSortLabel>
                    </TableCell>
                    <TableCell
                        onClick={() => requestAdvancedSort('email')}
                        style={{ cursor: 'pointer' }}
                    >
                    <TableSortLabel
                        active={sortAdvancedConfig.key === 'email'}
                        direction={sortAdvancedConfig.key === 'email' ? sortAdvancedConfig.direction : 'asc'}
                    >
                        Email
                    </TableSortLabel>
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {displayedAdvancedData.map((row) => (
                    <TableRow key={row.names}>
                    <TableCell><Link>Select</Link></TableCell>
                    <TableCell>{row.names} </TableCell>
                    <TableCell>{row.birth}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>

            {/* PAGE COUNTING */}

        {isPhone ? (
            <div className='margin-top-m padding-x-m'>
                <div className="text-align-center text-neutral-7">
                    {advancedItemsPerPage * (currentAdvancedPage -1) + 1 > advancedRows.length
                        ? advancedRows.length : advancedItemsPerPage * (currentAdvancedPage -1) + 1}{' '}
                    to{' '}{Math.min(advancedItemsPerPage * (currentAdvancedPage), advancedRows.length)}{' '}
                    of {advancedRows.length}
                </div>
                <Stack direction="row" justifyContent="center" >
                    <Pagination
                        className="margin-top-base"
                        rowsPerPage={advancedItemsPerPage}
                        page={currentAdvancedPage}
                        count={Math.ceil(sortedAdvancedData.length / advancedItemsPerPage)}
                        onChange={handleChangePage}
                    />
                </Stack>
            </div>
        ) : (
            <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" className="text-neutral-7 margin-top-m padding-x-m">
                {advancedItemsPerPage * (currentAdvancedPage -1) + 1 > advancedRows.length
                    ? advancedRows.length : advancedItemsPerPage * (currentAdvancedPage -1) + 1}{' '}
                to{' '}{Math.min(advancedItemsPerPage * (currentAdvancedPage), advancedRows.length)}{' '}
                of {advancedRows.length}
                <Pagination
                    rowsPerPage={advancedItemsPerPage}
                    page={currentAdvancedPage}
                    count={Math.ceil(advancedRows.length / advancedItemsPerPage)}
                    onChange={handleChangePage}
                />
            </Stack>
        </>
        )}
        <div className='margin-top-m padding-x-m'>
        <Button className='cancel'onClick={toggleAdvancedDrawer("right", false)}>Cancel</Button>
        </div>
    </Box>
  );

//Confirmation Delete

 const [isConfirmationDeleteOpen, setIsConfirmationDeleteOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenConfirmationDelete = (event) => {
    setAnchorEl(event.currentTarget);
    setIsConfirmationDeleteOpen(true);
  };

  const handleCloseConfirmationDelete = () => {
    setAnchorEl(null);
    setIsConfirmationDeleteOpen(false);
  };



       //ACTION BUTTONS
  
       function actionButton() {
      
        return (
        <div>
            {isNotDesktop ? (
                <>
                    <Button className='btn-icon' onClick={toggleCommentDrawer("right", true)}> <ChatBubbleOutlineOutlinedIcon/></Button>
                </>
            ):(
                <>
                {!showCommentCard && (
                    <Button className='btn-icon' onClick={toggleCommentCard}> <ChatBubbleOutlineOutlinedIcon /> </Button>
                )}
                </>
            )}
        </div>
        );
      }

    // TO SHOW THE SELECTED DROPDOWN WHEN isPhone is active

const [selectedValue, setSelectedValue] = React.useState(1);

const handleSelectChange = (event) => {
  const newValue = event.target.value;
  setSelectedValue(newValue);
}

 // CARD POPOVER
 const [anchorPopover, setAnchorPopover] = React.useState(null);

 const handlePopoverClick = (event) => {
    setAnchorPopover(event.currentTarget);
 };

 const handleClose = () => {
    setAnchorPopover(null);
 };

 const open = Boolean(anchorPopover);

//ACTION SHEETS
  const [openActionSheet, setopenActionSheet] = React.useState(false);
  const toggleActionSheet = (newOpen) => () => {
    setopenActionSheet(newOpen);
  };

//ACTION SHEETS DELETE
    const [openDeleteActionSheet, setopenDeleteActionSheet] = React.useState(false);
    const toggleDeleteActionSheet = (newOpen) => () => {
      setopenDeleteActionSheet(newOpen);
    };

    return(
        <LayoutSideWrapper 
        title="Form Section" 
        actions = {actionButton()}
        breadcrumbs={[
        <Link to="/home">Home</Link>,
        <Link to="/products">Products</Link>,
        <span to="/Form Section">Form Section</span>
        ]}>

    {openSnackbar && (<InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} /> )}

        <ActionSheet open={openActionSheet} onClose={toggleActionSheet(false)} onCancelClick={toggleActionSheet(false)}	cancelText='Cancel'>
            <Button className="full-width" onClick={handleClickSnackbar}>Edit </Button>
            <Button className="full-width error" onClick={handleClickSnackbar}>Delete </Button>
            <Button className="full-width" onClick={handleClickSnackbar}>Duplicate </Button>
            <Button className="full-width" onClick={handleClickSnackbar}>Export </Button>
        </ActionSheet>

        <ActionSheet open={openDeleteActionSheet} onClose={toggleDeleteActionSheet(false)} onCancelClick={toggleDeleteActionSheet(false)}cancelText='Cancel'>
            <Button className="full-width error" onClick={handleClickSnackbar}>Confirm </Button>
        </ActionSheet>

        <Drawer
            anchor={"right"}
            open={equipmentState["right"]}
            onClose={toggleAddEquipmentDrawer("right", false)}
        >
            {drawerAddEquipmentContent("right")}
        </Drawer>
        <Drawer
            anchor={"right"}
            open={documentationState["right"]}
            onClose={toggleAddDocumentationDrawer("right", false)}
        >
            {drawerAddDocumentationContent("right")}
        </Drawer>
        <Drawer
            anchor={"right"}
            open={commentState["right"]}
            onClose={toggleCommentDrawer("right", false)}
        >
            {drawerCommentContent("right")}
        </Drawer>
        <Drawer
            anchor={"right"}
            open={advancedState["right"]}
            onClose={toggleAdvancedDrawer("right", false)}
        >
            {drawerAdvancedContent("right")}
        </Drawer>

        <Popover
                    className="popover padding-none"
                    open={open}
                    anchorEl={anchorPopover}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={handleClickSnackbar}>
                      <ListItemIcon>
                        <EditOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>Edit</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClickSnackbar}>
                      <ListItemIcon>
                        <DeleteOutlineRoundedIcon />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClickSnackbar}>
                      <ListItemIcon>
                        <ContentCopyRoundedIcon />
                      </ListItemIcon>
                      <ListItemText>Duplicate</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClickSnackbar}>
                      <ListItemIcon>
                        <FileDownloadOutlinedIcon/>
                      </ListItemIcon>
                      <ListItemText>Export</ListItemText>
                    </MenuItem>
                  </Popover>
      
            {isPhone ? (
            <>
            <div>
                    <FormControl className=' margin-bottom-m full-width'>
                        <Select
                        className='full-width'
                        value={selectedValue}
                        onChange={handleSelectChange}
                        >
                        <MenuItem value={1}>Generic</MenuItem>
                        <MenuItem value={2}>Equipment</MenuItem>
                        <MenuItem value={3}>Documentation</MenuItem>
                        </Select>
                    </FormControl>

                    <section style={{ display: selectedValue === 1 ? 'block' : 'none' }}>
                        <Card className='margin-bottom-m'>
                            <CardContent>
                                <form>
                                    <h3 className='margin-bottom-base'>Generic</h3>
                                    <InputLabel htmlFor="id-name" required >Proposal requested by</InputLabel>
                                    <TextField id="id-name" placeholder="Name" />
                                    <InputLabel required>Area</InputLabel>
                                    <FormControl  className='margin-bottom-m'>
                                        <Select defaultValue={1}>
                                            <MenuItem value={1}>Accesories</MenuItem>
                                            <MenuItem value={2}>Headphones</MenuItem>
                                            <MenuItem value={3}>Laptops</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div className='date-picker'>
                                        <InputLabel htmlFor='Requested date'>Requested date</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker/>
                                        </LocalizationProvider>
                                    </div>
                                    <div  className='margin-bottom-m'>
                                        <InputLabel>Type of agreement</InputLabel>
                                        <RadioGroup row defaultValue="New">
                                            <FormControlLabel value="New" control={<Radio />} label="New" />
                                            <FormControlLabel value="Renovation" control={<Radio />} label="Renovation" />
                                        </RadioGroup>
                                    </div>
                                    <div className='margin-bottom-m'>
                                        <InputLabel>Mechanics</InputLabel>
                                        <FormControlLabel control={<Checkbox />} label='Auto' />
                                        <FormControlLabel  control={<Checkbox />} label='Manual' />
                                        <FormControlLabel  control={<Checkbox />} label='Semi-auto' />
                                    </div>
                                    <InputLabel>Auto renovation</InputLabel>
                                    <RadioGroup className='margin-bottom-m' row defaultValue="No">
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>

                                    <InputLabel required>Client</InputLabel>
                                    <Stack direction="row" className='margin-bottom-m' justifyContent="space-between">
                                    <FormControl className='full-width'>
                                        <Select defaultValue={1}>
                                            <MenuItem value={1}>Amos</MenuItem>
                                            <MenuItem value={2}>Andrea</MenuItem>
                                            <MenuItem value={3}>Ann</MenuItem>
                                            <MenuItem value={4}>Antonia</MenuItem>
                                            <MenuItem value={5}>Ashton</MenuItem>
                                        </Select>
                                    </FormControl>
                                        <Button className='margin-left-s btn-icon' onClick={toggleAdvancedDrawer("right", true)}><SearchOutlinedIcon/></Button>
                                    </Stack>  
                                </form>
                            </CardContent>
                        </Card>
                    </section>
                    <section style={{ display: selectedValue === 2 ? 'block' : 'none' }}>
                        <Card className='margin-bottom-m'>
                            <CardContent>
                                <Stack direction="row">
                                    <h3 className='full-width'>Equipment</h3>
                                    <Stack direction="row">
                                        <Button className='margin-right-base' onClick={toggleAddEquipmentDrawer("right", true)}>Add</Button>
                                        <Button className='btn-icon' onClick={toggleActionSheet(true)}><MoreVertRoundedIcon/></Button>

                                    </Stack>
                                </Stack>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell> <Checkbox checked={selectAll} onChange={handleSelectAll} /></TableCell>
                                                <TableCell  onClick={() => requestSort('name')} style={{ cursor: 'pointer' }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'name'}
                                                        direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                                                        >
                                                        Name
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell className='text-align-right'  onClick={() => requestSort('price')} style={{ cursor: 'pointer' }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'price'}
                                                        direction={sortConfig.key === 'price' ? sortConfig.direction : 'asc'}
                                                        >
                                                        Price
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {displayedData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell><Checkbox onChange={() => handleRowSelect(index)} /></TableCell>
                                                <TableCell> <Link>{row.name}</Link></TableCell>
                                            <TableCell className='text-align-right'>{row.price}</TableCell>
                                            <TableCell>
                                                <Button onClick={toggleDeleteActionSheet(true)}><DeleteOutlineRoundedIcon className='text-primary'/></Button>
                                            </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </section>
                    <section style={{ display: selectedValue === 3 ? 'block' : 'none' }}>
                        <Card className='margin-bottom-m'>
                            <CardContent>
                                <Stack direction="row">
                                    <h3 >Documentation</h3>
                                    <Stack direction="row">
                                        <Button className='margin-right-base' onClick={toggleAddDocumentationDrawer("right", true)}>Add</Button>
                                        <Button className='btn-icon'onClick={toggleActionSheet(true)}><MoreVertRoundedIcon/></Button>
                                    </Stack>
                                </Stack>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell> <Checkbox checked={selectAll} onChange={handleSelectAll} /></TableCell>
                                            <TableCell  onClick={() => requestSort('name')} style={{ cursor: 'pointer' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === 'name'}
                                                    direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                                                    >
                                                    Name
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className='text-align-right'  onClick={() => requestSort('size')} style={{ cursor: 'pointer' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === 'size'}
                                                    direction={sortConfig.key === 'size' ? sortConfig.direction : 'asc'}
                                                    >
                                                    Size
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {displayedData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell><Checkbox onChange={() => handleRowSelect(index)} /></TableCell>
                                                <TableCell>{row.format}</TableCell>
                                                <TableCell className='text-align-right'>{row.size}</TableCell>
                                                <TableCell>
                                                <Button onClick={toggleDeleteActionSheet(true)}><DeleteOutlineRoundedIcon className='text-primary'/></Button>
                                            </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>  
                            </CardContent>
                        </Card>  
                    </section>
                    <Button className='margin-right-base cancel' onClick={handleClickSnackbar}>Cancel</Button>
                    <Button className='primary'onClick={handleClickSnackbar}>Submit</Button>
                </div>

            </>
            ):(
            <>
            <Grid container>
                <Grid item sm={3} md={3}> 
                    <SectionIndex sections={sections} />
                </Grid>
                <Grid item sm={9} md={mdValue}>
                    <section id="section1">
                        <Card className='margin-bottom-m'>
                            <CardContent>
                                <form>
                                    <h2 className='margin-bottom-base'>Generic</h2>
                                    <InputLabel htmlFor="id-name" required >Proposal requested by</InputLabel>
                                    <TextField sx={{width:400}}id="id-name" placeholder="Name" />
                                    <InputLabel required>Area</InputLabel>
                                    <FormControl sx={{width:300}} className='margin-bottom-m'>
                                        <Select defaultValue={1}>
                                            <MenuItem value={1}>Accesories</MenuItem>
                                            <MenuItem value={2}>Headphones</MenuItem>
                                            <MenuItem value={3}>Laptops</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div className='date-picker'>
                                        <InputLabel htmlFor='Requested date'>Requested date</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker sx={{width: 150}}/>
                                        </LocalizationProvider>
                                    </div>
                                    <div  className='margin-bottom-m'>
                                        <InputLabel>Type of agreement</InputLabel>
                                        <RadioGroup row defaultValue="New">
                                            <FormControlLabel value="New" control={<Radio />} label="New" />
                                            <FormControlLabel value="Renovation" control={<Radio />} label="Renovation" />
                                        </RadioGroup>
                                    </div>
                                    <div className='margin-bottom-m'>
                                        <InputLabel>Mechanics</InputLabel>
                                        <FormControlLabel control={<Checkbox />} label='Auto' />
                                        <FormControlLabel  control={<Checkbox />} label='Manual' />
                                        <FormControlLabel  control={<Checkbox />} label='Semi-auto' />
                                    </div>
                                    <InputLabel>Auto renovation</InputLabel>
                                    <RadioGroup className='margin-bottom-m' row defaultValue="No">
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    <div className='margin-bottom-m'>
                                    <InputLabel required>Client</InputLabel>
                                    <FormControl sx={{width:300}}>
                                        <Select defaultValue={1}>
                                            <MenuItem value={1}>Amos</MenuItem>
                                            <MenuItem value={2}>Andrea</MenuItem>
                                            <MenuItem value={3}>Ann</MenuItem>
                                            <MenuItem value={4}>Antonia</MenuItem>
                                            <MenuItem value={5}>Ashton</MenuItem>
                                        </Select>
                                    </FormControl>
                                        <Button onClick={toggleAdvancedDrawer("right", true)} className='margin-left-base'>Advanced Search</Button>
                                    </div>
                                </form>
                            </CardContent>
                        </Card>
                    </section>
                    <section id="section2">
                        <Card className='margin-bottom-m'>
                            <CardContent>
                                <Stack direction="row">
                                    <h2 className='full-width'>Equipment</h2>
                                    <Stack direction="row">
                                    {isNotDesktop ? (
                                        <>
                                            <Button className='margin-right-base' onClick={toggleAddEquipmentDrawer("right", true)}>Add</Button>
                                            <Button className='btn-icon'onClick={toggleActionSheet(true)}><MoreVertRoundedIcon/></Button>
                                        </>
                                    ):(
                                        <>
                                            <Button className='small margin-right-base' onClick={toggleAddEquipmentDrawer("right", true)}>Add</Button>
                                            <Button className='small btn-icon' onClick={handlePopoverClick}><MoreVertRoundedIcon/></Button>
                                        </>
                                    )}
                                        <ConfirmationDelete
                                            open={isConfirmationDeleteOpen}
                                            onClose={handleCloseConfirmationDelete}
                                            anchorEl={anchorEl}
                                        />
                                    </Stack>
                                </Stack>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell> <Checkbox checked={selectAll} onChange={handleSelectAll} /></TableCell>
                                        <TableCell  onClick={() => requestSort('name')} style={{ cursor: 'pointer' }}>
                                            <TableSortLabel
                                                active={sortConfig.key === 'name'}
                                                direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                                                >
                                                Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className='text-align-right'  onClick={() => requestSort('price')} style={{ cursor: 'pointer' }}>
                                            <TableSortLabel
                                                active={sortConfig.key === 'price'}
                                                direction={sortConfig.key === 'price' ? sortConfig.direction : 'asc'}
                                                >
                                                Price
                                            </TableSortLabel>
                                        </TableCell>               
                                        <TableCell></TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {displayedData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell><Checkbox onChange={() => handleRowSelect(index)} /></TableCell>
                                            <TableCell> <Link>{row.name}</Link></TableCell>
                                        <TableCell className='text-align-right'>{row.price}</TableCell>
                                        <TableCell className='text-align-right'>
                                            <Button onClick={handleOpenConfirmationDelete}>
                                                <DeleteOutlineRoundedIcon className='text-primary'/>
                                            </Button>
                                        </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </section>
                    <section id="section3">
                        <Card className='margin-bottom-m'>
                            <CardContent>
                            <Stack direction="row">
                                <h2 className='full-width'>Documentation</h2>
                                <Stack direction="row">
                                {isNotDesktop ? (
                                        <>
                                            <Button className='margin-right-base' onClick={toggleAddDocumentationDrawer("right", true)}>Add</Button>
                                            <Button className='btn-icon'onClick={toggleActionSheet(true)}><MoreVertRoundedIcon/></Button>
                                        </>
                                    ):(
                                        <>
                                            <Button className='small margin-right-base' onClick={toggleAddDocumentationDrawer("right", true)}>Add</Button>
                                            <Button className='small btn-icon' onClick={handlePopoverClick}><MoreVertRoundedIcon/></Button>
                                        </>
                                    )}
                                </Stack>
                            </Stack>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell> <Checkbox checked={selectAll} onChange={handleSelectAll} /></TableCell>
                                        <TableCell  onClick={() => requestSort('name')} style={{ cursor: 'pointer' }}>
                                            <TableSortLabel
                                                active={sortConfig.key === 'name'}
                                                direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                                                >
                                                Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className='text-align-right'  onClick={() => requestSort('size')} style={{ cursor: 'pointer' }}>
                                            <TableSortLabel
                                                active={sortConfig.key === 'size'}
                                                direction={sortConfig.key === 'size' ? sortConfig.direction : 'asc'}
                                                >
                                                Size
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {displayedData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell><Checkbox onChange={() => handleRowSelect(index)} /></TableCell>
                                            <TableCell>{row.format}</TableCell>
                                        <TableCell className='text-align-right'>{row.size}</TableCell>
                                        <TableCell className='text-align-right'>
                                            <Button onClick={handleOpenConfirmationDelete}>
                                                <DeleteOutlineRoundedIcon className='text-primary'/>
                                            </Button>
                                        </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </section>
                    <div>
                        <Button className='margin-right-base cancel' onClick={handleClickSnackbar}>Cancel</Button>
                        <Button className='primary'onClick={handleClickSnackbar}>Submit</Button>
                    </div>
                </Grid>
            {isNotDesktop ? (
                <>

                </>
            ):(                  
                <Grid item md={3}>
                    {showCommentCard && (
                    <div className='margin-left-m'>
                        {drawerCommentContent()}
                    </div>
                    )}
                </Grid>
            )}
            </Grid>
            </>
            
            )}
            
   
        </LayoutSideWrapper> 
    )
}