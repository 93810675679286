import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, CardMedia } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PreviewComponent from '../app-components/PreviewComponent';


export default function BasicCard() {

  const sampleCode_basicCard="<Card className='padding-none'>" +
                            "\n\t<CardContent>" +
                            "\n\t\tTake control with a loan made for you. Access your loan information whenever you need it," +
                            "\n\t\twe developed tools to give you freedom to focus on the most important things" +
                            "\n\t</CardContent>" +
                            "\n</Card>";

  const sampleCode_paddingCard= "<Card>" +
                              "\n\t<CardContent>" +
                              "\n\t\tTake control with a loan made for you. Access your loan information whenever you need it," +
                              "\n\t\twe developed tools to give you freedom to focus on the most important things" +
                              "\n\t</CardContent>" +
                              "\n</Card>";

const sampleCode_mediaCard ="<Card>" + 
                          "\n\t<CardMedia" +
                          "\n\t\tcomponent='img'" +
                          "\n\t\timage='/path/to/image'" +
                          "\n\t\ttitle='title of image'" +
                          "\n\t\tclassName='img-cover'" +
                          "\n\t/>" +
                          "\n\t<CardContent className='padding-left-base'>" +
                          "\n\t\t<h4>Morwan Chatila</h4>" +
                          "\n\t\t<p className='horizontal-align'>" +
                          "\n\t\t\t<EmailIcon className='font-size-s margin-right-xs'/>morwan@couplahouse.com" +
                          "\n\t\t</p>" +
                          "\n\t\t<p className='horizontal-align'>" +
                          "\n\t\t\t<LocalPhoneIcon className='font-size-s margin-right-xs'/>+1 604428 0611" +
                          "\n\t\t</p>" +
                          "\n\t</CardContent>" +
                          "\n</Card>";


  return (
  <div>
    <PreviewComponent title="Without Padding" description="" code={sampleCode_basicCard}>
        <Box sx={{ width: 400 }}>
          <Card className='padding-none'>
            <CardContent >
                Take control with a loan made for you. Access your loan information whenever you need it, 
                we developed tools to give you freedom to focus on the most important things.
            </CardContent>
          </Card>
        </Box>
    </PreviewComponent>

    <PreviewComponent title="With Padding" description="" code={sampleCode_paddingCard}>
        <Box sx={{ width: 400 }}>
          <Card>
            <CardContent>
                Take control with a loan made for you. Access your loan information whenever you need it, 
                we developed tools to give you freedom to focus on the most important things.
            </CardContent>
          </Card>
        </Box>
    </PreviewComponent>

    <PreviewComponent title="Media Card" description="" code={sampleCode_mediaCard}>
        <Box sx={{ width: 400 }}>
          <Card>
            <CardMedia
                component='img'
                image="/images/profile.jpg"
                title="Profile Picture"
                className='img-cover'
              />
            <CardContent className='padding-left-base'>
              <h4>Morwan Chatila</h4>
              <p className='horizontal-align'>
                <EmailIcon className="font-size-s margin-right-xs"/>morwan@couplahouse.com
              </p>
              <p className='horizontal-align'>
                <LocalPhoneIcon className="font-size-s margin-right-xs"/>+1 604428 0611
              </p>
            </CardContent>
          </Card>
        </Box>
    </PreviewComponent>
  </div>
  );
}