import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './theme/theme.scss';
import './App.scss';

//Live Style Guide Screens
import ScreenTemplates from './pages/ScreenTemplates';
import Components from './pages/Components';
import Home from './pages/Home';
import GetStarted from './pages/GetStarted';
import NotFound from './pages/NotFound';
import Styles from './pages/Styles';

//Screen Samples
import Dashboard from './screen-templates/Dashboard/Dashboard';
import DetailComplex from './screen-templates/DetailComplex';
import DetailConsultEditable from './screen-templates/DetailConsultEditable';
import DetailConsultInformation from './screen-templates/DetailConsultInformation';
import FAQs from './screen-templates/FAQs';
import FormSection from './screen-templates/FormSection';
import FormSurvey from './screen-templates/FormSurvey';
import FormWizardHorizontal from './screen-templates/FormWizardHorizontal';
import FormWizardVertical from './screen-templates/FormWizardVertical';
import HomeScreen from './screen-templates/Home';
import LoadingScreen from './screen-templates/LoadingScreen';
import SimpleForm from './screen-templates/SimpleForm';
import SimpleList from './screen-templates/SimpleList';
import Login from './screen-templates/Login';
import Error from './screen-templates/Error';
import OnBoarding from './screen-templates/OnBoarding';


function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        'SofiaPro-Regular', //TODO: Configure this font family for each project, if needed.
      ].join(','),
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="get-started" element={<GetStarted />} />
            <Route path="styles" element={<Styles />} />
            <Route path="components" element={<Components />} />
            <Route path="screen-templates" element={<ScreenTemplates />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="detail-complex" element={<DetailComplex />} />
            <Route
              path="detail-consult-editable"
              element={<DetailConsultEditable />}
            />
            <Route
              path="detail-consult-information"
              element={<DetailConsultInformation />}
            />
            <Route path="FAQs" element={<FAQs />} />
            <Route path="form-section" element={<FormSection />} />
            <Route path="form-survey" element={<FormSurvey />} />
            <Route path="form-wizard-horizontal" element={<FormWizardHorizontal />} />
            <Route path="form-wizard-vertical" element={<FormWizardVertical />} />
            <Route path="home" element={<HomeScreen />} />
            <Route path="loading-screen" element={<LoadingScreen />} />
            <Route path="onboarding" element={<OnBoarding />} />
            <Route path="simple-form" element={<SimpleForm />} />
            <Route path="simple-list" element={<SimpleList />} />
            <Route path="login" element={<Login />} />
            <Route path="error" element={<Error />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
