import { Breadcrumbs } from '@mui/material';
import React, { useState } from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AppMenuList from './AppMenuList';

export default function LayoutSide(props) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const collapseMenu = () => {
    setMenuCollapse(!menuCollapse);
  };

  //TODO link image header logo
  return (
    <div
      className={
        'layout-side' +
        (props.halfSize ? ' layout-top--half-width' : '') +
        (menuCollapse
          ? ' layout-side--is-collapsed layout-side--is-hover-available'
          : '') +
        (menuVisible ? ' app-menu-visible' : '')
      }
      key="layout-side"
    >
      <aside className="layout-side__navigation">
        <nav
          data-advancedhtml=""
          className="app-menu-content"
          role="navigation"
        >
          <div className="app-menu-button-collapsible" onClick={collapseMenu}>
            <ArrowBackOutlinedIcon className="font-size-base text-primary" />
          </div>
          <div className="app-menu-close"></div>
          <div className="header-logo">
            <div className="header-logo__image">
              <div className="app-menu__logo-image">
                <img src={props.appLogoURL} alt="" />
              </div>
              <div className="app-menu__logo-image-full">
                <img src={props.appLogoFullURL} alt="" />
              </div>
            </div>
            <div className="app-menu__name">{props.appName}</div>
          </div>
          <div className="app-menu-links" role="menubar">
            <AppMenuList menuItems={props.menuItems} />
          </div>
          <div className="app-login-info">{props.userInfo}</div>
          <div className="menu-copy">{props.sideFooter}</div>
        </nav>
        <div className="app-menu-overlay" role="button"></div>
      </aside>

      <div className="layout-side__main">
        <header className="layout-side__header">
          <div className="layout-side__header-container layout-grid-container">
            <div className="layout-side__header-application-name">
              <img src={props.appLogoURL} alt="" />
              {props.appName}
            </div>
            <nav className="layout-side__header-menu"></nav>
            <div className="layout-side__header-actions">
              {props.headerActions}
            </div>
            <div className="hamburguer-menu" onClick={toggleMenu}></div>
          </div>
        </header>

        <div className="layout-side__content">
          <div className="layout-grid-container">
            <div className="breadcrumbs">
              {props.breadcrumbs !== undefined ? (
                <Breadcrumbs separator="›">
                  {props.breadcrumbs.map((breadcrumb) => breadcrumb)}
                </Breadcrumbs>
              ) : (
                ''
              )}
            </div>
            <div className="content-top">
              <h1 className="content-top__title">{props.title}</h1>
              <div className="content-top__actions">{props.actions}</div>
            </div>
            <div className="main-content">{props.children}</div>
          </div>
        </div>
        <div className="layout-side__footer">
          <div className="layout-grid-container">{props.footer}</div>
        </div>
      </div>

      {/* App Menu for Mobile */}
      <div className="app-menu-overlay" onClick={toggleMenu}></div>
      <div className="app-menu">
        <div className="app-menu__header">
          <div className="app-menu__header-close" onClick={toggleMenu}>
            <i class="fa-light fa-xmark"></i>
          </div>
        </div>
        <div className="app-menu__content">{props.appMenuPages}</div>
        <div className="app-menu__content-bottom">{props.userInfo}</div>
        <div className="app-menu__footer">{props.sideFooter}</div>
      </div>
    </div>
  );
}
