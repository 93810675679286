import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import PreviewComponent from "../app-components/PreviewComponent";
import { Stack } from "@mui/material";

export default function ToggleButtons() {
  const sampleCode_singleSelection =
    "<ToggleButtonGroup" +
    "\n\texclusive>" +
    "\n\t<ToggleButton>" +
    "\n\t\tWeb" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tNative" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tAndroid" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tiOS" +
    "\n\t</ToggleButton>" +
    "\n</ToggleButtonGroup>;" +
    "\n" +
    "\n<ToggleButtonGroup" +
    "\n\texclusive>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatAlignLeftIcon />" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatAlignCenterIcon />" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatAlignRightIcon />" +
    "\n\t</ToggleButton>" +
    "\n</ToggleButtonGroup>";

  const sampleCode_multipleSelection =
    "<ToggleButtonGroup>" +
    "\n\t<ToggleButton>" +
    "\n\t\tWeb" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tNative" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tAndroid" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tiOS" +
    "\n\t</ToggleButton>" +
    "\n</ToggleButtonGroup>;" +
    "\n" +
    "\n<ToggleButtonGroup>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatBoldIcon />" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatItalicIcon />" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatUnderlinedIcon />" +
    "\n\t</ToggleButton>" +
    "\n</ToggleButtonGroup>";

  const sampleCode_isDisabled =
    "<ToggleButtonGroup" +
    "\n\texclusive>" +
    "\n\t<ToggleButton>" +
    "\n\t\tWeb" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tNative" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton disabled>" +
    "\n\t\tAndroid" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton disabled>" +
    "\n\t\tiOS" +
    "\n\t</ToggleButton>" +
    "\n</ToggleButtonGroup>";

  const sampleCode_isDisabledSelected =
    "<ToggleButtonGroup" +
    "\n\texclusive>" +
    "\n\t<ToggleButton disabled>" +
    "\n\t\tWeb" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton disabled>" +
    "\n\t\tNative" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton disabled>" +
    "\n\t\tAndroid" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton disabled>" +
    "\n\t\tiOS" +
    "\n\t</ToggleButton>" +
    "\n</ToggleButtonGroup>";

  const sampleCode_SizesSmall =
    "<ToggleButtonGroup" +
    '\n\tclassName="small"' +
    "\n\texclusive>" +
    "\n\t<ToggleButton>" +
    "\n\t\tWeb" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tNative" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tAndroid" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tiOS" +
    "\n\t</ToggleButton>" +
    "\n</ToggleButtonGroup>;" +
    "\n" +
    "\n<ToggleButtonGroup" +
    '\n\tclassName="small"' +
    "\n\texclusive>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatAlignLeftIcon />" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatAlignCenterIcon />" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatAlignRightIcon />" +
    "\n\t</ToggleButton>" +
    "\n</ToggleButtonGroup>";

  const sampleCode_SizesLarge =
    "<ToggleButtonGroup" +
    '\n\tclassName="large"' +
    "\n\texclusive>" +
    "\n\t<ToggleButton>" +
    "\n\t\tWeb" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tNative" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tAndroid" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\tiOS" +
    "\n\t</ToggleButton>" +
    "\n</ToggleButtonGroup>;" +
    "\n" +
    "\n<ToggleButtonGroup" +
    '\n\tclassName="large"' +
    "\n\texclusive>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatAlignLeftIcon />" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatAlignCenterIcon />" +
    "\n\t</ToggleButton>" +
    "\n\t<ToggleButton>" +
    "\n\t\t<FormatAlignRightIcon />" +
    "\n\t</ToggleButton>" +
    "\n</ToggleButtonGroup>";

  const [alignment, setAlignment] = React.useState("button1");
  const [alignments, setAlignments] = React.useState("button1");
  const [formats, setFormats] = React.useState(() => ["button1", ""]);
  const [format, setFormat] = React.useState("button1");

  const handleFormats = (event, newFormats) => {
    setFormats(newFormats);
  };

  const [formatation, setFormatation] = React.useState("center");
  const handleFormatation = (event, newFormats) => {
    setFormatation(newFormats);
  };

  const [formatations, setFormatations] = React.useState(() => [
    "bold",
    "underlined",
  ]);
  const handleFormatations = (event, newFormats) => {
    setFormatations(newFormats);
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleAlignments = (event, newAlignments) => {
    setAlignments(newAlignments);
  };

  const handleFormat = (event, newFormat) => {
    setFormat(newFormat);
  };

  return (
    <div>
      <PreviewComponent
        title="Single Selection"
        description=""
        code={sampleCode_singleSelection}
      >
        <Stack direction="column" spacing={3}>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
          >
            <ToggleButton value="button1">Web</ToggleButton>
            <ToggleButton value="button2">Native</ToggleButton>
            <ToggleButton value="button3">Android</ToggleButton>
            <ToggleButton value="button4">iOS</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            value={formatation}
            exclusive
            onChange={handleFormatation}
          >
            <ToggleButton value="left">
              <FormatAlignLeftIcon />
            </ToggleButton>
            <ToggleButton value="center">
              <FormatAlignCenterIcon />
            </ToggleButton>
            <ToggleButton value="right">
              <FormatAlignRightIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </PreviewComponent>

      <PreviewComponent
        title="Multiple Selection"
        description=""
        code={sampleCode_multipleSelection}
      >
        <Stack direction="column" spacing={3}>
          <ToggleButtonGroup value={formats} onChange={handleFormats}>
            <ToggleButton value="button1">Web</ToggleButton>
            <ToggleButton value="button2">Native</ToggleButton>
            <ToggleButton value="button3">Android</ToggleButton>
            <ToggleButton value="button4">iOS</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup value={formatations} onChange={handleFormatations}>
            <ToggleButton value="bold">
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton value="italic">
              <FormatItalicIcon />
            </ToggleButton>
            <ToggleButton value="underlined">
              <FormatUnderlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </PreviewComponent>

      <PreviewComponent
        title="Disabled"
        description=""
        code={sampleCode_isDisabled}
      >
        <ToggleButtonGroup
          value={alignments}
          exclusive
          onChange={handleAlignments}
        >
          <ToggleButton value="button1">Web</ToggleButton>
          <ToggleButton value="button2">Native</ToggleButton>
          <ToggleButton disabled value="button3">
            Android
          </ToggleButton>
          <ToggleButton disabled value="button4">
            iOS
          </ToggleButton>
        </ToggleButtonGroup>
      </PreviewComponent>

      <PreviewComponent
        title="Disabled Selected"
        description=""
        code={sampleCode_isDisabledSelected}
      >
        <ToggleButtonGroup value={format} exclusive onChange={handleFormat}>
          <ToggleButton disabled value="button1">
            Web
          </ToggleButton>
          <ToggleButton disabled value="button2">
            Native
          </ToggleButton>
          <ToggleButton disabled value="button3">
            Android
          </ToggleButton>
          <ToggleButton disabled value="button4">
            iOS
          </ToggleButton>
        </ToggleButtonGroup>
      </PreviewComponent>

      <PreviewComponent
        title="Sizes"
        subtitle="Small"
        description=""
        code={sampleCode_SizesSmall}
      >
        <Stack direction="column" spacing={3}>
          <ToggleButtonGroup
            className="small"
            value={alignment}
            exclusive
            onChange={handleAlignment}
          >
            <ToggleButton value="button1">Web</ToggleButton>
            <ToggleButton value="button2">Native</ToggleButton>
            <ToggleButton value="button3">Android</ToggleButton>
            <ToggleButton value="button4">iOS</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            className="small"
            value={formatation}
            exclusive
            onChange={handleFormatation}
          >
            <ToggleButton value="left">
              <FormatAlignLeftIcon />
            </ToggleButton>
            <ToggleButton value="center">
              <FormatAlignCenterIcon />
            </ToggleButton>
            <ToggleButton value="right">
              <FormatAlignRightIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </PreviewComponent>

      <PreviewComponent
        title="Sizes"
        subtitle="Large"
        description=""
        code={sampleCode_SizesLarge}
      >
        <Stack direction="column" spacing={3}>
          <ToggleButtonGroup
            className="large"
            value={alignment}
            exclusive
            onChange={handleAlignment}
          >
            <ToggleButton value="button1">Web</ToggleButton>
            <ToggleButton value="button2">Native</ToggleButton>
            <ToggleButton value="button3">Android</ToggleButton>
            <ToggleButton value="button4">iOS</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            className="large"
            value={formatation}
            exclusive
            onChange={handleFormatation}
          >
            <ToggleButton value="left">
              <FormatAlignLeftIcon />
            </ToggleButton>
            <ToggleButton value="center">
              <FormatAlignCenterIcon />
            </ToggleButton>
            <ToggleButton value="right">
              <FormatAlignRightIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </PreviewComponent>
    </div>
  );
}
