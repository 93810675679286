import CopyToClipboard from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import * as React from 'react';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Button,  SnackbarContent} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function CodeBlock({ code }) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="code-preview">
      <SyntaxHighlighter
        code={code}
        style={monokaiSublime}
        language="xml"
        showLineNumbers={true}
      />
      {openSnackbar && (
        <SnackbarContent style={{width:50}}
          className="success-snackbar"
          message={
            <>
              <div className="horizontal-align">
                <CheckCircleOutlinedIcon />
                Copied
              </div>
            </>
          }
          action={<CloseIcon className="closeIcon" onClick={handleCloseSnackbar}/>}
        />
      )}
      <CopyToClipboard text={code}>
        <Button className="code-preview-button" onClick={handleClickSnackbar}>Copy Code</Button>
      </CopyToClipboard>
    </div>
  );
}

