import React from 'react';
import { Button, Popover } from "@mui/material";

function ConfirmationDelete({ open, onClose, anchorEl }) {

    return(

    <Popover className='popover' open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
        }}
        transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
        }}
    >

        <p className='text-align-center'>The information will be permanently 
        <br/>
        deleted, do you confirm that you
        <br/>
        intend to continue?</p>
        <div className='text-align-center'>
            <Button className='cancel' onClick={onClose}>Cancel</Button>
            <Button className='error margin-left-base'>Delete</Button>
        </div>
    </Popover>

    )
}

export default ConfirmationDelete;