import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function SelectLabels() {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const sampleCode_labelsHelperText =
                                    "<FormControl>" +
                                    "\n\t<InputLabel >Age</InputLabel>" +
                                    "\n\t<Select" +
                                    "\n\t\tvalue={age}" +
                                    "\n\t\tlabel='Age'" +
                                    "\n\t>" +
                                    "\n\t\t<MenuItem value={10}>Ten</MenuItem>" +
                                    "\n\t\t<MenuItem value={20}>Twenty</MenuItem>" +
                                    "\n\t\t<MenuItem value={30}>Thirty</MenuItem>" +
                                    "\n\t</Select>" +
                                    "\n\t<FormHelperText>With label + helper text</FormHelperText>" +
                                    "\n</FormControl>" +
                                    "\n<FormControl>" +
                                    "\n\t<Select" +
                                    "\n\t\tvalue={age}" +
                                    "\n\t>" +
                                    "\n\t\t<MenuItem value={10}>Ten</MenuItem>" +
                                    "\n\t\t<MenuItem value={20}>Twenty</MenuItem>" +
                                    "\n\t\t<MenuItem value={30}>Thirty</MenuItem>" +
                                    "\n\t</Select>" +
                                    "\n\t<FormHelperText>Without label</FormHelperText>" +
                                    "\n</FormControl>"
                                 
  return (
    <div>
       <PreviewComponent title="Labels and Helper Text" description="" code={sampleCode_labelsHelperText}>
            <FormControl className='min-width-150 margin-right-s'>
              <InputLabel >Age</InputLabel>
              <Select
                value={age}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>With label + helper text</FormHelperText>
            </FormControl>
            <FormControl className='min-width-150'>
              <Select
                value={age}
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>Without label</FormHelperText>
            </FormControl> 
        </PreviewComponent>
    </div>
  );
}