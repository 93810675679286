import * as React from 'react';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function LayoutBlank_Sample() {

  
  const sampleCode_layoutBlank = 
      "<LayoutBlank>" + 
      "\n\tPage Content" + 
      "\n</LayoutBlank>";
  
  return (
    <div>
      <PreviewComponent title="" description="" code={sampleCode_layoutBlank}>
        This layout has no configurable parameters.
      </PreviewComponent>
    </div>
  );
}