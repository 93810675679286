import * as React from 'react';
import './../App.scss';
import './../theme/theme.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Stack } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CodeBlock from '../app-components/CodeBlock';
import PreviewComponent from '../app-components/PreviewComponent';
import { Link } from 'react-router-dom';
import LayoutTopWrapperLSG from '../app-components/LayoutTopWrapperLSG';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function Styles(){

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
    setValue(newValue);
    };

    const sampleTypographySize = "<div className='font-size-display'>This is the Display content.</div>" +
                               "\n<p className='font-size-s'>This is the paragraph content.</p>";

    const sampleTypographyHeadings = "<h1>This is the Heading 1 content.</h1>";

    return (

        <LayoutTopWrapperLSG title="Styles" breadcrumbs={[
            <Link to="/">Home</Link>,
            <span>Styles</span>
          ]}>
                
                <PreviewComponent title="Color Pallete" description="Color reference to support or emphasize content or to help conveying a message.">
                    <TableContainer className='styles-table'>
                      <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Color</TableCell>
                                <TableCell>#Hex</TableCell>
                                <TableCell>Background Class</TableCell>
                                <TableCell>Text Class</TableCell>
                                <TableCell>CSS Variable</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className='border-top-m'>
                                <TableCell>Primary</TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-primary'/>Primary Base</Stack></TableCell>
                                <TableCell>#ed002f</TableCell>
                                <TableCell>.background-primary</TableCell>
                                <TableCell>.text-primary</TableCell>
                                <TableCell>--color-primary</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Secondary</TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-secondary'/>Secondary Base</Stack></TableCell>
                                <TableCell>#000000</TableCell>
                                <TableCell>.background-secondary</TableCell>
                                <TableCell>.text-secondary</TableCell>
                                <TableCell>--color-secondary</TableCell>
                            </TableRow>
                            <TableRow className='border-top-m'>
                                <TableCell>Neutral Palette</TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-10'/>Neutral 10</Stack></TableCell>
                                <TableCell>#000000</TableCell>
                                <TableCell>.background-neutral-10</TableCell>
                                <TableCell>.text-neutral-10</TableCell>
                                <TableCell>--color-neutral-10</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-9'/>Neutral 9</Stack></TableCell>
                                <TableCell>#272b30</TableCell>
                                <TableCell>.background-neutral-9</TableCell>
                                <TableCell>.text-neutral-9</TableCell>
                                <TableCell>--color-neutral-9</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-8'/>Neutral 8</Stack></TableCell>
                                <TableCell>#4f575e</TableCell>
                                <TableCell>.background-neutral-8</TableCell>
                                <TableCell>.text-neutral-8</TableCell>
                                <TableCell>--color-neutral-8</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-7'/>Neutral 7</Stack></TableCell>
                                <TableCell>#6a7178</TableCell>
                                <TableCell>.background-neutral-7</TableCell>
                                <TableCell>.text-neutral-7</TableCell>
                                <TableCell>--color-neutral-7</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-6'/>Neutral 6</Stack></TableCell>
                                <TableCell>#9ea6ae</TableCell>
                                <TableCell>.background-neutral-6</TableCell>
                                <TableCell>.text-neutral-6</TableCell>
                                <TableCell>--color-neutral-6</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-5'/>Neutral 5</Stack></TableCell>
                                <TableCell>#ced4da</TableCell>
                                <TableCell>.background-neutral-5</TableCell>
                                <TableCell>.text-neutral-5</TableCell>
                                <TableCell>--color-neutral-5</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-4'/>Neutral 4</Stack></TableCell>
                                <TableCell>#dee2e6</TableCell>
                                <TableCell>.background-neutral-4</TableCell>
                                <TableCell>.text-neutral-4</TableCell>
                                <TableCell>--color-neutral-4</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-3'/>Neutral 3</Stack></TableCell>
                                <TableCell>#e9ecef</TableCell>
                                <TableCell>.background-neutral-3</TableCell>
                                <TableCell>.text-neutral-3</TableCell>
                                <TableCell>--color-neutral-3</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-2'/>Neutral 2</Stack></TableCell>
                                <TableCell>#f1f3f5</TableCell>
                                <TableCell>.background-neutral-2</TableCell>
                                <TableCell>.text-neutral-2</TableCell>
                                <TableCell>--color-neutral-2</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-1'/>Neutral 1</Stack></TableCell>
                                <TableCell>#f8f9fa</TableCell>
                                <TableCell>.background-neutral-1</TableCell>
                                <TableCell>.text-neutral-1</TableCell>
                                <TableCell>--color-neutral-1</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-neutral-0'/>Neutral 0</Stack></TableCell>
                                <TableCell>#ffffff</TableCell>
                                <TableCell>.background-neutral-0</TableCell>
                                <TableCell>.text-neutral-0</TableCell>
                                <TableCell>--color-neutral-0</TableCell>
                            </TableRow>
                            <TableRow className='border-top-m'>
                                <TableCell>Semantic Palette</TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-info'/>Info Base</Stack></TableCell>
                                <TableCell>#017aad</TableCell>
                                <TableCell>.background-info</TableCell>
                                <TableCell>.text-info</TableCell>
                                <TableCell>--color-info</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-info-light'/>Info Light</Stack></TableCell>
                                <TableCell>#e5f5fc</TableCell>
                                <TableCell>.background-info-light</TableCell>
                                <TableCell>.text-info-light</TableCell>
                                <TableCell>--color-info-light</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-success'/>Success Base</Stack></TableCell>
                                <TableCell>#29823b</TableCell>
                                <TableCell>.background-success</TableCell>
                                <TableCell>.text-success</TableCell>
                                <TableCell>--color-success</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-success-light'/>Success Light</Stack></TableCell>
                                <TableCell>#eaf3eb</TableCell>
                                <TableCell>.background-success-light</TableCell>
                                <TableCell>.text-success-light</TableCell>
                                <TableCell>--color-success-light</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-warning'/>Warning Base</Stack></TableCell>
                                <TableCell>#e9a100</TableCell>
                                <TableCell>.background-warning</TableCell>
                                <TableCell>.text-warning</TableCell>
                                <TableCell>--color-warning</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-warning-light'/>Warning Light</Stack></TableCell>
                                <TableCell>#fdf6e5</TableCell>
                                <TableCell>.background-warning-light</TableCell>
                                <TableCell>.text-warning-light</TableCell>
                                <TableCell>--color-warning-light</TableCell>
                            </TableRow>
                                <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-error'/>Error Base</Stack></TableCell>
                                <TableCell>#dc2020</TableCell>
                                <TableCell>.background-error</TableCell>
                                <TableCell>.text-error</TableCell>
                                <TableCell>--color-error</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><Stack direction='row'><SquareRoundedIcon className='text-error-light'/>Error Light</Stack></TableCell>
                                <TableCell>#fceaea</TableCell>
                                <TableCell>.background-error-light</TableCell>
                                <TableCell>.text-error-light</TableCell>
                                <TableCell>--color-error-light</TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                </PreviewComponent>

                <PreviewComponent title="Borders" description="Reference to the styles that can be applied to the borders of any UI element."/>
                 
                <PreviewComponent subtitle="Border Radius" description="The Border Radius offers combination of 4 different options, from none (0 px) to a full circle (100%). They are used to round the corners of an element's outer edge.">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell>Class</TableCell>
                                    <TableCell>CSS Variable</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-box square border-radius-none'></Box></TableCell>
                                    <TableCell>None</TableCell>
                                    <TableCell>0</TableCell>
                                    <TableCell>.border-radius-none</TableCell>
                                    <TableCell>--border-radius-none</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-box square border-radius-soft'></Box></TableCell>
                                    <TableCell>Soft</TableCell>
                                    <TableCell>4px</TableCell>
                                    <TableCell>.border-radius-soft</TableCell>
                                    <TableCell>--border-radius-soft</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-box rectangle border-radius-rounded'></Box></TableCell>
                                    <TableCell>Rounded</TableCell>
                                    <TableCell>20px</TableCell>
                                    <TableCell>.border-radius-rounded</TableCell>
                                    <TableCell>--border-radius-rounded</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-box square border-radius-circle'></Box></TableCell>
                                    <TableCell>Circle</TableCell>
                                    <TableCell>100%</TableCell>
                                    <TableCell>.border-radius-circle</TableCell>
                                    <TableCell>--border-radius-circle</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </PreviewComponent>

                <PreviewComponent subtitle="Border Sizes" description="The Border Size is applied to all corners of an element. There are 4 sizes available — None, S, M and L.">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell>CSS Variable</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-box square border-size-none'></Box></TableCell>
                                    <TableCell>None</TableCell>
                                    <TableCell>0</TableCell>
                                    <TableCell>--border-size-none</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell><Box className='preview-box square border-size-s'></Box></TableCell>
                                    <TableCell>S</TableCell>
                                    <TableCell>1px</TableCell>
                                    <TableCell>--border-size-s</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-box square border-size-m'></Box></TableCell>
                                    <TableCell>M</TableCell>
                                    <TableCell>2px</TableCell>
                                    <TableCell>--border-size-m</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-box square border-size-l'></Box></TableCell>
                                    <TableCell>L</TableCell>
                                    <TableCell>4px</TableCell>
                                    <TableCell>--border-size-l</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </PreviewComponent>    
                    
                <PreviewComponent title="Spacings" description="Reference scale for the construction and positioning of the UI elements on an interface."></PreviewComponent>
                  
                    <PreviewComponent subtitle="Uniform Spacing" description="Uniform spacing is applied to all sides of an element. Margin and Padding share the same scale with their respective classes.">
                      <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell>MUI Spacing<br></br>Equivalence</TableCell>
                                    <TableCell>Padding Class<br></br>(MUI Prop: p)</TableCell>
                                    <TableCell>Margin Class<br></br>(MUI Prop: m)</TableCell>
                                    <TableCell>CSS Variable</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-none'></Box></Box></TableCell>
                                    <TableCell>None</TableCell>
                                    <TableCell>0</TableCell>
                                    <TableCell>0</TableCell>
                                    <TableCell>.padding-none</TableCell>
                                    <TableCell>.margin-none</TableCell>
                                    <TableCell>--space-none</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-xs'></Box></Box></TableCell>
                                    <TableCell>XS</TableCell>
                                    <TableCell>4px</TableCell>
                                    <TableCell>0.5</TableCell>
                                    <TableCell>.padding-xs</TableCell>
                                    <TableCell>.margin-xs</TableCell>
                                    <TableCell>--space-xs</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-s'></Box></Box></TableCell>
                                    <TableCell>S</TableCell>
                                    <TableCell>8px</TableCell>
                                    <TableCell>1</TableCell>
                                    <TableCell>.padding-s</TableCell>
                                    <TableCell>.margin-s</TableCell>
                                    <TableCell>--space-s</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-base'></Box></Box></TableCell>
                                    <TableCell>Base</TableCell>
                                    <TableCell>16px</TableCell>
                                    <TableCell>2</TableCell>
                                    <TableCell>.padding-base</TableCell>
                                    <TableCell>.margin-base</TableCell>
                                    <TableCell>--space-base</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-m'></Box></Box></TableCell>
                                    <TableCell>M</TableCell>
                                    <TableCell>24px</TableCell>
                                    <TableCell>3</TableCell>
                                    <TableCell>.padding-m</TableCell>
                                    <TableCell>.margin-m</TableCell>
                                    <TableCell>--space-m</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-l'></Box></Box></TableCell>
                                    <TableCell>L</TableCell>
                                    <TableCell>32px</TableCell>
                                    <TableCell>4</TableCell>
                                    <TableCell>.padding-l</TableCell>
                                    <TableCell>.margin-l</TableCell>
                                    <TableCell>--space-l</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-xl'></Box></Box></TableCell>
                                    <TableCell>XL</TableCell>
                                    <TableCell>40px</TableCell>
                                    <TableCell>5</TableCell>
                                    <TableCell>.padding-xl</TableCell>
                                    <TableCell>.margin-xl</TableCell>
                                    <TableCell>--space-xl</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-xxl'></Box></Box></TableCell>
                                    <TableCell>XXL</TableCell>
                                    <TableCell>48px</TableCell>
                                    <TableCell>6</TableCell>
                                    <TableCell>.padding-xxl</TableCell>
                                    <TableCell>.margin-xxl</TableCell>
                                    <TableCell>--space-xxl</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                      </TableContainer>
                    </PreviewComponent>

                    <PreviewComponent subtitle="Directional Spacing" description="Directional spacing is applied to an individual side of an element. Margin and Padding share the same scale with their respective classes.">
                      <Box>
                        <Box>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label="Top" {...a11yProps(0)} />
                                <Tab label="Right" {...a11yProps(1)} />
                                <Tab label="Bottom" {...a11yProps(2)} />
                                <Tab label="Left" {...a11yProps(3)} />
                                <Tab label="Vertical" {...a11yProps(4)} />
                                <Tab label="Horizontal" {...a11yProps(5)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                          <TableContainer className='styles-table'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>MUI Spacing<br></br>Equivalence</TableCell>
                                        <TableCell>Padding Class<br></br>(MUI Prop: pt)</TableCell>
                                        <TableCell>Margin Class<br></br>(MUI Prop: mt)</TableCell>
                                        <TableCell>CSS Variable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='border-top-m'>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-none'></Box></Box></TableCell>
                                        <TableCell>None</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>.padding-top-none</TableCell>
                                        <TableCell>.margin-top-none</TableCell>
                                        <TableCell>--space-top-none</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-top-xs'></Box></Box></TableCell>
                                        <TableCell>XS</TableCell>
                                        <TableCell>4px</TableCell>
                                        <TableCell>0.5</TableCell>
                                        <TableCell>.padding-top-xs</TableCell>
                                        <TableCell>.margin-top-xs</TableCell>
                                        <TableCell>--space-top-xs</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-top-s'></Box></Box></TableCell>
                                        <TableCell>S</TableCell>
                                        <TableCell>8px</TableCell>
                                        <TableCell>1</TableCell>
                                        <TableCell>.padding-top-s</TableCell>
                                        <TableCell>.margin-top-s</TableCell>
                                        <TableCell>--space-top-s</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-top-base'></Box></Box></TableCell>
                                        <TableCell>Base</TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>.padding-top-base</TableCell>
                                        <TableCell>.margin-top-base</TableCell>
                                        <TableCell>--space-top-base</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-top-m'></Box></Box></TableCell>
                                        <TableCell>M</TableCell>
                                        <TableCell>24px</TableCell>
                                        <TableCell>3</TableCell>
                                        <TableCell>.padding-top-m</TableCell>
                                        <TableCell>.margin-top-m</TableCell>
                                        <TableCell>--space-top-m</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-top-l'></Box></Box></TableCell>
                                        <TableCell>L</TableCell>
                                        <TableCell>32px</TableCell>
                                        <TableCell>4</TableCell>
                                        <TableCell>.padding-top-l</TableCell>
                                        <TableCell>.margin-top-l</TableCell>
                                        <TableCell>--space-top-l</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-top-xl'></Box></Box></TableCell>
                                        <TableCell>XL</TableCell>
                                        <TableCell>40px</TableCell>
                                        <TableCell>5</TableCell>
                                        <TableCell>.padding-top-xl</TableCell>
                                        <TableCell>.margin-top-xl</TableCell>
                                        <TableCell>--space-top-xl</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-top-xxl'></Box></Box></TableCell>
                                        <TableCell>XXL</TableCell>
                                        <TableCell>48px</TableCell>
                                        <TableCell>6</TableCell>
                                        <TableCell>.padding-top-xxl</TableCell>
                                        <TableCell>.margin-top-xxl</TableCell>
                                        <TableCell>--space-top-xxl</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <TableContainer className='styles-table'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>MUI Spacing<br></br>Equivalence</TableCell>
                                        <TableCell>Padding Class<br></br>(MUI Prop: pr)</TableCell>
                                        <TableCell>Margin Class<br></br>(MUI Prop: mr)</TableCell>
                                        <TableCell>CSS Variable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='border-top-m'>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-none'></Box></Box></TableCell>
                                        <TableCell>None</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>.padding-right-none</TableCell>
                                        <TableCell>.margin-right-none</TableCell>
                                        <TableCell>--space-right-none</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-right-xs'></Box></Box></TableCell>
                                        <TableCell>XS</TableCell>
                                        <TableCell>4px</TableCell>
                                        <TableCell>0.5</TableCell>
                                        <TableCell>.padding-right-xs</TableCell>
                                        <TableCell>.margin-right-xs</TableCell>
                                        <TableCell>--space-right-xs</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-right-s'></Box></Box></TableCell>
                                        <TableCell>S</TableCell>
                                        <TableCell>8px</TableCell>
                                        <TableCell>1</TableCell>
                                        <TableCell>.padding-right-s</TableCell>
                                        <TableCell>.margin-right-s</TableCell>
                                        <TableCell>--space-right-s</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-right-base'></Box></Box></TableCell>
                                        <TableCell>Base</TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>.padding-right-base</TableCell>
                                        <TableCell>.margin-right-base</TableCell>
                                        <TableCell>--space-right-base</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-right-m'></Box></Box></TableCell>
                                        <TableCell>M</TableCell>
                                        <TableCell>24px</TableCell>
                                        <TableCell>3</TableCell>
                                        <TableCell>.padding-right-m</TableCell>
                                        <TableCell>.margin-right-m</TableCell>
                                        <TableCell>--space-right-m</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-right-l'></Box></Box></TableCell>
                                        <TableCell>L</TableCell>
                                        <TableCell>32px</TableCell>
                                        <TableCell>4</TableCell>
                                        <TableCell>.padding-right-l</TableCell>
                                        <TableCell>.margin-right-l</TableCell>
                                        <TableCell>--space-right-l</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-right-xl'></Box></Box></TableCell>
                                        <TableCell>XL</TableCell>
                                        <TableCell>40px</TableCell>
                                        <TableCell>5</TableCell>
                                        <TableCell>.padding-right-xl</TableCell>
                                        <TableCell>.margin-right-xl</TableCell>
                                        <TableCell>--space-right-xl</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-right-xxl'></Box></Box></TableCell>
                                        <TableCell>XXL</TableCell>
                                        <TableCell>48px</TableCell>
                                        <TableCell>6</TableCell>
                                        <TableCell>.padding-right-xxl</TableCell>
                                        <TableCell>.margin-right-xxl</TableCell>
                                        <TableCell>--space-right-xxl</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <TableContainer className='styles-table'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>MUI Spacing<br></br>Equivalence</TableCell>
                                        <TableCell>Padding Class<br></br>(MUI Prop: pb)</TableCell>
                                        <TableCell>Margin Class<br></br>(MUI Prop: mb)</TableCell>
                                        <TableCell>CSS Variable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='border-top-m'>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-none'></Box></Box></TableCell>
                                        <TableCell>None</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>.padding-bottom-none</TableCell>
                                        <TableCell>.margin-bottom-none</TableCell>
                                        <TableCell>--space-bottom-none</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-bottom-xs'></Box></Box></TableCell>
                                        <TableCell>XS</TableCell>
                                        <TableCell>4px</TableCell>
                                        <TableCell>0.5</TableCell>
                                        <TableCell>.padding-bottom-xs</TableCell>
                                        <TableCell>.margin-bottom-xs</TableCell>
                                        <TableCell>--space-bottom-xs</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-bottom-s'></Box></Box></TableCell>
                                        <TableCell>S</TableCell>
                                        <TableCell>8px</TableCell>
                                        <TableCell>1</TableCell>
                                        <TableCell>.padding-bottom-s</TableCell>
                                        <TableCell>.margin-bottom-s</TableCell>
                                        <TableCell>--space-bottom-s</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-bottom-base'></Box></Box></TableCell>
                                        <TableCell>Base</TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>.padding-bottom-base</TableCell>
                                        <TableCell>.margin-bottom-base</TableCell>
                                        <TableCell>--space-bottom-base</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-bottom-m'></Box></Box></TableCell>
                                        <TableCell>M</TableCell>
                                        <TableCell>24px</TableCell>
                                        <TableCell>3</TableCell>
                                        <TableCell>.padding-bottom-m</TableCell>
                                        <TableCell>.margin-bottom-m</TableCell>
                                        <TableCell>--space-bottom-m</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-bottom-l'></Box></Box></TableCell>
                                        <TableCell>L</TableCell>
                                        <TableCell>32px</TableCell>
                                        <TableCell>4</TableCell>
                                        <TableCell>.padding-bottom-l</TableCell>
                                        <TableCell>.margin-bottom-l</TableCell>
                                        <TableCell>--space-bottom-l</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-bottom-xl'></Box></Box></TableCell>
                                        <TableCell>XL</TableCell>
                                        <TableCell>40px</TableCell>
                                        <TableCell>5</TableCell>
                                        <TableCell>.padding-bottom-xl</TableCell>
                                        <TableCell>.margin-bottom-xl</TableCell>
                                        <TableCell>--space-bottom-xl</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-bottom-xxl'></Box></Box></TableCell>
                                        <TableCell>XXL</TableCell>
                                        <TableCell>48px</TableCell>
                                        <TableCell>6</TableCell>
                                        <TableCell>.padding-bottom-xxl</TableCell>
                                        <TableCell>.margin-bottom-xxl</TableCell>
                                        <TableCell>--space-bottom-xxl</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                          <TableContainer className='styles-table'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>MUI Spacing<br></br>Equivalence</TableCell>
                                        <TableCell>Padding Class<br></br>(MUI Prop: pl)</TableCell>
                                        <TableCell>Margin Class<br></br>(MUI Prop: ml)</TableCell>
                                        <TableCell>CSS Variable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='border-top-m'>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-none'></Box></Box></TableCell>
                                        <TableCell>None</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>.padding-left-none</TableCell>
                                        <TableCell>.margin-left-none</TableCell>
                                        <TableCell>--space-left-none</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-left-xs'></Box></Box></TableCell>
                                        <TableCell>XS</TableCell>
                                        <TableCell>4px</TableCell>
                                        <TableCell>0.5</TableCell>
                                        <TableCell>.padding-left-xs</TableCell>
                                        <TableCell>.margin-left-xs</TableCell>
                                        <TableCell>--space-left-xs</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-left-s'></Box></Box></TableCell>
                                        <TableCell>S</TableCell>
                                        <TableCell>8px</TableCell>
                                        <TableCell>1</TableCell>
                                        <TableCell>.padding-left-s</TableCell>
                                        <TableCell>.margin-left-s</TableCell>
                                        <TableCell>--space-left-s</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-left-base'></Box></Box></TableCell>
                                        <TableCell>Base</TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>.padding-left-base</TableCell>
                                        <TableCell>.margin-left-base</TableCell>
                                        <TableCell>--space-left-base</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-left-m'></Box></Box></TableCell>
                                        <TableCell>M</TableCell>
                                        <TableCell>24px</TableCell>
                                        <TableCell>3</TableCell>
                                        <TableCell>.padding-left-m</TableCell>
                                        <TableCell>.margin-left-m</TableCell>
                                        <TableCell>--space-left-m</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-left-l'></Box></Box></TableCell>
                                        <TableCell>L</TableCell>
                                        <TableCell>32px</TableCell>
                                        <TableCell>4</TableCell>
                                        <TableCell>.padding-left-l</TableCell>
                                        <TableCell>.margin-left-l</TableCell>
                                        <TableCell>--space-left-l</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-left-xl'></Box></Box></TableCell>
                                        <TableCell>XL</TableCell>
                                        <TableCell>40px</TableCell>
                                        <TableCell>5</TableCell>
                                        <TableCell>.padding-left-xl</TableCell>
                                        <TableCell>.margin-left-xl</TableCell>
                                        <TableCell>--space-left-xl</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-left-xxl'></Box></Box></TableCell>
                                        <TableCell>XXL</TableCell>
                                        <TableCell>48px</TableCell>
                                        <TableCell>6</TableCell>
                                        <TableCell>.padding-left-xxl</TableCell>
                                        <TableCell>.margin-left-xxl</TableCell>
                                        <TableCell>--space-left-xxl</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                          <TableContainer className='styles-table'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>MUI Spacing<br></br>Equivalence</TableCell>
                                        <TableCell>Padding Class<br></br>(MUI Prop: py)</TableCell>
                                        <TableCell>Margin Class<br></br>(MUI Prop: my)</TableCell>
                                        <TableCell>CSS Variable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='border-top-m'>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-none'></Box></Box></TableCell>
                                        <TableCell>None</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>.padding-y-none</TableCell>
                                        <TableCell>.margin-y-none</TableCell>
                                        <TableCell>--space-y-none</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-y-xs'></Box></Box></TableCell>
                                        <TableCell>XS</TableCell>
                                        <TableCell>4px</TableCell>
                                        <TableCell>0.5</TableCell>
                                        <TableCell>.padding-y-xs</TableCell>
                                        <TableCell>.margin-y-xs</TableCell>
                                        <TableCell>--space-y-xs</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-y-s'></Box></Box></TableCell>
                                        <TableCell>S</TableCell>
                                        <TableCell>8px</TableCell>
                                        <TableCell>1</TableCell>
                                        <TableCell>.padding-y-s</TableCell>
                                        <TableCell>.margin-y-s</TableCell>
                                        <TableCell>--space-y-s</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-y-base'></Box></Box></TableCell>
                                        <TableCell>Base</TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>.padding-y-base</TableCell>
                                        <TableCell>.margin-y-base</TableCell>
                                        <TableCell>--space-y-base</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-y-m'></Box></Box></TableCell>
                                        <TableCell>M</TableCell>
                                        <TableCell>24px</TableCell>
                                        <TableCell>3</TableCell>
                                        <TableCell>.padding-y-m</TableCell>
                                        <TableCell>.margin-y-m</TableCell>
                                        <TableCell>--space-y-m</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-y-l'></Box></Box></TableCell>
                                        <TableCell>L</TableCell>
                                        <TableCell>32px</TableCell>
                                        <TableCell>4</TableCell>
                                        <TableCell>.padding-y-l</TableCell>
                                        <TableCell>.margin-y-l</TableCell>
                                        <TableCell>--space-y-l</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-y-xl'></Box></Box></TableCell>
                                        <TableCell>XL</TableCell>
                                        <TableCell>40px</TableCell>
                                        <TableCell>5</TableCell>
                                        <TableCell>.padding-y-xl</TableCell>
                                        <TableCell>.margin-y-xl</TableCell>
                                        <TableCell>--space-y-xl</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-y-xxl'></Box></Box></TableCell>
                                        <TableCell>XXL</TableCell>
                                        <TableCell>48px</TableCell>
                                        <TableCell>6</TableCell>
                                        <TableCell>.padding-y-xxl</TableCell>
                                        <TableCell>.margin-y-xxl</TableCell>
                                        <TableCell>--space-y-xxl</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                          <TableContainer className='styles-table'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>MUI Spacing<br></br>Equivalence</TableCell>
                                        <TableCell>Padding Class<br></br>(MUI Prop: px)</TableCell>
                                        <TableCell>Margin Class<br></br>(MUI Prop: px)</TableCell>
                                        <TableCell>CSS Variable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='border-top-m'>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-none'></Box></Box></TableCell>
                                        <TableCell>None</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>0</TableCell>
                                        <TableCell>.padding-x-none</TableCell>
                                        <TableCell>.margin-x-none</TableCell>
                                        <TableCell>--space-x-none</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-x-xs'></Box></Box></TableCell>
                                        <TableCell>XS</TableCell>
                                        <TableCell>4px</TableCell>
                                        <TableCell>0.5</TableCell>
                                        <TableCell>.padding-x-xs</TableCell>
                                        <TableCell>.margin-x-xs</TableCell>
                                        <TableCell>--space-x-xs</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-x-s'></Box></Box></TableCell>
                                        <TableCell>S</TableCell>
                                        <TableCell>8px</TableCell>
                                        <TableCell>1</TableCell>
                                        <TableCell>.padding-x-s</TableCell>
                                        <TableCell>.margin-x-s</TableCell>
                                        <TableCell>--space-x-s</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-x-base'></Box></Box></TableCell>
                                        <TableCell>Base</TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>.padding-x-base</TableCell>
                                        <TableCell>.margin-x-base</TableCell>
                                        <TableCell>--space-x-base</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-x-m'></Box></Box></TableCell>
                                        <TableCell>M</TableCell>
                                        <TableCell>24px</TableCell>
                                        <TableCell>3</TableCell>
                                        <TableCell>.padding-x-m</TableCell>
                                        <TableCell>.margin-x-m</TableCell>
                                        <TableCell>--space-x-m</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-x-l'></Box></Box></TableCell>
                                        <TableCell>L</TableCell>
                                        <TableCell>32px</TableCell>
                                        <TableCell>4</TableCell>
                                        <TableCell>.padding-x-l</TableCell>
                                        <TableCell>.margin-x-l</TableCell>
                                        <TableCell>--space-x-l</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-x-xl'></Box></Box></TableCell>
                                        <TableCell>XL</TableCell>
                                        <TableCell>40px</TableCell>
                                        <TableCell>5</TableCell>
                                        <TableCell>.padding-x-xl</TableCell>
                                        <TableCell>.margin-x-xl</TableCell>
                                        <TableCell>--space-x-xl</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='preview-background'><Box className='preview-box square--small margin-x-xxl'></Box></Box></TableCell>
                                        <TableCell>XXL</TableCell>
                                        <TableCell>48px</TableCell>
                                        <TableCell>6</TableCell>
                                        <TableCell>.padding-x-xxl</TableCell>
                                        <TableCell>.margin-x-xxl</TableCell>
                                        <TableCell>--space-x-xxl</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                      </Box>
                    </PreviewComponent>
                               
                <PreviewComponent title="Shadows" description="Reference scale for the elevation levels between UI elements on an interface.">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>CSS Variable</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-box square no-background shadow-xs'></Box></TableCell>
                                    <TableCell>XS</TableCell>
                                    <TableCell>--shadow-xs</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-box square no-background shadow-s'></Box></TableCell>
                                    <TableCell>S</TableCell>
                                    <TableCell>--shadow-s</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-box square no-background shadow-m'></Box></TableCell>
                                    <TableCell>M</TableCell>
                                    <TableCell>--shadow-m</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-box square no-background shadow-l'></Box></TableCell>
                                    <TableCell>L</TableCell>
                                    <TableCell>--shadow-l</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-box square no-background shadow-xl'></Box></TableCell>
                                    <TableCell>XL</TableCell>
                                    <TableCell>--shadow-xl</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </PreviewComponent>

                <PreviewComponent title="Typography" description="Typography reference for the definition, organization and hierarchy of the content."></PreviewComponent>

                  <PreviewComponent subtitle="Font Size" description="The type scale features a combination of 11 styles for font size including Display, Headings and Body text.
                    Display is intended for use at large sizes. Headings are reserved for short and important text or numerals.
                    Body is used for long-form writing, Body Small for the general UI elements, and Body Extra Small or Extra Extra Small for accessory information.">
                    <Box>
                        <Box>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label="Desktop" {...a11yProps(0)} />
                                <Tab label="Tablet" {...a11yProps(1)} />
                                <Tab label="Phone" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                          <TableContainer className='styles-table'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Class</TableCell>
                                        <TableCell>CSS Variable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='border-top-m'>
                                        <TableCell><Box className='font-size-display'>Display</Box></TableCell>
                                        <TableCell>36px</TableCell>
                                        <TableCell>.font-size-display <br></br>(adds Line Height of 46px)</TableCell>
                                        <TableCell>--font-size-display</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h1'>Heading1</Box></TableCell>
                                        <TableCell>32px</TableCell>
                                        <TableCell>.font-size-h1 <br></br>(adds Line Height of 40px)</TableCell>
                                        <TableCell>--font-size-h1</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h2'>Heading2</Box></TableCell>
                                        <TableCell>28px</TableCell>
                                        <TableCell>.font-size-h2 <br></br>(adds Line Height of 36px)</TableCell>
                                        <TableCell>--font-size-h2</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h3'>Heading3</Box></TableCell>
                                        <TableCell>26px</TableCell>
                                        <TableCell>.font-size-h3 <br></br>(adds Line Height of 32px)</TableCell>
                                        <TableCell>--font-size-h3</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h4'>Heading4</Box></TableCell>
                                        <TableCell>22px</TableCell>
                                        <TableCell>.font-size-h4 <br></br>(adds Line Height of 28px)</TableCell>
                                        <TableCell>--font-size-h4</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h5'>Heading5</Box></TableCell>
                                        <TableCell>20px</TableCell>
                                        <TableCell>.font-size-h5 <br></br>(adds Line Height of 26px)</TableCell>
                                        <TableCell>--font-size-h5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h6'>Heading6</Box></TableCell>
                                        <TableCell>18px</TableCell>
                                        <TableCell>.font-size-h6 <br></br>(adds Line Height of 22px)</TableCell>
                                        <TableCell>--font-size-h6</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-base'>Body</Box></TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>.font-size-base <br></br>(adds Line Height of 24px)</TableCell>
                                        <TableCell>--font-size-base</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-s'>Body Small</Box></TableCell>
                                        <TableCell>14px</TableCell>
                                        <TableCell>.font-size-s <br></br>(adds Line Height of 22px)</TableCell>
                                        <TableCell>--font-size-s</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-xs'>Body Extra Small</Box></TableCell>
                                        <TableCell>12px</TableCell>
                                        <TableCell>.font-size-xs <br></br>(adds Line Height of 18px)</TableCell>
                                        <TableCell>--font-size-xs</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-xxs'>Body Extra Extra Small</Box></TableCell>
                                        <TableCell>10px</TableCell>
                                        <TableCell>.font-size-xxs <br></br>(adds Line Height of 14px)</TableCell>
                                        <TableCell>--font-size-xxs</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <TableContainer className='styles-table'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Class</TableCell>
                                        <TableCell>CSS Variable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className='tablet'>
                                    <TableRow className='border-top-m'>
                                        <TableCell><Box className='font-size-display'>Display</Box></TableCell>
                                        <TableCell>34px</TableCell>
                                        <TableCell>.font-size-display <br></br>(adds Line Height of 40px)</TableCell>
                                        <TableCell>--font-size-display</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h1'>Heading1</Box></TableCell>
                                        <TableCell>30px</TableCell>
                                        <TableCell>.font-size-h1 <br></br>(adds Line Height of 34px)</TableCell>
                                        <TableCell>--font-size-h1</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h2'>Heading2</Box></TableCell>
                                        <TableCell>26px</TableCell>
                                        <TableCell>.font-size-h2 <br></br>(adds Line Height of 28px)</TableCell>
                                        <TableCell>--font-size-h2</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h3'>Heading3</Box></TableCell>
                                        <TableCell>24px</TableCell>
                                        <TableCell>.font-size-h3 <br></br>(adds Line Height of 24px)</TableCell>
                                        <TableCell>--font-size-h3</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h4'>Heading4</Box></TableCell>
                                        <TableCell>20px</TableCell>
                                        <TableCell>.font-size-h4 <br></br>(adds Line Height of 22px)</TableCell>
                                        <TableCell>--font-size-h4</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h5'>Heading5</Box></TableCell>
                                        <TableCell>18px</TableCell>
                                        <TableCell>.font-size-h5 <br></br>(adds Line Height of 20px)</TableCell>
                                        <TableCell>--font-size-h5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h6'>Heading6</Box></TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>.font-size-h6 <br></br>(adds Line Height of 18px)</TableCell>
                                        <TableCell>--font-size-h6</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-base'>Body</Box></TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>.font-size-base <br></br>(adds Line Height of 24px)</TableCell>
                                        <TableCell>--font-size-base</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-s'>Body Small</Box></TableCell>
                                        <TableCell>14px</TableCell>
                                        <TableCell>.font-size-s <br></br>(adds Line Height of 22px)</TableCell>
                                        <TableCell>--font-size-s</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-xs'>Body Extra Small</Box></TableCell>
                                        <TableCell>12px</TableCell>
                                        <TableCell>.font-size-xs <br></br>(adds Line Height of 18px)</TableCell>
                                        <TableCell>--font-size-xs</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-xxs'>Body Extra Extra Small</Box></TableCell>
                                        <TableCell>10px</TableCell>
                                        <TableCell>.font-size-xxs <br></br>(adds Line Height of 14px)</TableCell>
                                        <TableCell>--font-size-xxs</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <TableContainer className='styles-table'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Class</TableCell>
                                        <TableCell>CSS Variable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className='phone'>
                                    <TableRow className='border-top-m'>
                                        <TableCell><Box className='font-size-display'>Display</Box></TableCell>
                                        <TableCell>32px</TableCell>
                                        <TableCell>.font-size-display <br></br>(adds Line Height of 40px)</TableCell>
                                        <TableCell>--font-size-display</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h1'>Heading1</Box></TableCell>
                                        <TableCell>28px</TableCell>
                                        <TableCell>.font-size-h1 <br></br>(adds Line Height of 34px)</TableCell>
                                        <TableCell>--font-size-h1</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h2'>Heading2</Box></TableCell>
                                        <TableCell>24px</TableCell>
                                        <TableCell>.font-size-h2 <br></br>(adds Line Height of 28px)</TableCell>
                                        <TableCell>--font-size-h2</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h3'>Heading3</Box></TableCell>
                                        <TableCell>22px</TableCell>
                                        <TableCell>.font-size-h3 <br></br>(adds Line Height of 24px)</TableCell>
                                        <TableCell>--font-size-h3</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h4'>Heading4</Box></TableCell>
                                        <TableCell>20px</TableCell>
                                        <TableCell>.font-size-h4 <br></br>(adds Line Height of 22px)</TableCell>
                                        <TableCell>--font-size-h4</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h5'>Heading5</Box></TableCell>
                                        <TableCell>18px</TableCell>
                                        <TableCell>.font-size-h5 <br></br>(adds Line Height of 20px)</TableCell>
                                        <TableCell>--font-size-h5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-h6'>Heading6</Box></TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>.font-size-h6 <br></br>(adds Line Height of 18px)</TableCell>
                                        <TableCell>--font-size-h6</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-base'>Body</Box></TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>.font-size-base <br></br>(adds Line Height of 24px)</TableCell>
                                        <TableCell>--font-size-base</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-s'>Body Small</Box></TableCell>
                                        <TableCell>14px</TableCell>
                                        <TableCell>.font-size-s <br></br>(adds Line Height of 22px)</TableCell>
                                        <TableCell>--font-size-s</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-xs'>Body Extra Small</Box></TableCell>
                                        <TableCell>12px</TableCell>
                                        <TableCell>.font-size-xs <br></br>(adds Line Height of 18px)</TableCell>
                                        <TableCell>--font-size-xs</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Box className='font-size-xxs'>Body Extra Extra Small</Box></TableCell>
                                        <TableCell>10px</TableCell>
                                        <TableCell>.font-size-xxs <br></br>(adds Line Height of 14px)</TableCell>
                                        <TableCell>--font-size-xxs</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                    </Box>
                    <p className='font-size-base'>Examples of Font Size usage:</p>
                    <CodeBlock code={sampleTypographySize} />
                  </PreviewComponent>

                  <PreviewComponent subtitle="Headings" description="Use these semantic HTML tags, for specifically displaying Headings, which 
                  describe the purpose of the element and the meaning of content they have inside.">
                    <Box>
                        <Box>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label="Desktop" {...a11yProps(0)} />
                                <Tab label="Tablet" {...a11yProps(1)} />
                                <Tab label="Phone" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                          <TableContainer className='styles-table'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>HTML Tag</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='border-top-m'>
                                        <TableCell><h1>Heading1</h1></TableCell>
                                        <TableCell>32px</TableCell>
                                        <TableCell>&lt;h1&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><h2>Heading2</h2></TableCell>
                                        <TableCell>28px</TableCell>
                                        <TableCell>&lt;h2&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><h3>Heading3</h3></TableCell>
                                        <TableCell>26px</TableCell>
                                        <TableCell>&lt;h3&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><h4>Heading4</h4></TableCell>
                                        <TableCell>22px</TableCell>
                                        <TableCell>&lt;h4&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><h5>Heading5</h5></TableCell>
                                        <TableCell>20px</TableCell>
                                        <TableCell>&lt;h5&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><h6>Heading6</h6></TableCell>
                                        <TableCell>18px</TableCell>
                                        <TableCell>&lt;h6&gt;</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <TableContainer className='styles-table'>
                            <Table>
                            <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>HTML Tag</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='border-top-m'>
                                        <TableCell className='tablet'><h1>Heading1</h1></TableCell>
                                        <TableCell>30px</TableCell>
                                        <TableCell>&lt;h1&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='tablet'><h2>Heading2</h2></TableCell>
                                        <TableCell>26px</TableCell>
                                        <TableCell>&lt;h2&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='tablet'><h3>Heading3</h3></TableCell>
                                        <TableCell>24px</TableCell>
                                        <TableCell>&lt;h3&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='tablet'><h4>Heading4</h4></TableCell>
                                        <TableCell>20px</TableCell>
                                        <TableCell>&lt;h4&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='tablet'><h5>Heading5</h5></TableCell>
                                        <TableCell>18px</TableCell>
                                        <TableCell>&lt;h5&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='tablet'><h6>Heading6</h6></TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>&lt;h6&gt;</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <TableContainer className='styles-table'>
                            <Table>
                            <TableHead>
                                    <TableRow>
                                        <TableCell>Example</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>HTML Tag</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className='border-top-m'>
                                        <TableCell className='phone'><h1>Heading1</h1></TableCell>
                                        <TableCell>28px</TableCell>
                                        <TableCell>&lt;h1&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='phone'><h2>Heading2</h2></TableCell>
                                        <TableCell>24px</TableCell>
                                        <TableCell>&lt;h2&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='phone'><h3>Heading3</h3></TableCell>
                                        <TableCell>22px</TableCell>
                                        <TableCell>&lt;h3&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='phone'><h4>Heading4</h4></TableCell>
                                        <TableCell>20px</TableCell>
                                        <TableCell>&lt;h4&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='phone'><h5>Heading5</h5></TableCell>
                                        <TableCell>18px</TableCell>
                                        <TableCell>&lt;h5&gt;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='phone'><h6>Heading6</h6></TableCell>
                                        <TableCell>16px</TableCell>
                                        <TableCell>&lt;h6&gt;</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                    </Box>
                    <p className='font-size-base'>Example of Headings usage:</p>
                    <CodeBlock code={sampleTypographyHeadings} /> 
                  </PreviewComponent>


                  <PreviewComponent subtitle="Font Weight">               
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Weight</TableCell>
                                    <TableCell>Class</TableCell>
                                    <TableCell>CSS Variable</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='font-light font-size-h1'>Aa</Box></TableCell>
                                    <TableCell>Light</TableCell>
                                    <TableCell>300</TableCell>
                                    <TableCell>.font-light</TableCell>
                                    <TableCell>--font-light</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='font-regular font-size-h1'>Aa</Box></TableCell>
                                    <TableCell>Regular</TableCell>
                                    <TableCell>400</TableCell>
                                    <TableCell>.font-regular</TableCell>
                                    <TableCell>--font-regular</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='font-semi-bold font-size-h1'>Aa</Box></TableCell>
                                    <TableCell>Semi-bold</TableCell>
                                    <TableCell>600</TableCell>
                                    <TableCell>.font-semi-bold</TableCell>
                                    <TableCell>--font-semi-bold</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='font-bold font-size-h1'>Aa</Box></TableCell>
                                    <TableCell>Bold</TableCell>
                                    <TableCell>700</TableCell>
                                    <TableCell>.font-bold</TableCell>
                                    <TableCell>--font-bold</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="Text Transform">   
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Class</TableCell>
                                    <TableCell>Additional Info</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='text-lowercase'>Lowercased text</Box></TableCell>
                                    <TableCell>Lowercase</TableCell>
                                    <TableCell>.text-lowercase</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='text-uppercase'>uppercased text</Box></TableCell>
                                    <TableCell>Uppercase</TableCell>
                                    <TableCell>.text-uppercase</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='text-capitalize'>capitalized text</Box></TableCell>
                                    <TableCell>Capitalize</TableCell>
                                    <TableCell>.text-capitalize</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='text-ellipsis'>Ellipsis...</Box></TableCell>
                                    <TableCell>Ellipsis</TableCell>
                                    <TableCell>.text-ellipsis</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box></Box></TableCell>
                                    <TableCell>Wcag Hide Text</TableCell>
                                    <TableCell>.wcag-hide-text</TableCell>
                                    <TableCell>Use this class to show an element to a screen reader and hide it visually on the screen</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box></Box></TableCell>
                                    <TableCell>Break Word</TableCell>
                                    <TableCell>.break-word</TableCell>
                                    <TableCell>Use this class to force the parent element to wrap the child's content if it starts to become smaller than the space available for it</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                <PreviewComponent title="Utility Classes" description="Reference to the utilities styles that can be applied to any UI element."></PreviewComponent> 
                
                <PreviewComponent subtitle="Images">
                  <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell>                                   
                                    <TableCell>Additional Info</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><ImageList><ImageListItem><img className='img-thumbnail' alt='profile pic' src='/images/profile-squared.jpg'/></ImageListItem></ImageList></TableCell>
                                    <TableCell>.img-thumbnail</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><ImageList><ImageListItem><img className='img-rounded' alt='profile pic' src='/images/profile-squared.jpg'/></ImageListItem></ImageList></TableCell>
                                    <TableCell>.img-rounded</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><ImageList><ImageListItem><img className='img-circle' alt='profile pic' src='/images/profile-squared.jpg'/></ImageListItem></ImageList></TableCell>
                                    <TableCell>.img-circle</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><ImageList><ImageListItem><img className='img-cover' alt='profile pic' src='/images/profile-squared.jpg'/></ImageListItem></ImageList></TableCell>
                                    <TableCell>.img-cover</TableCell>
                                    <TableCell>Covers the full height of its parent element</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="Text Align" description="Use these classes to align text inside a container.">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell>                                   
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-display--small text-align-left padding-base'>Text Align Left</Box></TableCell>
                                    <TableCell>.text-align-left</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display--small text-align-center padding-base'>Text Align Center</Box></TableCell>
                                    <TableCell>.text-align-center</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display--small text-align-right padding-base'>Text Align Right</Box></TableCell>
                                    <TableCell>.text-align-right</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="DIV Spacing" description="Use these classes to adjust width or height to 100% of containers.">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell>   
                                    <TableCell>Additional Info</TableCell>                                 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell className='height-100'><Box className='width-300 full-height background-neutral-3 padding-base'>When Chuck Norris sends in his taxes, he sends blank forms and includes only a picture of himself, crouched and ready to attack.</Box></TableCell>
                                    <TableCell>.full-height</TableCell>
                                    <TableCell>When your element is nested inside another element, the browser will use the parent element's height to calculate a value for 100%. 
                                    So, if the element is inside another element that has a height of 100px, as in this case, and you set the child element's class to .full-height (height: 100%), it will have 100px high.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='width-500'><Box className='full-width background-neutral-3 padding-base'>This container uses full width</Box></TableCell>
                                    <TableCell>.full-width</TableCell>
                                    <TableCell>When your element is nested inside another element, the browser will use the parent element's width to calculate a value for 100%. 
                                    So, if the element is inside another element that has a width of 500px, as in this case, and you set the child element's class to .full-width (width: 100%), it will have 500px width.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="Line Height" description="">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell> 
                                    <TableCell>Additional Info</TableCell>                                  
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-display--small line-height-reset padding-s'>This text uses line height reset.
                                    This text uses line height reset.</Box></TableCell>
                                    <TableCell>.line-height-reset</TableCell>
                                    <TableCell>Use this class to set line height to 1. The default value is set in the in the body with the value of 1.5</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="Overflow" description="Use these classes to apply overflow properties to the containers.">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell>                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-display overflow-hidden padding-m'>This container has 100px of height. If there is more text inside it will be hidden.<br></br>
                                    When the Boogeyman goes to sleep every night, he checks his closet for Chuck Norris. Guns don't kill people. Chuck Norris kills people.</Box></TableCell>
                                    <TableCell>.overflow-hidden</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display overflow-auto padding-m'>This container has 100px of height. If there is more text inside it will have a scrollbar.<br></br>
                                    When the Boogeyman goes to sleep every night, he checks his closet for Chuck Norris. Guns don't kill people. Chuck Norris kills people.</Box></TableCell>
                                    <TableCell className='padding-base'>.overflow-auto</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display hide-scrollbar overflow-auto padding-m'>This container has 100px of height. If there is more text inside, the scrollbar is hidden but you can still scroll.<br></br>
                                    When the Boogeyman goes to sleep every night, he checks his closet for Chuck Norris. Guns don't kill people. Chuck Norris kills people.</Box></TableCell>
                                    <TableCell className='padding-base'>.hide-scrollbar</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="White Space" description="Use these classes to apply white-space properties to the containers."> 
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell>                                   
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-display--small white-space-preline padding-s'>Text will wrap when necessary, and on line breaks.</Box></TableCell>
                                    <TableCell>.white-space-preline</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display--small white-space-nowrap padding-base'>Text will never wrap to the next line. 
                                    The text continues on the same line until a {"<br>"} tag is encountered.</Box></TableCell>
                                    <TableCell>.white-space-nowrap</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="Positioning" description="To specify an element's position, use these classes.">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell> 
                                    <TableCell>Additional Info</TableCell>                                
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-display'><span>I am Relative</span></Box></TableCell>
                                    <TableCell>.position-relative</TableCell> 
                                    <TableCell>Relative position limits the scope of absolutely positioned child elements. 
                                    This means that any element that is the child of this element can be absolutely positioned within this block.</TableCell>                                    
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display'><div className='btn position-absolute'>Absolute</div><span>I am Relative</span></Box></TableCell>
                                    <TableCell>.position-absolute</TableCell> 
                                    <TableCell>In the example, the parent element has the position set to relative. 
                                    Now, when you set the position of the child element to absolute, any additional positioning will be done relative to the parent element.</TableCell>                                    
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='height-150 overflow-vertical preview-display'><div className='btn sticky'>Sticky</div>
                                    <div className='height-100'></div><div className='height-100'></div><div className='height-100'></div></Box></TableCell>
                                    <TableCell>.sticky</TableCell> 
                                    <TableCell>The element will scroll along with its container, 
                                    until it is at the top of the container or reaches the offset specified in top.</TableCell>                                    
                                </TableRow>
                                <TableRow>
                                <TableCell><Box className='height-150 overflow-vertical preview-display'><div className='btn fixed'>Fixed</div>
                                    <div className='height-100'></div><div className='height-100'></div><div className='height-100'></div></Box></TableCell>
                                    <TableCell>.fixed</TableCell> 
                                    <TableCell>The element is positioned relative to the viewport, 
                                    which means it always stays in the same place even if the page is scrolled.</TableCell>                                    
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="Absolute Positioning" description="Use these classes to give to the child element an absolute position. For this to work the parent element must have a relative position!">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell> 
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell> 
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell>                                 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-top square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-top</TableCell>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-right square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-right</TableCell>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-bottom square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-bottom</TableCell>                                          
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-left square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-left</TableCell>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-top-right square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-top-right</TableCell>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-top-left square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-top-left</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-bottom-right square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-bottom-right</TableCell>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-bottom-left square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-bottom-left</TableCell>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-center square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-center</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-center-top square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-center-top</TableCell>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-center-right square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-center-right</TableCell>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-center-bottom square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-center-bottom</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display--square'><div className='preview-box position-absolute absolute-center-left square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-center-left</TableCell>
                                    <TableCell><Box className='preview-display--square'><Box className='preview-display--square-header'>I am a Header</Box><div className='preview-box position-absolute absolute-top-plus-header square--small border-size-m'></div></Box></TableCell>
                                    <TableCell>.absolute-top-plus-header</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="Display" description="Use these classes to change how components are shown.">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell>
                                    <TableCell>Class</TableCell>  
                                    <TableCell>Additional Info</TableCell>                                  
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-display padding-base'><div className='background-neutral-5'>This is the first sentence</div><div className='visibility-hidden'>This is the second sentence</div>
                                    <div className='background-neutral-5'>This is the third sentence</div></Box></TableCell>
                                    <TableCell>.visibility-hidden</TableCell>
                                    <TableCell>The second sentence is hidden, but still takes up space on the page, is accessible to JavaScript but not to assistive technology.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='preview-display padding-base'><div className='background-neutral-5'>This is the first sentence</div><div className='hidden'>This is the second sentence</div>
                                    <div className='background-neutral-5'>This is the third sentence</div></Box></TableCell>
                                    <TableCell>.hidden or .ph</TableCell>
                                    <TableCell>The second sentence is hidden and does not take up space on the page nor is accessible to assistive technology or JavaScript.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="Width" description="Use these classes to define the width of an element.">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example / Class</TableCell>                            
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='full-width-vw background-neutral-3 padding-base'>.full-width-vw (I take up the entire window width)</Box></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Box className='width-300 background-neutral-1'>
                                        <Box className='half-width background-neutral-3 padding-base'>.half-width (I take up half of an element width)</Box></Box></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>   
                                <TableRow>
                                    <TableCell><Box className='full-width-vw background-neutral-1'>
                                        <Box className='half-width-vw background-neutral-3 padding-base'>.half-width-vw (I take up half of the window's width)</Box></Box></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>      
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>

                  <PreviewComponent subtitle="Height" description="To specify an element's height, use these classes.">
                    <TableContainer className='styles-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Example</TableCell> 
                                    <TableCell>Class</TableCell>   
                                    <TableCell>Example</TableCell> 
                                    <TableCell>Class</TableCell>                          
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className='border-top-m'>
                                    <TableCell><Box className='preview-display--square'>
                                        <Box className='preview-display-square-bar full-height-vh padding-s'>I take up the entire window height</Box></Box></TableCell>
                                    <TableCell>.full-height-vh</TableCell>
                                    <TableCell><Box className='preview-display--square'>
                                        <Box className='preview-display--square-bar half-height-vh padding-s'>I take up half window height</Box></Box></TableCell>
                                    <TableCell>.half-height-vh</TableCell>
                                </TableRow>   
                                <TableRow>
                                    <TableCell><Box className='preview-display--square'>
                                        <Box className='preview-display--square-bar half-height padding-s'>I take up half height of my parent</Box></Box></TableCell>
                                    <TableCell>.half-height</TableCell>
                                    <TableCell><Box className='preview-display--square'>
                                        <Box className='preview-display--square-bar auto-height padding-s'>I take up the height of the content I have inside</Box></Box></TableCell>
                                    <TableCell>.auto-height</TableCell>
                                </TableRow>   
                                <TableRow>
                                    <TableCell><Box className='preview-display--square'>
                                        <Box className='preview-display--square-header'>I am a Header</Box>
                                        <Box className='preview-display--square-bar full-height-minus-header padding-s'>I take up the entire window height minus header height</Box></Box></TableCell>
                                    <TableCell>.full-height-minus-header</TableCell>
                                </TableRow>         
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </PreviewComponent>
        </LayoutTopWrapperLSG>
    )
}