import * as React from 'react';
import LayoutSideWrapper from '../app-components/LayoutSideWrapper';
import InfoSnackbar from './Commons/InfoSnackbar';
import { Button, Card, CardContent, CardMedia, Grid, ListItemIcon, ListItemText, MenuItem, MobileStepper, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import AdsClickTwoToneIcon from '@mui/icons-material/AdsClickTwoTone';
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { BarChart } from '@mui/x-charts';
import SwipeableViews from 'react-swipeable-views';
import ActionSheet from '../theme/custom-components/action-sheet/ActionSheet';


export default function HomeScreen() {

    const isPhone = useMediaQuery('(max-width:620px)');
    const isNotDesktop = useMediaQuery('(max-width:820px)');

//ACTION SHEETS

const [openActionSheet, setOpenActionSheet] = React.useState(false);

const toggleActionSheet = (newOpen) => () => {
  setOpenActionSheet(newOpen);
}

 // CARD POPOVER
 const [anchorEl, setAnchorEl] = React.useState(null);

 const handleClick = (event) => {
   setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
   setAnchorEl(null);
 };

 const open = Boolean(anchorEl);

//MINI CARDS LOGIC

    const [activeStep, setActiveStep] = React.useState(0);

      const handleStepChange = (step) => {
        setActiveStep(step);
      };

    const miniCardsContent = [

        <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
            <PersonOutlineRoundedIcon className="font-size-h1 text-neutral-7" />
            <Stack direction="column" className="margin-left-m">
            <div className="font-size-display">178</div>
            <div className="text-neutral-7">Soft booking</div>
            </Stack>
        </Stack>
        <EastRoundedIcon className="font-size-h3 text-primary" />
        </Stack>,
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
            <AssignmentOutlinedIcon className="font-size-h1 text-neutral-7" />
            <Stack direction="column" className="margin-left-m">
                <div className="font-size-display">365</div>
                <div className="text-neutral-7">Hard booking</div>
            </Stack>
            </Stack>
            <EastRoundedIcon className="font-size-h3 text-primary" />
        </Stack>,
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
                <RepeatRoundedIcon className="font-size-h1 text-neutral-7" />
                <Stack direction="column" className="margin-left-m">
                <div className="font-size-display">29</div>
                <div className="text-neutral-7">Ongoing</div>
                </Stack>
            </Stack>
            <EastRoundedIcon className="font-size-h3 text-primary" />
            </Stack>,
            <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
                <FormatListBulletedRoundedIcon className="font-size-h1 text-neutral-7" />
                <Stack direction="column" className="margin-left-m">
                <div className="font-size-display">1953</div>
                <div className="text-neutral-7">All</div>
                </Stack>
            </Stack>
            <EastRoundedIcon className="font-size-h3 text-primary" />
            </Stack>
 ];

    const cardPairs = [];
    for (let i = 0; i < miniCardsContent.length; i += 2) {
    cardPairs.push(
        <Grid container spacing={2} key={i} className='margin-bottom-xl'>
            <Grid item sm={6}>
                <Card>
                <CardContent>
                    {miniCardsContent[i]}
                </CardContent>
                </Card>
            </Grid>
        {i + 1 < miniCardsContent.length && (
            <Grid item sm={6}>
                <Card>
                    <CardContent>
                    {miniCardsContent[i + 1]}
                    </CardContent>
                </Card>
            </Grid>
        )}
        </Grid>
    );
    }

 //OTHER CARDS LOGIC

const [activeStepCard, setActiveStepCard] = React.useState(0);

const handleStepCardChange = (step) => {
    setActiveStepCard(step);
};

 const concerningContent = [
    <>
        <AdjustRoundedIcon className='text-neutral-5'/>
        <h4 className='margin-top-m'> Select date</h4>
        <div className='text-align-left  margin-top-s padding-bottom-s'>
            <span className='text-neutral-7'>To start the action, click on the day or days in the calendar</span>
        </div>
    </>, 
    <>
        <PanToolAltOutlinedIcon className='text-neutral-5'/>
        <h4 className='margin-top-m'> Submit</h4>
        <div className='text-align-left margin-top-s padding-bottom-s'>
            <span className='text-neutral-7'>In the bottom right corner, click the submit button.</span>
        </div>
    </>,
    <>
        <AdsClickTwoToneIcon className='text-neutral-5'/>
        <h4 className='margin-top-m'> Select an action</h4>
        <div className='text-align-left  margin-top-s padding-bottom-s'>
            <span className='text-neutral-7'>Select your course of action and explain your motivation</span>
        </div>
    </>
 ];

    //SMALL TABLE

    const rows=[
        {name: "Patricia Welsey", score:8},
        {name: "Edwards Williams", score:6},
        {name: "Andrea Mccarthy", score:6},
        {name: "Ann Olivarria", score:3}
    ]

      //CHANGE VIEW

  const [view, setView] = React.useState('chart');

  const handleViewChange = () => {
    setView(view === 'table' ? 'chart' : 'table');
  };

  //SORT TABLE

  const [sortBy, setSortBy] = React.useState(null);
  const [sortDirection, setSortDirection] = React.useState('asc');

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('asc');
    }
  };

  const sortedRows = [...rows];

  if (sortBy === 'name') {
    sortedRows.sort((a, b) => {
      const order = sortDirection === 'asc' ? 1 : -1;
      return order * a.name.localeCompare(b.name);
    });
  } else if (sortBy === 'score') {
    sortedRows.sort((a, b) => {
      const order = sortDirection === 'asc' ? 1 : -1;
      return order * (a.score - b.score);
    });
  }

  // CHART

  const dataset = [
    {
      score: 8,
      name: 'Patricia Welsey',
    },
    {
      score: 6,
      name: 'Edwards Williams',
    },
    {
      score: 6,
      name: 'Andrea Mccarthy',
    },
    {
      score: 3,
      name: 'Ann Olivarria',
    },
  ];
  
  const valueFormatter = (value) => `${value}`;

      //SNACKBAR MESSAGE

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
    return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

    return(
        <LayoutSideWrapper title="Summary of recent activities">
            {openSnackbar && (
        <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />
      )}

        <ActionSheet open={openActionSheet} onClose={toggleActionSheet(false)} onCancelClick={toggleActionSheet(false)}cancelText='Cancel'>
            <Button className='full-width' onClick={handleClickSnackbar}>Favorites</Button>
            <Button className='full-width'onClick={handleClickSnackbar}>See Details</Button>
        </ActionSheet>

        <Popover
            className="popover padding-none"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            >
            <MenuItem onClick={handleClickSnackbar}>
                <ListItemIcon>
                <StarBorderRoundedIcon />
                </ListItemIcon>
                <ListItemText>Favorites</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClickSnackbar}>
                <ListItemIcon>
                <VisibilityOutlinedIcon onClick={handleClickSnackbar} />
                </ListItemIcon>
                <ListItemText>See Details</ListItemText>
            </MenuItem>
        </Popover>

            <div className="margin-bottom-m font-size-base text-neutral-7">
                    Latest update: 1 Jun. 2022
                </div>
    {/* MINI CARDS CONTENT */}
            {isPhone ? (
                <div className='margin-bottom-base'>
                    <SwipeableViews
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    >
                    {miniCardsContent.map((content, index) => (
                        <div key={index} className='card-content-wrapper'>
                        <Card>
                            <CardContent>
                            {content}
                            </CardContent>
                        </Card>
                        </div>
                    ))}
                    </SwipeableViews>
                    <Stack direction="row" justifyContent="center" className='margin-bottom-m'>
                        <MobileStepper
                        className='padding-top-base'
                        variant="dots"
                        steps={miniCardsContent.length}
                        position="static"
                        activeStep={activeStep}
                        />
                    </Stack>
                </div>
            ):( isNotDesktop ? (
                <>
                <div className='margin-bottom-base'>
                    <SwipeableViews
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                    >
                        {cardPairs.map((pair, index) => (
                        <div key={index}>{pair}</div>
                        ))}
                    </SwipeableViews>
                </div>
              </>
            ):(
            <Grid container spacing={2} className='margin-bottom-m'>
                {miniCardsContent.map((content, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Card>
                      <CardContent>
                        {content}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ))}

{/* OTHER CARD CONTENT */}
            {isPhone ? (
                <div className='margin-bottom-base'>
                <Card  >
                    <CardContent>
                        <SwipeableViews
                            index={activeStepCard}
                            onChangeIndex={handleStepCardChange}
                        >
                            {concerningContent.map((content, index) => (
                                <div key={index}>
                        
                                    <h2 className='text-align-center'>Concerning the application</h2>
                                    <div className='svg-resize text-align-center padding-top-m'>
                                    {content}
                                    </div>
                                </div>
                            ))}
                        </SwipeableViews>
                        <Stack direction="row" justifyContent="center">
                            <MobileStepper
                        className='padding-bottom-base'
                        variant="dots"
                        steps={concerningContent.length}
                        position="static"
                        activeStep={activeStepCard}
                        />
                        </Stack>
                        <div className='text-align-center margin-bottom-l'>
                            <Button className='primary' onClick={handleClickSnackbar}>Start</Button>
                        </div>
                    </CardContent>
                </Card>
              </div>

            ):( isNotDesktop ? (
                    <Card className="margin-bottom-m">
                        <CardContent>
                            <SwipeableViews
                                index={activeStepCard}
                                onChangeIndex={handleStepCardChange}
                                >
                                {concerningContent.slice(0, -1).map((content, index) => (
                                    <div key={index}>
                                    <h2 className='text-align-center'>Concerning the application</h2>
                                    <Grid container spacing={2} className='padding-top-m'>
                                        <Grid item sm={6}>
                                        <div className="svg-resize text-align-center">
                                            {concerningContent[index]}
                                        </div>
                                        </Grid>
                                        <Grid item sm={6}>
                                        <div className="svg-resize text-align-center">
                                            {concerningContent[index + 1]}
                                        </div>
                                        </Grid>
                                    </Grid>
                                    </div>
                                ))}
                            </SwipeableViews>
                            <Stack direction="row" justifyContent="center">
                                <MobileStepper
                                    className="margin-bottom-base"
                                    variant="dots"
                                    steps={concerningContent.length - 1}
                                    position="static"
                                    activeStep={activeStepCard}
                                />
                            </Stack>
                            <div className='text-align-center margin-bottom-l margin-top-m'>
                                <Button className='primary' onClick={handleClickSnackbar}>Start</Button>
                            </div>
                        </CardContent>
                    </Card>
            ):(

            <Card className='margin-bottom-m'>
                <CardContent>
                <h2 className='text-align-center'>Concerning the application</h2>
                <Grid container className='text-align-center padding-top-m margin-bottom-xxl svg-resize'>
                    <Grid item md={4}>
                        <AdjustRoundedIcon className='text-neutral-5'/>
                        <h4 className='margin-top-m'> Select date</h4>
                        <span className='text-neutral-7 margin-top-s'>To start the action, click on the day or days in the calendar</span>
                    </Grid>
                    <Grid item md={4}>
                        <PanToolAltOutlinedIcon className='text-neutral-5'/>
                        <h4 className='margin-top-m'> Submit</h4>
                        <span className='text-neutral-7 margin-top-s'>In the bottom right corner, click the submit button.</span>
                    </Grid>
                    <Grid item md={4}>
                        <AdsClickTwoToneIcon className='text-neutral-5'/>
                        <h4 className='margin-top-m'> Select an action</h4>
                        <span className='text-neutral-7 margin-top-s'>Select your course of action and explain your motivation</span>
                    </Grid>
                </Grid>
                <div className='text-align-center margin-bottom-l'>
                <Button className='primary' onClick={handleClickSnackbar}>Start</Button>
                </div>
                </CardContent>
            </Card>

            ))}

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Card className='full-height'>
                            <CardContent>
                                {isPhone ? (
                                <>
                                    <Stack direction="column" className='padding-bottom-m'>
                                        <h4>Goal-setting for the coming week</h4>
                                        <span className='text-neutral-7'>An illustration of the weekly goal.</span> 
                                    </Stack>
                                    <Button onClick={handleClickSnackbar}><span className='font-size-xs'>See manual</span></Button>
                                </>
                                ):(
                                <>
                                    <Stack direction="row" justifyContent="space-between">
                                    <h4>Goal-setting for the coming week</h4>
                                        <Button className={isNotDesktop ? '' : 'small'} onClick={handleClickSnackbar}>
                                            <span className='font-size-xs'>See manual</span>
                                        </Button>
                                    </Stack>
                                    <span className='text-neutral-7'>An illustration of the weekly goal.</span>
                                </>
                                )}
                                <CardMedia
                                    component="img"
                                    image="/images/Goal-setting-full.png"
                                    title="Goal-setting Picture"
                                    className="full-width padding-top-m"
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Card className='full-height'>
                            <CardContent>
                                <Stack direction="row" justifyContent="space-between">
                                    <h4>Team evaluation</h4>
                                    <Stack direction="row">
                                {isPhone ? (
                                    <>
                                        <Button className='btn-icon' onClick={toggleActionSheet(true)}><MoreVertRoundedIcon/></Button>
                                        <ToggleButtonGroup
                                          className='margin-left-base' 
                                            value={view}
                                            exclusive
                                            onChange={handleViewChange}
                                            >
                                            <ToggleButton className='btn-icon'>
                                                {view === 'chart' ? (
                                                <TableChartOutlinedIcon />
                                                ) : (
                                                <SsidChartOutlinedIcon />
                                                )}
                                            </ToggleButton>
                                            </ToggleButtonGroup>
                                    </>    
                                ):(  isNotDesktop ?(
                                    <>
                                            <Button className='btn-icon' onClick={toggleActionSheet(true)}><MoreVertRoundedIcon/></Button>
                                        <ToggleButtonGroup
                                            className='margin-left-base' 
                                            onChange={handleViewChange}
                                            value={view}
                                            exclusive>
                                            <ToggleButton value="table" className='btn-icon'>
                                                <TableChartOutlinedIcon/>
                                            </ToggleButton>
                                            <ToggleButton value="chart" className='btn-icon'>
                                                <SsidChartOutlinedIcon/>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </>
                                ):(
                                    <>
                                        <Button className='small btn-icon' onClick={handleClick}><MoreVertRoundedIcon/></Button>
                                        <ToggleButtonGroup
                                            className='margin-left-base' 
                                            onChange={handleViewChange}
                                            value={view}
                                            exclusive>
                                            <ToggleButton value="table" className='small btn-icon'>
                                                <TableChartOutlinedIcon/>
                                            </ToggleButton>
                                            <ToggleButton value="chart" className='small btn-icon'>
                                                <SsidChartOutlinedIcon/>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </>
                                ))}
                                        
                                    </Stack>
                                </Stack>
                                {view === 'table' ? (
                                <TableContainer className='padding-top-m'>
                                    <Table>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell
                                                onClick={() => handleSort('name')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                            <TableSortLabel
                                               active={sortBy === 'name'}
                                               direction={sortDirection}
                                            >
                                                Name
                                            </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                onClick={() => handleSort('score')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                            <TableSortLabel
                                                active={sortBy === 'score'}
                                                direction={sortDirection}
                                            >
                                                Total score
                                            </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {sortedRows.map((row) => (
                                            <TableRow key={row.name}>
                                            <TableCell>
                                                {row.name}
                                            </TableCell>
                                            <TableCell>{row.score}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                ):(
                                    //CHART VIEW
                                <div>
                                    <BarChart
                                    height={300}
                                    margin={{ top: 40, left: 110 }}
                                    dataset={dataset}
                                    series={[
                                        {
                                        dataKey: 'score',
                                        valueFormatter,
                                        color: 'var(--color-primary)',
                                        },
                                    ]}
                                    layout="horizontal"
                                    yAxis={[
                                        {
                                        scaleType: 'band',
                                        dataKey: 'name',
                                        categoryGapRatio: 0.5,
                                        },
                                    ]}
                                    xAxis={[
                                        { min: 0, max: 12, tickMinStep: 2, label: 'Total Score' },
                                    ]}
                                    />
                                </div>
                            )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

        </LayoutSideWrapper>

    )
}