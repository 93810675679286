import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import LayoutSideWrapper from '../app-components/LayoutSideWrapper';
import Link from '@mui/material/Link';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Pagination,
  Popover,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchIcon from '@mui/icons-material/Search';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import InfoSnackbar from './Commons/InfoSnackbar';
import ActionSheet from '../theme/custom-components/action-sheet/ActionSheet';

function createData(id, productName, price, stock, equipment, created, status) {
  return {
    id,
    productName,
    price,
    stock,
    equipment,
    created,
    status,
  };
}

const rows = [
  createData(
    2420,
    'A Black and white headphones',
    150.99,
    1831,
    'Headphones',
    '01 jan. 2023',
    'Draft'
  ),
  createData(
    2421,
    'Black headphones',
    99.9,
    2986,
    'Headphones',
    '01 jan. 2023',
    'Rejected'
  ),
  createData(
    2422,
    'Black headphones',
    45.99,
    3559,
    'Headphones',
    '01 feb. 2023',
    'Draft'
  ),
  createData(
    2423,
    'Black and white headphones',
    35.99,
    3561,
    'Headphones',
    '01 jan. 2023',
    'Pending'
  ),
  createData(
    2424,
    'Black and silver headphones',
    140.99,
    3646,
    'Headphones',
    '01 jan. 2023',
    'Pending'
  ),
  createData(
    2425,
    'Black and silver headphones',
    89.99,
    3656,
    'Headphones',
    '01 mar. 2023',
    'Submitted'
  ),
  createData(
    2426,
    'Silver smartphone',
    150.99,
    2567,
    'Smartphones',
    '01 jan. 2023',
    'Submitted'
  ),
  createData(
    2427,
    'Silver smartphone',
    99.9,
    2212,
    'Smartphones',
    '01 jan. 2023',
    'Active'
  ),
  createData(
    2428,
    'White smartphone',
    45.99,
    1559,
    'Smartphones',
    '01 jan. 2023',
    'Rejected'
  ),
  createData(
    2429,
    'Black smartphone',
    35.99,
    961,
    'Smartphones',
    '01 feb. 2023',
    'Draft'
  ),
  createData(
    2430,
    'Red smartphone',
    140.99,
    1234,
    'Smartphones',
    '01 mar. 2023',
    'Pending'
  ),
  createData(
    2431,
    'Silver smartphone',
    89.99,
    4321,
    'Smartphones',
    '01 mar. 2023',
    'Submitted'
  ),
  createData(
    2432,
    'SSD 2TB',
    499.99,
    4307,
    'Storage',
    '01 feb. 2023',
    'Active'
  ),
  createData(2433, 'SSD 20TB', 199.9, 4001, 'Storage', '01 feb. 2023', 'Draft'),
  createData(
    2434,
    'SSD 10TB',
    145.99,
    4004,
    'Storage',
    '01 feb. 2023',
    'Active'
  ),
  createData(
    2435,
    'SSD 2TB',
    135.99,
    4321,
    'Storage',
    '01 feb. 2023',
    'Pending'
  ),
  createData(
    2436,
    'SSD 5TB',
    340.99,
    1007,
    'Storage',
    '01 mar. 2023',
    'Pending'
  ),
  createData(
    2437,
    'SSD 2TB',
    189.99,
    4307,
    'Storage',
    '01 mar. 2023',
    'Submitted'
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'productName',
    numeric: true,
    disablePadding: false,
    label: 'Product name',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Price',
  },
  {
    id: 'stock',
    numeric: true,
    disablePadding: false,
    label: 'Stock',
  },
  {
    id: 'equipment',
    disablePadding: false,
    label: 'Equipment',
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'status',
    disablePadding: false,
    label: 'Status',
  },
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

/* Create Table Header*/
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="text-align-left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function SimpleList() {
  const isPhone = useMediaQuery('(max-width:620px)');
  const isNotDesktop = useMediaQuery('(max-width:820px)');

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');

  // Sorting
  const handleRequestSort = (event, property) => {
    const isAsc = order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setFilteredRows(
      stableSort(filteredRows, getComparator(isAsc ? 'desc' : 'asc', orderBy))
    );
  };

  // PAGINATION
  const [page, setPage] = React.useState(1);
  const rowsperpage = 5;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    //atualizar tabela
    setFilteredRows(
      stableSort(filteredRowsCount, getComparator(order, orderBy)).slice(
        (newPage - 1) * rowsperpage,
        (newPage - 1) * rowsperpage + rowsperpage
      )
    );
  };

  function resortList(thisAraay) {
    return stableSort(thisAraay, getComparator(order, orderBy)).slice(
      (page - 1) * rowsperpage,
      (page - 1) * rowsperpage + rowsperpage
    );
  }

  //LOAD MORE CARDS BUTTON
  const cardsPerPage = 8;
  const [visibleCards, setVisibleCards] = React.useState(cardsPerPage);

  const handleLoadMore = () => {
    setVisibleCards((preVisibleCards) => preVisibleCards + cardsPerPage);
  };

  // SEARCH
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filteredRowsCount, setFilteredRowsCount] = React.useState(
    stableSort(rows, getComparator(order, orderBy))
  );
  const [filteredRows, setFilteredRows] = React.useState(
    resortList(filteredRowsCount)
  );

  const [filteredCardsCount, setFilteredCardsCount] = React.useState(
    stableSort(rows, getComparator(order, orderBy))
  );

  const performSearch = () => {
    const filtered = rows.filter((row) =>
      row.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setPage(1);
    setFilteredRows(resortList(filtered));
    setFilteredRowsCount(filtered);
    // setFilteredCards(resortCards(filtered));
    setFilteredCardsCount(filtered);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setVisibleCards(cardsPerPage);
    setPage(1);
    setFilteredRows(resortList(rows));
    setFilteredRowsCount(rows);
    // setFilteredCards(resortCards(rows));
    setFilteredCardsCount(rows);
  };

  //CHIP COLOR STATES
  const statusClassNames = [
    { status: 'Active', className: 'background-info-light' },
    { status: 'Pending', className: 'background-warning-light' },
    { status: 'Draft', className: 'background-secondary-light' },
    { status: 'Submitted', className: 'background-success-light' },
    { status: 'Rejected', className: 'background-error-light' },
  ];

  //CHANGE VIEW TO DISPLAY CARDS INSTEAD OF A TABLE
  const [view, setView] = React.useState('table');

  const handleViewChange = () => {
    setView(view === 'table' ? 'card' : 'table');
  };

    //VIEW WHEN THE RESOLUTION IS BELOW 600PX (PHONE)
  
      React.useEffect(() => {
        // Check the screen width and set the view accordingly
        const handleResize = () => {
          if (window.innerWidth <= 600) {
            setView('card');
          } else {
            setView('table');
          }
        };
    
        // Initial check when the component mounts
        handleResize();
    
        // Add a listener for window resize events
        window.addEventListener('resize', handleResize);
    
        // Cleanup the listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

  // CARD POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //SNACKBAR MESSAGE

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
    return <InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  //ACTION SHEETS

  const [openActionSheet, setOpenActionSheet] = React.useState(false);

  const toggleActionSheet = (newOpen) => () => {
    setOpenActionSheet(newOpen);
  };

  //ACTION SHEETS DELETE
  const [openDeleteActionSheet, setopenDeleteActionSheet] = React.useState(false);
  const toggleDeleteActionSheet = (newOpen) => () => {
    setopenDeleteActionSheet(newOpen);
  };


   //Card ActionSheet

   const [openCardActionSheet, setOpenCardActionSheet] = React.useState(false);

   const toggleCardActionSheet = (newOpen) => () => {
     setOpenCardActionSheet(newOpen);
   };

  function actionButton() {
    return (
      <div>
        {isPhone ? (
          <>
            <Button onClick={toggleActionSheet(true)} className='btn-icon'>
              {' '}
              <MoreVertOutlinedIcon />
            </Button>
          </>
        ) : (
          <>
            <Button className="primary" onClick={handleClickSnackbar}>
              <AddRoundedIcon className="margin-right-s" />
              Add Product
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <LayoutSideWrapper
      title="Products List"
      actions={actionButton()}
      breadcrumbs={[
        <Link key="home">Home</Link>,
        <Link key="products">Products</Link>,
        <span key="products-list">Product List</span>,
      ]}
    >
      {openSnackbar && (<InfoSnackbar open={openSnackbar} onClose={handleCloseSnackbar} />)}

            <ActionSheet open={openActionSheet}  onClose={toggleActionSheet(false)} onCancelClick={toggleActionSheet(false)}cancelText='Cancel'>
              <Button className="full-width" onClick={handleClickSnackbar}> Add </Button>
              <Button className="full-width" onClick={handleClickSnackbar}> Export </Button>
            </ActionSheet>

            <ActionSheet open={openCardActionSheet}  onClose={toggleCardActionSheet(false)} onCancelClick={toggleCardActionSheet(false)}cancelText='Cancel'>
              <Button className="full-width" onClick={handleClickSnackbar}> Edit </Button>
              <Button className="full-width error" onClick={handleClickSnackbar}> Delete </Button>
            </ActionSheet>

            <ActionSheet open={openDeleteActionSheet} onClose={toggleDeleteActionSheet(false)} onCancelClick={toggleDeleteActionSheet(false)}cancelText='Cancel'>
            <Button className="full-width error" onClick={handleClickSnackbar}>Confirm </Button>
        </ActionSheet>

      <Stack direction="row">
        <TextField
          sx={{ width: 300 }}
          value={searchQuery}
          onChange={handleSearchInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Search by product name"
        />
        <div>
          {isPhone ? (
            <>
              <Stack direction="row" className="margin-bottom-m">
                <Button
                  className="primary margin-left-s margin-right-base btn-icon"
                  onClick={performSearch}
                >
                  {' '}
                  <SearchIcon />
                </Button>
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  onChange={handleViewChange}
                >
                  <ToggleButton className='btn-icon'>
                    {view === 'table' ? (
                      <FormatListBulletedRoundedIcon />
                    ) : (
                      <WindowOutlinedIcon />
                    )}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </>
          ) : (
            <>
              <Button onClick={performSearch} className="margin-left-s">
                Search
              </Button>
              <Button onClick={clearSearch} className="cancel margin-left-base">
                Clear all filters
              </Button>
            </>
          )}
        </div>
      </Stack>

      {isPhone ? (
        <></>
      ) : (
        <>
            <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" > {filteredRowsCount.length} results</Stack>
              <Stack direction="row" className=' margin-bottom-base'>
              <Button className="margin-right-m" onClick={handleClickSnackbar}>
                {' '}
                Export
              </Button>

              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleViewChange}
              >
                <ToggleButton value="table" className='btn-icon'>
                  <FormatListBulletedIcon />
                </ToggleButton>
                <ToggleButton value="card" className='btn-icon'>
                  <WindowOutlinedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Stack>
        </>
      )}
      {view === 'table' ? (
        <Card>
          <CardContent>
            <TableContainer>
              <Table>
                {/*table header*/}

                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredRows.length}
                />

                {/*table rows*/}
                <TableBody>
                  {filteredRows.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className="text-primary text-align-left">
                          <Link onClick={handleClickSnackbar}> {row.id}</Link>
                        </TableCell>
                        <TableCell className="text-align-left">
                          {row.productName}
                        </TableCell>
                        <TableCell className="text-align-left">
                          ${row.price}
                        </TableCell>
                        <TableCell className="text-align-left">
                          {row.stock}
                        </TableCell>
                        <TableCell className="text-align-left">
                          {row.equipment}
                        </TableCell>
                        <TableCell className="text-align-left">
                          {row.created}
                        </TableCell>
                        <TableCell className="text-align-left">
                          <Chip
                            label={row.status}
                            className={
                              statusClassNames.find(
                                (item) => item.status === row.status
                              )?.className || ''
                            }
                          />
                        </TableCell>
                        <TableCell className="text-align-right">
                          <Stack direction="row">
                            <Button  onClick={isNotDesktop ? toggleDeleteActionSheet(true) : ""}>
                              <DeleteOutlineOutlinedIcon />
                            </Button>
                            <Button>
                              <EditOutlinedIcon />
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              {/* PAGE COUNTING */}
              

                {isPhone ? (
                  <Stack direction="column" className="margin-top-m">
                    <div className="text-align-center text-neutral-7">
                      {rowsperpage * (page - 1) + 1 > filteredRowsCount.length
                        ? filteredRowsCount.length
                        : rowsperpage * (page - 1) + 1}{' '}
                      to{' '}
                      {Math.min(rowsperpage * page, filteredRowsCount.length)}{' '}
                      of {filteredRowsCount.length}
                    </div>
                    <Pagination
                      className="margin-bottom-m margin-top-base"
                      rowsperpage={rowsperpage}
                      page={page}
                      count={Math.ceil(filteredRowsCount.length / rowsperpage)}
                      onChange={handleChangePage}
                    />
                  </Stack>
                ) : (
                  <>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" className="text-neutral-7">
                      {rowsperpage * (page - 1) + 1 > filteredRowsCount.length
                        ? filteredRowsCount.length
                        : rowsperpage * (page - 1) + 1}{' '}
                      to{' '}
                      {Math.min(rowsperpage * page, filteredRowsCount.length)}{' '}
                      of {filteredRowsCount.length}
                    <Pagination
                      rowsperpage={rowsperpage}
                      page={page}
                      count={Math.ceil(filteredRowsCount.length / rowsperpage)}
                      onChange={handleChangePage}
                    />
                  </Stack>
                  </>
                )}

            </TableContainer>
          </CardContent>
        </Card>
      ) : (
        /*CARD VIEW*/
        <>
          <Grid container spacing={3} >
            {filteredCardsCount.slice(0, visibleCards).map((row, index) => (
              <Grid key={index} item xs={12} sm={4} md={3} >
                <Card key={row.id} className='full-height'>
                  <CardContent>
                    <Stack direction="row" justifyContent="space-between" className='padding-bottom-base'>
                      <Link onClick={handleClickSnackbar}>
                        {' '}
                        #{row.id}
                      </Link>
                    {isNotDesktop ? (
                        <Link onClick={toggleCardActionSheet(true)}>
                          <MoreVertOutlinedIcon className="text-primary" />
                        </Link>
                    ):(
                        <Link onClick={handleClick}>
                          <MoreVertOutlinedIcon className="text-primary" />
                        </Link>
                    )}
                      

                      <Popover
                        className="popover padding-none"
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <MenuItem onClick={handleClickSnackbar}>
                          <ListItemIcon>
                            <EditOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleClickSnackbar}>
                          <ListItemIcon>
                            <DeleteOutlineOutlinedIcon onClick={handleClickSnackbar} />
                          </ListItemIcon>
                          <ListItemText>Delete</ListItemText>
                        </MenuItem>
                      </Popover>
                    </Stack>

                    {headCells.map((cell) => {
                      if (cell.id !== 'status' && cell.id !== 'id') {
                        return (
                          <div key={cell.id}>
                            <div className="text-neutral-8">{cell.label}</div>
                            <div className="padding-bottom-base font-bold font-size-base">
                              {cell.id === 'price'
                                ? '$' + row[cell.id]
                                : row[cell.id]}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                    <div className="text-align-left">
                      <Chip
                        label={row.status}
                        className={`small 
                      ${
                        statusClassNames.find(
                          (item) => item.status === row.status
                        )?.className || ''
                      }`}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Stack direction="row" justifyContent="center" className="margin-top-m">
            {visibleCards < filteredCardsCount.length && (
              <Button onClick={handleLoadMore}>
                Load more ({Math.min(visibleCards, filteredCardsCount.length)}{' '}
                of {filteredCardsCount.length})
              </Button>
            )}
          </Stack>
        </>
      )}
    </LayoutSideWrapper>
  );
}
