import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import PreviewComponent from '../app-components/PreviewComponent';


export default function Modal_Sample() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const sampleModal = "<Modal className='dialog-style MuiBackdrop-visible'>" +
  "\n\t<Box className='background-neutral-0'>" +
  "\n\t\t<h3>Personal Loan</h3>" +
  "\n\t\t<p>Take control with a loan made for you. Access your loan" +
  "\n\t\tinformation whenever you need it, we developed tools to give" +
  "\n\t\tyou freedom to focus on the most important things.</p>" +
  "\n\t\t<Divider className='margin-bottom-m'/>" +
  "\n\t\t<Button className='cancel margin-right-base'>Cancel</Button>" +
  "\n\t\t<Button className='primary'>Confirm</Button>" +
  "\n\t</Box>" +
  "\n</Modal>";

  return (

    <div>
      <PreviewComponent title="" description="" code={sampleModal}>
      <Button onClick={handleOpen}>Open Modal</Button>
        <Modal open={open} onClose={handleClose} className="dialog-style MuiBackdrop-visible">
          <Box className='background-neutral-0'>
            <h3>Personal Loan</h3>
            <p>
              Take control with a loan made for you. Access your loan 
              information whenever you need it, we developed tools to give 
              you freedom to focus on the most important things.
            </p>
            <Divider className="margin-bottom-m"/>
            <Button className="cancel margin-right-base">Cancel</Button>
            <Button className="primary">Confirm</Button>
          </Box>
        </Modal>
        </PreviewComponent>
    </div>     
  );
}