import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import PreviewComponent from '../app-components/PreviewComponent';
import { Divider, Stack } from '@mui/material';

export default function ButtonGroup_Sample() {

  const sampleCode = "<ButtonGroup>" +
    "\n\t<Button>Web</Button>" + 
    "\n\t<Button>Android</Button>" +
    "\n\t<Button>iOS</Button>" +
    "\n</ButtonGroup>";
    
  const sampleCode_sizes = "<ButtonGroup className='small'>" + 
                        "\n\t<Button>Web</Button>" +
                        "\n\t<Button>Android</Button>" +
                        "\n\t<Button>iOS</Button>" +
                        "\n</ButtonGroup>" + 
                        "\n" +
                        "\n<ButtonGroup>" + 
                        "\n\t<Button>Web</Button>" +
                        "\n\t<Button>Android</Button>" +
                        "\n\t<Button>iOS</Button>" + 
                        "\n</ButtonGroup>" + 
                        "\n" +
                        "\n<ButtonGroup className='large'>" + 
                        "\n\t<Button>Web</Button>" +
                        "\n\t<Button>Android</Button>" +
                        "\n\t<Button>iOS</Button>" +
                        "\n</ButtonGroup>"

  const sampleCode_verticalGroup = "<ButtonGroup" +
                                  "\n\tclassName='vertical'>" +
                                  "\n\t<Button>Web</Button>" +
                                  "\n\t<Button>Android</Button>" +
                                  "\n\t<Button>iOS</Button>" +
                                  "\n</ButtonGroup>" +
                                  "\n" +
                                  "\n<ButtonGroup" +
                                  "\n\tclassName='vertical'>" +
                                  "\n\t<Button className='primary'>Web</Button>" +
                                  "\n\t<Button className='primary'>Android</Button>" +
                                  "\n\t<Button className='primary'>iOS</Button>" +
                                  "\n</ButtonGroup>" +
                                  "\n" +
                                  "\n<ButtonGroup" +
                                  "\n\tclassName='vertical lined'>" +
                                  "\n\t<Button>Web</Button>" +
                                  "\n\t<Divider className='background-primary'/>" +
                                  "\n\t<Button>Android</Button>" +
                                  "\n\t<Divider className='background-primary'/>" +
                                  "\n\t<Button>iOS</Button>" +
                                  "\n</ButtonGroup>"

  return (
    <div>
        <PreviewComponent title="Default" description="" code={sampleCode}>
            <ButtonGroup >
              <Button >Web</Button>
              <Button>Android</Button>
              <Button>iOS</Button>
            </ButtonGroup>
        </PreviewComponent>

        <PreviewComponent title="Sizes" subtitle="Small, Default and Large" description="" code={sampleCode_sizes}>
            <Stack direction='column' spacing={3}>
              <ButtonGroup className="small">
              <Button >Web</Button>
                <Button>Android</Button>
                <Button>iOS</Button>
              </ButtonGroup>
              <ButtonGroup >
                <Button >Web</Button>
                <Button>Android</Button>
                <Button>iOS</Button>
              </ButtonGroup>
              <ButtonGroup className="large">
              <Button >Web</Button>
                <Button>Android</Button>
                <Button>iOS</Button>
              </ButtonGroup>
            </Stack>
        </PreviewComponent>

        <PreviewComponent title="Vertical Group" description="" code={sampleCode_verticalGroup}>    
            <Stack direction='row' spacing={4}>
              <ButtonGroup className="vertical">
                <Button>Web</Button>
                <Button>Android</Button>
                <Button>iOS</Button>
              </ButtonGroup>

              <ButtonGroup className="vertical">
                <Button className='primary'>Web</Button>
                <Button className='primary'>Android</Button>
                <Button className='primary'>iOS</Button>
              </ButtonGroup>
            
              <ButtonGroup className="vertical lined">
                <Button >Web</Button>
                <Divider className="background-primary"/>
                <Button >Android</Button>
                <Divider className="background-primary"/>
                <Button >iOS</Button>
              </ButtonGroup>
            </Stack>
        </PreviewComponent>
    </div>
  );
}