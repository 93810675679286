import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import PreviewComponent from '../app-components/PreviewComponent';

export default function SimpleBackdrop() {

  const sample_backdrop="<Backdrop className='MuiBackdrop-visible'>" +
                      "\n\t<CircularProgress />" +
                      "\n</Backdrop>"

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <PreviewComponent title="" description="" code={sample_backdrop}>
          <Button onClick={handleOpen}>Show backdrop</Button>
          <Backdrop
            className='MuiBackdrop-visible'
            open={open}
            onClick={handleClose}
          >
            <CircularProgress/>
          </Backdrop>
      </PreviewComponent>
    </div>
  );
}