import PetsIcon from '@mui/icons-material/Pets';
import LayoutBlankWrapper from '../app-components/LayoutBlankWrapper';

export default function NotFound(){

    return (
        <LayoutBlankWrapper>
            <PetsIcon className='font-size-display text-error margin-bottom-m'/>
            <div className="font-size-display font-bold margin-bottom-base">Page not found</div>
            <div className="text-neutral-7 margin-bottom-l">It seems that something is not right on this screen, either your cat stumbled on the ethernet cable or the screen doesn't exist.</div>
            <a href="/" title="Live Style Guide Homepage">Go to the Homepage</a>
        </LayoutBlankWrapper>
    )
}