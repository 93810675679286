import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function ComboBox() {

    const sampleCode_disabled = "<Autocomplete" + 
    "\n\tdisabled" + 
    "\n\t<TextField label='Movie' />"+
    "\n/>"
    
  return (
    <div> 
        <PreviewComponent title="Disabled" description="" code={sampleCode_disabled}>
            <Autocomplete
                className='width-300'
                disabled
                renderInput={(params) => (
                  <TextField {...params} label="Movie" />
                )}
            />
        </PreviewComponent>
      </div> 
  );
}