import * as React from "react";
import BasicSample from "./Basic_Sample";
import LabelHelperText from "./LabelsHelperText_Sample";
import AutoWidth from "./AutoWidth_Sample";
import OtherProps from "./OtherProps_Sample";
import Default from "./Default_Sample";
import Checkmarks from "./Checkmarks_Sample";
import Chip from "./Chip_Sample";
import Placeholder from "./Placeholder_Sample";

export default function Select_Sample() {
  return (
    <div>
      <h2>Single Select</h2>
      <hr />

      <BasicSample />

      <LabelHelperText />

      <AutoWidth />

      <OtherProps />
      <br />

      <h2>Multiple Select</h2>
      <hr />

      <Default />

      <Placeholder />

      <Checkmarks />

      <Chip />
    </div>
  );
}
